import * as React from "react";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import { NegotiationStatus, Project } from "../../types/project";
import { useGetChatMessagesQuery } from "../../services/chat.service";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { Refresh } from "@mui/icons-material";

const ProjectMessagesPanel = ({ project }: { project: Project }) => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const chatId = project.chats?.[0]?.id || 0;

  const { data, isLoading, isFetching, refetch, isError, error } =
    useGetChatMessagesQuery({
      chatId,
    });

  const Messages = React.useMemo(() => {
    let lastDate = "";
    return data?.data
      ?.slice(0)
      .reverse()
      .map((it) => {
        let curDate = dayjs(it.created_at).format("MMM DD, YYYY");
        let isNewDay = curDate != lastDate;
        if (isNewDay) lastDate = curDate;

        const isImage =
          it.documents?.[0]?.mime_type?.startsWith("image") || false;
        const isFile = !!it.documents?.[0]?.mime_type && !isImage; //anything but image

        return (
          <>
            {isNewDay && <MessageSeparator>{curDate}</MessageSeparator>}
            <Message
              key={it.id}
              model={{
                direction: it.user_id == user?.id ? "outgoing" : "incoming",
                position: "single",
              }}
            >
              {isImage ? (
                // <Message.ImageContent
                //   src={it.documents?.[0]?.original_url}
                //   width={200}
                //   onClick={() => console.log(it.documents?.[0]?.original_url)}
                // />
                <Message.HtmlContent
                  html={`<a href='${it.documents?.[0]?.original_url}' target='_blank'><img src='${it.documents?.[0]?.original_url}' width='250'/></a>`}
                />
              ) : isFile ? (
                <Message.HtmlContent
                  html={`${it.body}<br/><a href='${it.documents?.[0]?.original_url}' target='_blank'>⬇️ Open File</a>`}
                />
              ) : (
                <Message.HtmlContent html={it.body} />
              )}
              <Message.Footer
                sender={`${it.user?.full_name || "?"}`}
                sentTime={dayjs(it.created_at).format("hh:mm A")}
              />
            </Message>
          </>
        );
      });
  }, [data, user]);

  if (isError) {
    return (
      <Box>
        <Alert color="error" title="Error">
          {/* @ts-ignore */}
          {error?.data?.message}
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Stack sx={{ alignItems: "flex-end" }}>
        <LoadingButton
          onClick={refetch}
          size="small"
          loading={isFetching}
          startIcon={<Refresh />}
        >
          Refresh
        </LoadingButton>
      </Stack>
      <div style={{ position: "relative", height: "62vh", overflow: "hidden" }}>
        <MainContainer style={{ borderWidth: 0 }}>
          <ChatContainer>
            <MessageList loading={isLoading}>{Messages}</MessageList>
          </ChatContainer>
        </MainContainer>
      </div>
    </Box>
  );
};
export default ProjectMessagesPanel;
