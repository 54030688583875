import { Skill, SkillCategory } from "../types/skill";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "SkillCategories";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const skillCategoryApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getSkillCategories: builder.query<
      QueryResponse<SkillCategory>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
      }
    >({
      query: ({ page, pageSize, sortField, sortMode, search }) => ({
        url: "api/admin/skill-categories",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          withTrashed: true,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getSkillCategoryById: builder.query<SkillCategory, number>({
      query: (id) => ({
        url: `api/admin/skill-categories/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    addSkillCategory: builder.mutation<
      SkillCategory,
      Omit<SkillCategory, "id">
    >({
      query: (body) => ({
        url: `api/admin/skill-categories`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    editSkillCategory: builder.mutation<
      SkillCategory,
      Partial<SkillCategory> & Pick<SkillCategory, "id">
    >({
      query: (body) => ({
        url: `api/admin/skill-categories/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteSkillCategory: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/skill-categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    restoreSkillCategory: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/skill-categories/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSkillCategoriesQuery,
  useGetSkillCategoryByIdQuery,
  useAddSkillCategoryMutation,
  useEditSkillCategoryMutation,
  useDeleteSkillCategoryMutation,
  useRestoreSkillCategoryMutation,
} = skillCategoryApi;
