//ading coment to deploy again

import { isManage360, isProductionMode } from "../utils/helper";

export const BASE_API_URL = isManage360()
  ? "https://api.manage.workz360.com/"
  : isProductionMode()
  ? `https://api.workz360.com/`
  : `https://api.staging.workz360.com/`;

export const OAUTH_CLIENT_ID = isManage360()
  ? `2`
  : isProductionMode()
  ? `2`
  : `4`;
export const OAUTH_CLIENT_SECRET = isManage360()
  ? `NBwV57xnXUur1ucOCF5c4DT4WMVvf4uwBVKELis2`
  : isProductionMode()
  ? `NBwV57xnXUur1ucOCF5c4DT4WMVvf4uwBVKELis2`
  : `z7HhDe1wmm4LeZgi33mq40XU0ZV1nRkzBnzMWaty`;

export const ROLE_ADMIN_ID = 1;
export const ROLE_TALENT_ID = 5;

export const GMAP_API_KEY = `AIzaSyBFhDf7o_dEXSoMzbg06MH_V2DXPn_j374`;
