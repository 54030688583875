import { Language } from "../types/language";
import { baseApi } from "./base";

const TAG_TYPE: string = "Languages";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const languageApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getLanguages: builder.query<
      Array<Language>,
      { sortField?: string; sortMode?: string | null; search?: string }
    >({
      query: ({ sortField, sortMode, search }) => ({
        url: "api/languages",
        method: "GET",
        params: {
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetLanguagesQuery } = languageApi;
