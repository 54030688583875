import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridToolbarContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  LinearProgress,
  Link,
  Paper,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
import useDebounce from "../../hooks/useDebounce";
import { Project } from "../../types/project";
import { Company } from "../../types/company";
import { useNavigate } from "react-router-dom";
import { useGetProjectsQuery } from "../../services/project.service";
// import ProjectDrawer from "../talent/ProjectDrawer";
import dayjs from "dayjs";
import ChipProjectStatus from "../../components/ChipProjectStatus";
import ProjectDrawer from "../project/ProjectDrawer";

const TableToolbar = ({
  onSearch,
  onShowMap,
}: {
  onSearch: (query?: string) => void;
  onShowMap: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      {/* <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <Button variant="outlined" onClick={onShowMap}>
          <LocationOnIcon /> Show Map
        </Button>
      </Stack> */}
    </GridToolbarContainer>
  );
};

const CompanyProjectsPanel = ({ data: dataCompany }: { data?: Company }) => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState<Project | null>(null);

  const { data, isLoading, isFetching } = useGetProjectsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
    company: dataCompany?.id,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        flex: 1,
        sortable: true,
        filterable: true,
        renderCell: (params: GridRenderCellParams<undefined, Project>) => (
          <Link
            component="button"
            variant="body2"
            onClick={() => onView(params.row)}
          >
            #{params.row.id} - {params.row.title}
          </Link>
        ),
      },
      {
        field: "customer_id",
        headerName: "Customer / Location",
        headerAlign: "center",
        minWidth: 250,
        sortable: true,
        filterable: true,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, Project>) =>
          !!params.row.customer ? (
            <Typography variant="body2">
              {params.row.customer.first_name} {params.row.customer.last_name}
            </Typography>
          ) : !!params.row.location ? (
            <Typography variant="body2">{params.row.location.name}</Typography>
          ) : (
            <Typography>?</Typography>
          ),
      },
      {
        field: "manager_id",
        headerName: "Manager",
        headerAlign: "center",
        minWidth: 130,
        sortable: true,
        filterable: true,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, Project>) =>
          !!params.row.manager ? (
            <Typography variant="body2">
              {params.row.manager.first_name} {params.row.manager.last_name}
            </Typography>
          ) : (
            <Typography>-</Typography>
          ),
      },
      {
        field: "is_private",
        headerName: "Is Private",
        headerAlign: "center",
        minWidth: 50,
        sortable: true,
        filterable: true,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, Project>) =>
          !!params.row.is_private ? (
            <Typography variant="body2">Yes</Typography>
          ) : (
            <Typography variant="body2">No</Typography>
          ),
      },
      {
        field: "start_at",
        headerName: "Start At",
        headerAlign: "center",
        minWidth: 200,
        sortable: true,
        filterable: true,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          `${dayjs(params.row.start_date).format("MMM DD, YYYY hh:mm A")}`,
      },
      {
        field: "status",
        headerName: "Status",
        headerAlign: "center",
        minWidth: 130,
        sortable: true,
        filterable: true,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, Project>) => (
          <ChipProjectStatus status={params.row.status} />
        ),
      },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleShowMap = React.useCallback(() => {
    navigate(`/talents/map`);
  }, []);

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onShowMap={handleShowMap} />,
    [handleSearch, handleShowMap]
  );

  const onView = (item: any) => {
    setCurrentItem(item);
    setOpen(true);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Paper style={{ height: "65vh" }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[25, 50, 100]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnFilter
          // rowHeight={100}
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
        />
      </Paper>
      <ProjectDrawer data={currentItem!} open={open} setOpen={setOpen} />
    </Box>
  );
};
export default CompanyProjectsPanel;
