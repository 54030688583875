import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Chip,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { Project } from "../../types/project";
import { useNavigate } from "react-router-dom";
import MapPreview from "../../components/MapPreview";
import {
  ContactPhone,
  EditOutlined,
  EmailOutlined,
  ExpandMore,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { nl2br } from "../../utils/helper";
import ModalInternalNotesForm from "./ModalInternalNotesForm";
import { formatWithTimezone } from "../../utils/dateHelper";
// import EditIcon from "@mui/icons-material/Edit";
import { decode } from "html-entities";

const ProjectDetailsPanel = ({ data }: { data?: Project }) => {
  const navigate = useNavigate();
  const [formInternalNotesModal, setFormInternalNotesModal] = React.useState<{
    open: boolean;
    data: Project | null;
  }>({
    open: false,
    data: null,
  });
  const [lng, lat] = React.useMemo(() => {
    if (!!data?.customer) {
      return data?.customer?.coordinate?.coordinates || [];
    } else if (!!data?.location) {
      return data?.location?.coordinate?.coordinates || [];
    } else return [];
  }, [data]);

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={8}>
          <Card>
            <CardHeader title="Scope of Work" />
            <CardContent>
              <Typography variant="body2">
                {nl2br(decode(data?.description))}
              </Typography>
            </CardContent>
            <Divider />
            <CardHeader title="Confidential Information" />
            <CardContent>
              <Typography variant="body2">
                {nl2br(data?.confidential_information) || "n/a"}
              </Typography>
            </CardContent>
            <Divider />
            <CardHeader title="Start Time" />
            <CardContent>
              <Typography variant="body2">
                {formatWithTimezone(data?.start_date, data?.timezone)} (
                {data?.timezone})
              </Typography>
            </CardContent>
          </Card>
          {/* <Card sx={{ mt: 1 }}>
            <CardHeader title="Talents" />
            <CardContent></CardContent>
          </Card> */}
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Tasks" />
            <CardContent>
              {data?.tasks && data?.tasks?.length > 0 ? (
                data?.tasks?.map((it, idx) => (
                  <Accordion key={idx}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography>{it.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="caption">
                        Description → {decode(it.description) || "n/a"}
                        <br />
                        Start → {it.start_date || "n/a"}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Typography sx={{ color: "text.disabled" }}>
                  No tasks specified
                </Typography>
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Requirements" />
            <CardContent>
              <Typography variant="body2" fontWeight={500}>
                Skills
              </Typography>
              <Box sx={{ mt: 1 }}>
                {data?.skills?.length == 0 ? (
                  <Typography sx={{ color: "text.disabled" }}>
                    Not Required
                  </Typography>
                ) : (
                  data?.skills?.map((it) => (
                    <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                  ))
                )}
              </Box>
              <Typography sx={{ mt: 2 }} variant="body2" fontWeight={500}>
                Certifications
              </Typography>
              <Box sx={{ mt: 1 }}>
                {data?.certifications?.length == 0 ? (
                  <Typography sx={{ fontSize: 12, color: "text.disabled" }}>
                    Not Required
                  </Typography>
                ) : (
                  data?.certifications?.map((it) => (
                    <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                  ))
                )}
              </Box>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Additional Settings" />
            <CardContent>
              <Typography>
                Number of Talents → {data?.num_of_talent}
                <br />
                Approximate Hours to Complete →{" "}
                {data?.approximate_hours_to_complete} hour
                <br />
                Tools Required → {!!data?.tool_required ? "Yes" : "No"}
                <br />
                Mile Radius → {data?.mile_radius} miles
                <br />
                {/* Covid-19 Vaccinated Required →{" "}
                {!!data?.covid_vaccine_required ? "Yes" : "No"}
                <br /> */}
                Background Check Required →{" "}
                {!!data?.background_check_required ? "Yes" : "No"}
                <br />
                Drug Test Required → {!!data?.drug_test_required ? "Yes" : "No"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader title="Work Site" />
            <CardContent sx={{ p: 0 }}>
              <MapPreview lat={lat} lng={lng} />
            </CardContent>
            <Divider />
            <CardContent>
              {!!data?.customer && (
                <Typography variant="body2">
                  <Typography variant="body1">
                    {data?.customer?.first_name} {data?.customer?.last_name}
                  </Typography>
                  {data?.customer.physical_address_1}, {data?.customer.city},
                  {data?.customer.state} {data?.customer.zip}
                </Typography>
              )}
              {!!data?.location && (
                <Box fontSize={14}>
                  <Typography variant="body1" fontWeight={500}>
                    {data?.location?.name}
                  </Typography>
                  {data?.location.address_1}, {data?.location.city},{" "}
                  {data?.location.state} {data?.location.zip}
                  <Stack direction={"row"} alignItems="center" sx={{ mt: 1 }}>
                    <ContactPhone
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.location?.primary_contact_name} -{" "}
                    {data?.location?.primary_contact_phone_number}
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <EmailOutlined
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.location?.primary_contact_email}
                  </Stack>
                </Box>
              )}
              {!!data?.customer && (
                <Box fontSize={14}>
                  <Typography variant="body1" fontWeight={500}>
                    {data?.customer?.first_name} {data?.customer?.last_name}
                  </Typography>
                  {data?.customer.physical_address_1}, {data?.customer.city},{" "}
                  {data?.customer.state} {data?.customer.zip}
                  <br />
                  <Stack direction={"row"} alignItems="center" sx={{ mt: 1 }}>
                    <ContactPhone
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.customer?.phone_number}
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <EmailOutlined
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.customer?.email}
                  </Stack>
                </Box>
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Manager" />
            <CardContent>
              <Typography variant="body2">
                <Box fontSize={14}>
                  <Typography variant="body1" fontWeight={500}>
                    {data?.manager?.first_name} {data?.manager?.last_name}
                  </Typography>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: 1 }}>
                    <ContactPhone
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.manager?.phone_number}
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <EmailOutlined
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.manager?.email}
                  </Stack>
                </Box>
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader
              title="Notes Between Customer and Admin"
              action={
                <IconButton
                  size="small"
                  onClick={() =>
                    setFormInternalNotesModal({ open: true, data: data! })
                  }
                >
                  <EditOutlined />
                </IconButton>
              }
            />
            <CardContent>
              <Typography variant="body2">
                {nl2br(data?.internal_notes || "n/a")}
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Meta" />
            <CardContent>
              <Typography variant="body2">
                Created At →
                {dayjs(data?.created_at).format("MMM DD, YYYY hh:mm A")}
                <br />
                Last Updated →
                {dayjs(data?.updated_at).format("MMM DD, YYYY hh:mm A")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ModalInternalNotesForm
        open={formInternalNotesModal.open}
        onClose={() => setFormInternalNotesModal({ open: false, data: null })}
        project={formInternalNotesModal.data}
      />
    </Box>
  );
};
export default ProjectDetailsPanel;
