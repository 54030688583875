import { Chat, ChatMessage } from "../types/chat";
// import { File } from "../types/common";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE_CHAT = "chats";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_CHAT],
});

export const chatApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getChat: builder.query<Array<Chat>, number>({
      query: (project_id) => {
        return {
          url: `api/admin/chats/${project_id}/project`,
          method: "GET",
          params: {
            sort: "created_at|desc",
            load: "participants,participants.user",
          },
        };
      },
    }),
    getChatMessages: builder.query<
      QueryResponse<ChatMessage>,
      { chatId: number; page?: number; pageSize?: number }
    >({
      query: ({ page = 0, pageSize = 100, chatId }) => {
        return {
          url: `api/admin/messages/${chatId}`,
          method: "GET",
          params: {
            page,
            per_page: pageSize,
            sort: "created_at|desc",
            load: "documents,user",
          },
        };
      },
      providesTags: (result, err, { chatId }) => [
        { type: TAG_TYPE_CHAT, id: chatId },
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useGetChatQuery, useGetChatMessagesQuery } = chatApi;
