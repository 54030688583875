import { ROLE_TALENT_ID } from "../configs/api";
import { UserCertification } from "../types/certification";
import { UserEducation } from "../types/education";
import { Talent } from "../types/users";
import { isFile } from "../utils/helper";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "Talents";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const talentApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTalents: builder.query<
      QueryResponse<Talent>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        distance?: string;
        skills?: number[];
        city?: string;
        state?: string;
        country?: number;
        withTrashed?: boolean;
      }
    >({
      query: ({
        page,
        pageSize,
        sortField,
        sortMode,
        search,
        distance,
        skills,
        city,
        state,
        country,
        withTrashed,
      }) => ({
        url: "api/admin/users",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
          withTrashed,
          role: ROLE_TALENT_ID,
          search,
          load: "photo",
          distance,
          skills,
          city: city || undefined,
          state: state || undefined,
          country: country || undefined,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getTalentById: builder.query<Talent, number>({
      query: (id) => ({
        url: `api/admin/users/${id}`,
        method: "GET",
        params: {
          load: "country,photo,businessVerticals,languages,skills,certifications.certification,educations,workExperiences,talent",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id: id }],
    }),
    addTalent: builder.mutation<Talent, Omit<Talent, "id">>({
      query: (body) => {
        let formData = new FormData();
        formData.append("role", ROLE_TALENT_ID.toString());
        Object.entries(body).forEach(([key, value]) => {
          if (typeof value == "number") formData.append(key, value.toString());
          else if (Array.isArray(value)) {
            // formData.append(key, JSON.stringify(value));
            value.forEach((it: any) => {
              formData.append(`${key}[]`, it);
            });
          } else if (key == "photo") {
            if (isFile(value)) formData.append(key, value as any);
          } else formData.append(key, value as string);
        });
        return {
          url: `api/admin/users`,
          method: "POST",
          body: formData,
          // body: {
          //   role: ROLE_TALENT_ID,
          //   ...body
          // },
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    editTalent: builder.mutation<Talent, Partial<Talent> & Pick<Talent, "id">>({
      query: (body) => {
        if (!body.photo) delete body.photo;

        let formData = new FormData();
        formData.append("_method", "put");
        formData.append("role", ROLE_TALENT_ID.toString());

        Object.entries(body).forEach(([key, value]) => {
          if (typeof value == "number") formData.append(key, value.toString());
          else if (Array.isArray(value)) {
            // formData.append(key, JSON.stringify(value));
            value.forEach((it: any) => {
              formData.append(`${key}[]`, it);
            });
          } else if (key == "photo") {
            if (isFile(value)) formData.append(key, value as any);
          } else formData.append(key, value as string);
        });

        return {
          url: `api/admin/users/${body.id}`,
          method: "POST",
          body: formData,
          // body: {
          //   role: ROLE_TALENT_ID,
          //   ...body
          // },
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteTalent: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    restoreTalent: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/users/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    activateTalent: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/users/${id}/active`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    disableTalent: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/users/${id}/in-active`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    getTalentTotalJobSuccessRateById: builder.query<any, number>({
      query: (id) => ({
        url: `api/talent/${id}/total-success-rate`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    getTalentTotalJobHiredById: builder.query<any, number>({
      query: (id) => ({
        url: `api/talent/${id}/total-job-hired`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    getTalentTotalEarnedById: builder.query<any, number>({
      query: (id) => ({
        url: `api/talent/${id}/total-earning`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    //
    addUserCertification: builder.mutation<
      UserCertification,
      Omit<UserCertification, "id">
    >({
      query: (body) => {
        return {
          url: `api/admin/users/${body.user_id}/certifications`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE, id: params.user_id },
      ],
    }),
    updateUserCertification: builder.mutation<
      UserCertification,
      Partial<UserCertification> & Pick<UserCertification, "id">
    >({
      query: (body) => {
        return {
          url: `api/admin/users/${body.user_id}/certifications/${body.id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE, id: params.user_id },
      ],
    }),
    deleteUserCertification: builder.mutation<
      void,
      { user_id: number; id: number }
    >({
      query: ({ user_id, id }) => {
        return {
          url: `api/admin/users/${user_id}/certifications/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE, id: params.user_id },
      ],
    }),
    //
    addUserEducation: builder.mutation<
      UserEducation,
      Omit<UserEducation, "id">
    >({
      query: (body) => {
        return {
          url: `api/admin/users/${body.user_id}/educations`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE, id: params.user_id },
      ],
    }),
    updateUserEducation: builder.mutation<
      UserEducation,
      Partial<UserEducation> & Pick<UserEducation, "id">
    >({
      query: (body) => {
        return {
          url: `api/admin/users/${body.user_id}/educations/${body.id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE, id: params.user_id },
      ],
    }),
    deleteUserEducation: builder.mutation<
      void,
      { user_id: number; id: number }
    >({
      query: ({ user_id, id }) => {
        return {
          url: `api/admin/users/${user_id}/educations/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE, id: params.user_id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTalentsQuery,
  useGetTalentByIdQuery,
  useAddTalentMutation,
  useEditTalentMutation,
  useDeleteTalentMutation,
  useRestoreTalentMutation,
  useActivateTalentMutation,
  useDisableTalentMutation,
  useGetTalentTotalJobSuccessRateByIdQuery,
  useGetTalentTotalJobHiredByIdQuery,
  useGetTalentTotalEarnedByIdQuery,
  useAddUserCertificationMutation,
  useUpdateUserCertificationMutation,
  useDeleteUserCertificationMutation,
  useAddUserEducationMutation,
  useUpdateUserEducationMutation,
  useDeleteUserEducationMutation,
} = talentApi;
