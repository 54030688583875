import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import { SkillCategory } from "../../types/skill";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetSkillCategoryByIdQuery,
  useAddSkillCategoryMutation,
  useEditSkillCategoryMutation,
} from "../../services/skill-category.service";

interface ModalSkillCategoryFormProps {
  open: boolean;
  onClose: () => void;
  data: SkillCategory | null;
}

const ModalSkillCategoryForm = ({
  open,
  onClose,
  data,
}: ModalSkillCategoryFormProps) => {
  const { data: skill } = useGetSkillCategoryByIdQuery(data?.id!, {
    skip: !data?.id,
  });

  const [add, { isLoading: adding }] = useAddSkillCategoryMutation();
  const [edit, { isLoading: editing }] = useEditSkillCategoryMutation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!data) await add(payload).unwrap();
      else {
        payload.id = data?.id!;
        await edit(payload).unwrap();
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("name", data?.name);
      setValue("description", data?.description);
    }
  }, [data, open]);

  React.useEffect(() => {
    setValue("name", skill?.name);
    setValue("description", skill?.description);
  }, [skill]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="xs"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} Skill Category
        </DialogTitle>
        <DialogContent>
          <Box>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Category name"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.name}
                  helperText={!!errors.name && "This field is required"}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Description (optional)"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  multiline
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={adding || editing}
          >
            {!data ? "Create" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalSkillCategoryForm;
