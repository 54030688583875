import * as React from "react";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Stack,
  Typography,
  LinearProgress,
  Rating,
  IconButton,
  Menu,
  MenuItem,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetTalentByIdQuery,
  useGetTalentTotalJobSuccessRateByIdQuery,
  useGetTalentTotalJobHiredByIdQuery,
  useGetTalentTotalEarnedByIdQuery,
} from "../../services/talent.service";
import { useTitle } from "../../hooks/useTitle";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { Talent } from "../../types/users";
import { TabPanel } from "@mui/lab";
import TalentProfilePanel from "./TalentProfilePanel";
import TalentJobsPanel from "./TalentJobsPanel";
import TalentFundsPanel from "./TalentFundsPanel";
import TalentAccountPanel from "./TalentAccountPanel";
import { formatCash, formatMoney } from "../../utils/helper";
import { MoreVert } from "@mui/icons-material";

const MoreMenu = ({ talent }: { talent?: Talent }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ ml: 1 }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => navigate(`/talents/${talent?.id}/reviews`)}>
          Reviews
        </MenuItem>
      </Menu>
    </div>
  );
};

const ProfileStat = ({
  label,
  value,
  isLoading,
  onClick,
}: {
  label: string;
  value: string;
  isLoading?: any;
  onClick?: () => void;
}) => (
  <Box
    onClick={onClick}
    sx={{
      border: "1px solid #ccc",
      borderRadius: 1,
      py: 0.5,
      px: 2,
      alignItems: "center",
      mr: 1,
      bgcolor: "white",
      minWidth: 100,
    }}
  >
    {isLoading ? (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress size={16} />
      </Box>
    ) : (
      <Typography textAlign={"center"} fontSize={18} letterSpacing={0.5}>
        {value}
      </Typography>
    )}
    <Typography textAlign={"center"} fontSize={12} color="text.secondary">
      {label}
    </Typography>
  </Box>
);

const ProfileMeta = ({
  icon,
  value,
  onClick,
}: {
  icon: React.ReactNode;
  value: string;
  onClick?: () => void;
}) => (
  <Button
    onClick={onClick}
    variant="text"
    startIcon={icon}
    size="small"
    sx={{ mr: 2, textTransform: "unset", color: "text.secondary" }}
  >
    {value}
  </Button>
);

const ProfileHeader = ({ data }: { data?: Talent }) => {
  const navigate = useNavigate();
  const talentId = data?.id;

  const {
    data: dataTotalJobSuccessRate,
    isLoading: isLoadingTotalJobSuccessRate,
  } = useGetTalentTotalJobSuccessRateByIdQuery(talentId!, {
    skip: !talentId,
  });

  const { data: dataTotalJobHired, isLoading: isLoadingTotalJobHired } =
    useGetTalentTotalJobHiredByIdQuery(talentId!, {
      skip: !talentId,
    });

  const { data: dataTotalEarned, isLoading: isLoadingTotalEarned } =
    useGetTalentTotalEarnedByIdQuery(talentId!, {
      skip: !talentId,
    });

  return (
    <Stack direction={"row"} alignItems="center">
      <Avatar
        variant="square"
        sx={{ bgColor: "primary.main", width: 100, height: 100, mr: 2 }}
        // alt={data?.first_name}
        src={
          (!!data?.photo?.length && data?.photo[0].original_url) ||
          "broken-image.jpg"
        }
      />
      <Stack direction="column" flex={1}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5">{data?.full_name}</Typography>
          <Rating value={data?.total_rating || 0} readOnly sx={{ ml: 2 }} />
        </Stack>

        <Stack direction="row" alignItems="center">
          <ProfileMeta
            icon={<PersonPinCircleIcon />}
            value={`${data?.city || "Unknown City"}, ${
              data?.state || "Unknown State"
            }`}
          />
          <ProfileMeta
            icon={<EmailIcon />}
            value={`${data?.email || "No Email"}`}
            onClick={() => {
              window.location.href = `${`mailto:${data?.email}`}`;
            }}
          />
          <ProfileMeta
            icon={<PhoneAndroidIcon />}
            value={`${data?.phone_number || "No Phone"}`}
            onClick={() => {
              // try {
              //   var outlookApp = new ActiveXObject('Outlook.Application');
              //   var nameSpace = outlookApp.getNameSpace('MAPI');
              //   var mailFolder = nameSpace.getDefaultFolder(6);
              //   mailFolder.Display();
              // } catch (e) {
              //   window.location.href = `${`mailto:${data?.email}`}`
              // }
              // window.open('https://outlook.office.com/mail/inbox#compose', '_blank');
              window.location.href = `${`TEL:${data?.phone_number}`}`;
            }}
          />
        </Stack>
        <Stack direction="row" sx={{ mt: 1 }}>
          <ProfileStat
            label="Balance"
            value={
              !!data?.balance_decrypt
                ? `$${formatMoney(data?.balance_decrypt || 0)}`
                : `$0`
            }
          />
          <ProfileStat
            label="Rating"
            value={`${+Number(data?.total_rating || 0).toFixed(1)}`}
            onClick={() => navigate(`/talents/${data?.id}/reviews`)}
          />
          <ProfileStat
            label="Job Success"
            value={`${+Number(dataTotalJobSuccessRate?.total || 0).toFixed(
              2
            )}%`}
            isLoading={isLoadingTotalJobSuccessRate}
          />
          <ProfileStat
            label="Jobs"
            value={`${dataTotalJobHired?.total || 0}`}
            isLoading={isLoadingTotalJobHired}
          />
          <ProfileStat
            label="Total Earned"
            value={`$${formatCash(Number(dataTotalEarned?.total || 0))}`}
            isLoading={isLoadingTotalEarned}
          />
        </Stack>
      </Stack>
      <MoreMenu talent={data} />
    </Stack>
  );
};

const TalentDetailPage = () => {
  const { talentId } = useParams();
  const [tab, setTab] = React.useState("profile");

  const {
    data,
    isFetching: fetching,
    isError,
    error,
  } = useGetTalentByIdQuery(parseInt(talentId!), {
    skip: !talentId,
  });

  useTitle(
    `Talent #${data?.id}`,
    `${data?.first_name} ${data?.last_name} | Talent`
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  //@ts-ignore
  if (isError && error?.status == 404) {
    return (
      <Box>
        <Alert severity="error">
          <AlertTitle>ERROR</AlertTitle>
          Talent not found or deleted
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      {!fetching && <ProfileHeader data={data} />}
      <Box sx={{ mt: 2 }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Profile" value={"profile"} />
          <Tab label="Jobs" value={"jobs"} />
          <Tab label="Finances" value={"funds"} />
        </Tabs>
      </Box>
      <Box sx={{ mt: 1 }}>
        {tab == "profile" && (
          <>
            {fetching && <LinearProgress />}
            <TalentProfilePanel data={data} />
          </>
        )}
        {tab == "jobs" && <TalentJobsPanel data={data} />}
        {tab == "funds" && <TalentFundsPanel data={data} />}
      </Box>
    </Box>
  );
};
export default TalentDetailPage;
