import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React from "react";
import {
  useGetTotalCompanyQuery,
  useGetVerticalGroupByCompanyQuery,
} from "../../services/report.service";

const ModalStatisticVerticalByCompany = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { data, isLoading, isFetching, isError } =
    useGetVerticalGroupByCompanyQuery(undefined, {
      skip: !open,
    });
  const { data: totalCompany } = useGetTotalCompanyQuery(undefined, {
    skip: !open,
  });

  const sorted = React.useMemo(() => {
    const _data = data?.data;
    if (!_data || !totalCompany) return [];
    const total =
      totalCompany.total_active +
      totalCompany.total_idle +
      totalCompany.total_inactive;
    const sorted = [..._data]
      .sort((a, b) => b.total - a.total)
      .map((it) => ({
        name: it.name,
        total: it.total,
        props: { percent: Number((it.total / total) * 100).toFixed(1) },
      }));
    return sorted || [];
  }, [data, totalCompany]);

  if (isError) return <Box sx={{ textAlign: "center" }}>Error</Box>;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle>Number of Customer With Vertical</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Table size="small" sx={{ mt: 2 }}>
              <TableBody>
                {sorted.map((it, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      {idx + 1}. {it.name}
                    </TableCell>
                    <TableCell align="right">
                      {it.total} - {it.props.percent}%
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default ModalStatisticVerticalByCompany;
