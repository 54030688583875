import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
  GridValueGetterParams,
  GridToolbarExport,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
// import { Stack, Paper, Button } from "@mui/material";
import {
  Button,
  Link,
  Paper,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { Project } from "../../types/project";
import { useNavigate } from "react-router-dom";
import { useGetProjectsQuery } from "../../services/project.service";
import dayjs from "dayjs";
import ProjectDrawer from "./ProjectDrawer";
import ChipProjectStatus from "../../components/ChipProjectStatus";
import { formatWithTimezone } from "../../utils/dateHelper";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <GridToolbarExport
          sx={{ ml: 2 }}
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Jobs" }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const ProjectsPage = () => {
  useTitle("Jobs");
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);

  const { data, isLoading, isFetching } = useGetProjectsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : "start_date",
    sortMode: sortModel.length > 0 ? sortModel[0].sort : "desc",
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const handleEditRow = (row: GridRowModel<Project>) => {
    //
  };

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "job_title",
        headerName: "Title",
        minWidth: 400,
        flex: 1,
        sortingOrder: ["desc", "asc", null],
        filterable: true,
        // valueGetter: (params: GridValueGetterParams<Project>) =>
        //   `${params.row.name} ${params.row.last_name}`,
        renderCell: (params: GridRenderCellParams<undefined, Project>) => (
          <Link
            component="button"
            variant="body2"
            onClick={() => onView(params.row)}
          >
            #{params.row.id} - {params.row.title}
          </Link>
        ),
        disableExport: true,
      },
      {
        field: "id",
        headerName: "ID",
        hide: true,
      },
      {
        field: "title",
        headerName: "Title",
        hide: true,
      },
      {
        field: "company.name",
        headerName: "Company",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: true,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          `${params.row?.company?.name}`,
      },
      {
        field: "customer_id",
        headerName: "Client / Location",
        headerAlign: "center",
        minWidth: 250,
        sortable: true,
        filterable: true,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, Project>) =>
          !!params.row.customer ? (
            <Typography variant="body2">
              {params.row.customer.first_name} {params.row.customer.last_name}
            </Typography>
          ) : !!params.row.location ? (
            <Typography variant="body2">{params.row.location.name}</Typography>
          ) : (
            <Typography>?</Typography>
          ),
        disableExport: true,
      },
      {
        field: "customer",
        headerName: "Client/Location",
        hide: true,
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          !!params.row.customer
            ? `${params.row.customer.first_name} ${params.row.customer.last_name}`
            : !!params.row.location
            ? params.row.location.name
            : `?`,
      },
      {
        field: "state_city",
        headerName: "City, State",
        headerAlign: "center",
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          !!params.row.customer
            ? `${params.row.customer.city}, ${params.row.customer.state} ${params.row.customer.zip}`
            : !!params.row.location
            ? `${params.row.location.city}, ${params.row.location.state} ${params.row.location.zip}`
            : `?`,
      },
      {
        field: "is_private",
        headerName: "Is Private",
        headerAlign: "center",
        minWidth: 50,
        sortable: true,
        filterable: true,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, Project>) =>
          !!params.row.is_private ? (
            <Typography variant="body2">Yes</Typography>
          ) : (
            <Typography variant="body2">No</Typography>
          ),
      },
      {
        field: "created_at",
        headerName: "Created At",
        headerAlign: "center",
        minWidth: 200,
        sortable: true,
        filterable: true,
        align: "left",
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          `${formatWithTimezone(
            params.row.created_at,
            params.row.timezone,
            "MMM-D-YY - h:mmA z"
          )}`,
      },
      {
        field: "start_at",
        headerName: "Start At",
        headerAlign: "center",
        minWidth: 200,
        sortable: true,
        filterable: true,
        align: "left",
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          `${formatWithTimezone(
            params.row.start_date,
            params.row.timezone,
            "MMM-D-YY - h:mmA z"
          )}`,
      },
      {
        field: "status",
        headerName: "Status",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: true,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, Project>) => (
          <ChipProjectStatus status={params.row.status} />
        ),
      },
      {
        field: "reason",
        headerName: "Reason",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: true,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          params.row.canceled_reason,
      },
      {
        field: "type",
        headerName: "Job Type",
        hide: true,
      },
      {
        field: "fixed_price_fee",
        headerName: "Fixed Fee",
        hide: true,
      },
      {
        field: "tool_required",
        headerName: "Tool Required",
        hide: true,
      },
      {
        field: "is_photo_required",
        headerName: "Photo Required",
        hide: true,
      },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    navigate(`/jobs/create`);
  }, []);

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  const onView = (item: any) => {
    setCurrentItem(item);
    setOpen(true);
  };

  return (
    <Paper style={{ height: "85vh" }}>
      <DataGrid
        rows={rows || []}
        columns={columns}
        paginationMode="server"
        rowCount={rowCount}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newSize) => setPageSize(newSize)}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        loading={isLoading || isFetching}
        components={{
          LoadingOverlay: LinearProgress,
          NoResultsOverlay: NoResultsOverlay,
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        disableColumnFilter
        // rowHeight={42}
        density="compact"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            borderTop: "1px solid #ddd",
          },
          "& .deleted": {
            fontStyle: "italic",
            color: "#aaa",
          },
        }}
        getRowClassName={getRowClassName}
      />
      <ProjectDrawer data={currentItem!} open={open} setOpen={setOpen} />
    </Paper>
  );
};
export default ProjectsPage;
