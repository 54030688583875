import { ROLE_ADMIN_ID } from "../configs/api";
import { Admin } from "../types/users";
import { isFile } from "../utils/helper";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "Admins";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const adminApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getAdmins: builder.query<
      QueryResponse<Admin>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
      }
    >({
      query: ({ page, pageSize, sortField, sortMode, search }) => ({
        url: "api/admin/users",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `first_name|asc`,
          withTrashed: true,
          role: ROLE_ADMIN_ID,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getAdminById: builder.query<Admin, number>({
      query: (id) => ({
        url: `api/admin/users/${id}`,
        method: "GET",
        params: {
          load: "photo",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    addAdmin: builder.mutation<Admin, Omit<Admin, "id">>({
      query: (body) => {
        let formData = new FormData();
        formData.append("role", ROLE_ADMIN_ID.toString());
        Object.entries(body).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            // formData.append(key, JSON.stringify(value));
            value.forEach((it: any) => {
              formData.append(`${key}[]`, it);
            });
          } else if (key == "photo") {
            if (isFile(value)) formData.append(key, value as any);
          } else formData.append(key, value as string);
        });
        return {
          url: `api/admin/users`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    editAdmin: builder.mutation<Admin, Partial<Admin> & Pick<Admin, "id">>({
      query: (body) => {
        if (!body.photo) delete body.photo;

        let formData = new FormData();
        formData.append("_method", "put");
        formData.append("role", ROLE_ADMIN_ID.toString());

        Object.entries(body).forEach(([key, value]) => {
          if (typeof value == "number") formData.append(key, value.toString());
          else if (Array.isArray(value)) {
            // formData.append(key, JSON.stringify(value));
            value.forEach((it: any) => {
              formData.append(`${key}[]`, it);
            });
          } else if (key == "photo") {
            if (isFile(value)) formData.append(key, value as any);
          } else formData.append(key, value as string);
        });

        return {
          url: `api/admin/users/${body.id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteAdmin: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    restoreAdmin: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/users/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAdminsQuery,
  useGetAdminByIdQuery,
  useAddAdminMutation,
  useEditAdminMutation,
  useDeleteAdminMutation,
  useRestoreAdminMutation,
} = adminApi;
