import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  //   Avatar,
  Box,
  //   Chip,
  //   Divider,
  Drawer,
  Button,
  //   Grid,
  List,
  ListItem,
  //   ListItemAvatar,
  //   ListItemIcon,
  ListItemText,
  //   ListItemButton,
  //   Rating,
  //   Stack,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import MailIcon from "@mui/icons-material/Mail";
// import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import { Company } from "../../types/company";
// import { GoogleMap, LoadScript } from "@react-google-maps/api";
// import { GMAP_API_KEY } from "../../configs/api";
import MapPreview from "../../components/MapPreview";
import Linkify from "../../components/Linkify";

// type Anchor = 'top' | 'left' | 'bottom' | 'right';

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2),
  marginTop: 40,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const containerStyle = {
  width: "100%",
  height: "400px",
};

const options = {
  fullscreenControl: false, // remove the top-right button
  mapTypeControl: false, // remove the top-left buttons
  streetViewControl: false, // remove the pegman
  zoomControl: false, // remove the bottom-right buttons
  scrollwheel: false,
};

interface CompanyDrawerProps {
  data: Company;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CompanyDrawer(props: CompanyDrawerProps) {
  const { data, open, setOpen } = props;
  const navigate = useNavigate();
  const [lng, lat] = React.useMemo(
    () => data?.coordinate?.coordinates || [],
    [data]
  );

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  return (
    <div>
      <React.Fragment>
        {/*<Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>*/}
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          <Box sx={{ width: 450, mb: 8 }} role="presentation">
            <DrawerHeader>
              <ClearIcon
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              />
            </DrawerHeader>
            <MapPreview lat={lat} lng={lng} />
            <List>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={`${data?.name || ""}`}
                  primaryTypographyProps={{ fontWeight: "500", fontSize: 18 }}
                  secondary={data?.city}
                  sx={{ flex: 1 }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`HQ Address`}
                  primaryTypographyProps={{ fontWeight: "500" }}
                  secondary={`${data?.headquarters_address_1}, ${data?.city}, ${data?.zip}`}
                />
              </ListItem>
              {/* <ListItem>
                <ListItemText
                  primary={`Billing Address`}
                  primaryTypographyProps={{ fontWeight: "500" }}
                  secondary={`${data?.billing_address_1}, ${data?.billing_city}, ${data?.billing_zip}`}
                />
              </ListItem> */}
              <ListItem>
                <ListItemText
                  primary={`Phone number`}
                  primaryTypographyProps={{ fontWeight: "500" }}
                  secondary={`${data?.phone_number}`}
                />
              </ListItem>
              {/* <ListItem>
                <ListItemText primary={`Email`} secondary={`${data?.email}`} />
              </ListItem> */}
              <ListItem>
                <ListItemText
                  primary={`Socials`}
                  primaryTypographyProps={{ fontWeight: "500" }}
                  secondary={
                    <Box sx={{ mt: 1 }}>
                      <Typography sx={{ fontSize: 14 }}>
                        LinkedIn: <Linkify url={data?.linked_in} />
                      </Typography>
                      <Typography sx={{ fontSize: 14 }}>
                        Facebook: <Linkify url={data?.facebook} />
                      </Typography>
                      <Typography sx={{ fontSize: 14 }}>
                        Instagram: <Linkify url={data?.instagram} />
                      </Typography>
                      <Typography sx={{ fontSize: 14 }}>
                        Website: <Linkify url={data?.website} />
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            </List>
            <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
              <Button
                variant="contained"
                onClick={() => navigate(`/clients/${data?.id}`)}
              >
                More Detail
              </Button>
              <Button
                variant="contained"
                sx={{ ml: 2 }}
                onClick={() => navigate(`/clients/${data?.id}/edit`)}
              >
                Edit Company
              </Button>
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
