import { ProjectReview } from "../types/project";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE_REVIEW: string = "reviews";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_REVIEW],
});

export const reviewApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTalentReviews: builder.query<Array<ProjectReview>, number>({
      query: (talent_id) => ({
        url: `api/talents/${talent_id}/review`,
        method: "GET",
        params: {
          sort: "created_at|desc",
          load: "project.company",
        },
      }),
      providesTags: (result, _, talent_id) => [
        { type: TAG_TYPE_REVIEW, talent_id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useGetTalentReviewsQuery } = reviewApi;
