import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridSortModel,
  GridRowClassNameParams,
  GridRowModel,
  GridRenderCellParams,
  GridValueGetterParams,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
// import AddIcon from "@mui/icons-material/Add";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { useNavigate } from "react-router-dom";
import { useGetWithdrawalsQuery } from "../../services/withdrawal.service";
import { Withdrawal } from "../../types/withdrawal";
import dayjs from "dayjs";
import ModalWithdrawalDetailForm from "./ModalWithdrawalDetailForm";
import { toast } from "react-toastify";
import { formatMoney } from "../../utils/helper";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <GridToolbarExport
          sx={{ ml: 2 }}
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Withdrawals" }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const WithdrawalsPage = () => {
  useTitle("Withdrawals");
  const navigate = useNavigate();
  //   const user = useCurrentUser();

  const [formModal, setFormModal] = React.useState<{
    open: boolean;
    data: Withdrawal | null;
    type: string;
  }>({
    open: false,
    data: null,
    type: "",
  });

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const { data, isLoading, isFetching } = useGetWithdrawalsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "created_at",
        headerName: "Time",
        sortingOrder: ["desc", "asc", null],
        sortable: true,
        filterable: false,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams<undefined, Withdrawal>) =>
          `${dayjs(params.row?.created_at).format("MMM DD, YYYY hh:mm A")}`,
      },
      {
        field: "user.first_name",
        headerName: "Talent Name",
        minWidth: 200,
        flex: 1,
        sortable: true,
        filterable: true,
        valueGetter: (params: GridValueGetterParams<undefined, Withdrawal>) =>
          `#${params.row?.user?.id} ${params.row?.user?.full_name}`,
      },
      {
        field: "type",
        headerName: "Type",
        headerAlign: "center",
        sortable: true,
        filterable: true,
        minWidth: 100,
        align: "center",
      },
      {
        field: "stripe_transfer_id",
        headerName: "Trx ID",
        headerAlign: "center",
        sortable: false,
        filterable: false,
        minWidth: 260,
        align: "center",
      },
      {
        field: "total",
        headerName: "Total",
        headerAlign: "center",
        minWidth: 120,
        align: "right",
        type: "number",
        sortable: false,
        filterable: false,
        valueGetter: (params: GridValueGetterParams<undefined, Withdrawal>) =>
          `${formatMoney(parseInt(params.row?.total || "0"))}`,
      },
      // {
      //   field: "notes",
      //   headerName: "Notes",
      //   flex: 1,
      //   sortable: true,
      //   filterable: true,
      // },
      {
        field: "status",
        headerName: "Status",
        headerAlign: "center",
        minWidth: 120,
        align: "center",
        sortable: true,
        filterable: true,
        renderCell: (params: GridRenderCellParams<undefined, Withdrawal>) => (
          <Chip
            label={params.row.status.toUpperCase()}
            size="small"
            color={params.row.status == "completed" ? "success" : undefined}
            sx={{ fontSize: 12 }}
          />
        ),
      },
      // {
      //   field: "control",
      //   headerName: "",
      //   sortable: false,
      //   filterable: false,
      //   renderCell: (params: GridRenderCellParams<undefined, Withdrawal>) => {
      //     if (params.row.status === "new") {
      //       return (
      //         <Button
      //           variant="contained"
      //           color="secondary"
      //           onClick={() => handleDetailRow(params.row, "process")}
      //         >
      //           Process
      //         </Button>
      //       );
      //     } else if (params.row.status === "in-progress") {
      //       return (
      //         <Button
      //           variant="contained"
      //           color="primary"
      //           onClick={() => handleDetailRow(params.row, "confirm")}
      //         >
      //           Done
      //         </Button>
      //       );
      //     } else {
      //       return null;
      //     }
      //   },
      // },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    // setFormModal({ open: true, data: null });
  }, []);

  const handleDetailRow = (row: GridRowModel<Withdrawal>, type: string) => {
    setFormModal({ open: true, data: row, type });
  };

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  return (
    <Box>
      <Paper sx={{ height: "85vh", mt: 2 }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[25, 50, 100]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          // disableColumnFilter
          // rowHeight={42}
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
          getRowClassName={getRowClassName}
        />
        <ModalWithdrawalDetailForm
          open={formModal.open}
          onClose={() => setFormModal({ open: false, data: null, type: "" })}
          data={formModal.data}
          type={formModal.type}
        />
      </Paper>
    </Box>
  );
};
export default WithdrawalsPage;
