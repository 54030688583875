import * as React from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { useGetCountriesQuery } from "../services/country.service";
import { Country } from "../types/country";

const SelectCountry = ({
  onChange,
  value,
}: {
  onChange: (arg?: number) => void;
  value: number;
}) => {
  const {
    data: countries,
    isLoading,
    isFetching,
  } = useGetCountriesQuery({
    sortField: "name",
    sortMode: "asc",
  });

  const selected = React.useMemo(
    () => countries?.find((it) => it.id == value),
    [countries, value]
  );

  return (
    <Autocomplete
      fullWidth
      options={countries || []}
      value={selected || null}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField {...params} label="Country" placeholder="Search..." />
      )}
      onChange={(event: any, newValue: Country | null) => {
        onChange(newValue?.id);
      }}
      loading={isLoading}
    />
  );
};
export default SelectCountry;
