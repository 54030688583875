import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridSortModel,
  GridRowClassNameParams,
  GridValueGetterParams,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
import AddIcon from "@mui/icons-material/Add";
import useDebounce from "../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { useGetBalanceHistoriesQuery } from "../../services/finance.service";
import { BalanceHistory, ManualDeposit } from "../../types/finance";
import { Talent } from "../../types/users";
import dayjs from "dayjs";
import { useCurrentUser } from "../../hooks/useCurrentUser";
// import ModalManualDepositForm from "./ModalManualDepositForm";
import { formatMoney } from "../../utils/helper";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button
          variant="text"
          sx={{ mt: 1 }}
          onClick={() => setQuery("")}
        >
          Reset
        </Button>
      )}
      {/* <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <Button variant="outlined" onClick={onCreate}>
          <AddIcon /> Add Manual Deposit
        </Button>
      </Stack> */}
    </GridToolbarContainer>
  );
};

const TalentFundsPanel = ({ data: dataTalent }: { data?: Talent }) => {
  const navigate = useNavigate();
  const user = useCurrentUser();

  const [formModal, setFormModal] = React.useState<{
    open: boolean;
    data: ManualDeposit | null;
  }>({
    open: false,
    data: null,
  });

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const { data, isLoading, isFetching } = useGetBalanceHistoriesQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
    modelType: "user",
    model: dataTalent?.id,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "created_at",
        headerName: "Time",
        minWidth: 200,
        sortingOrder: ["desc", "asc", null],
        sortable: true,
        filterable: false,
        valueGetter: (
          params: GridValueGetterParams<undefined, BalanceHistory>
        ) => `${dayjs(params.row?.created_at).format("MMM DD, YYYY hh:mm A")}`,
      },
      {
        field: "notes",
        headerName: "Notes",
        minWidth: 400,
        flex: 1,
        sortingOrder: ["desc", "asc", null],
        sortable: false,
        filterable: false,
      },
      {
        field: "credit",
        headerName: "Credit",
        minWidth: 200,
        type: "number",
        sortable: false,
        filterable: false,
        valueGetter: (
          params: GridValueGetterParams<undefined, BalanceHistory>
        ) => `${formatMoney(params.row.credit)}`,
      },
      {
        field: "debit",
        headerName: "Debit",
        minWidth: 200,
        type: "number",
        sortable: false,
        filterable: false,
        valueGetter: (
          params: GridValueGetterParams<undefined, BalanceHistory>
        ) => `${formatMoney(params.row.debit)}`,
      },
      // {
      //   field: "balance",
      //   headerName: "Balance",
      //   flex: 1,
      //   type: "number",
      //   sortable: true,
      //   filterable: true,
      // },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    setFormModal({ open: true, data: null });
  }, []);

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  return (
    <Box>
      <Paper sx={{ height: "65vh", mt: 1 }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[25, 50, 100]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          // disableColumnFilter
          // rowHeight={42}
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
          getRowClassName={getRowClassName}
        />
      </Paper>
    </Box>
  );
};
export default TalentFundsPanel;
