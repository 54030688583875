import { EmailTemplate } from "../types/emailTemplate";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "EmailTemplates";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const emailTemplateApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getEmailTemplates: builder.query<Array<EmailTemplate>, void>({
      query: () => ({
        url: "api/admin/email-templates",
        method: "GET",
      }),
      providesTags: (result) => {
        const data = result;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getEmailTemplateById: builder.query<EmailTemplate, number>({
      query: (id) => ({
        url: `api/admin/email-templates/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    editEmailTemplate: builder.mutation<
      EmailTemplate,
      Partial<EmailTemplate & { skills: number[] }> & Pick<EmailTemplate, "id">
    >({
      query: (body) => ({
        url: `api/admin/email-templates/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
        { type: "VerticalSkills", id: data.id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEmailTemplatesQuery,
  useGetEmailTemplateByIdQuery,
  useEditEmailTemplateMutation,
} = emailTemplateApi;
