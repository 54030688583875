import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import { Admin } from "../../types/users";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetAdminByIdQuery,
  useAddAdminMutation,
  useEditAdminMutation,
} from "../../services/admin.service";

interface ModalAdminFormProps {
  open: boolean;
  onClose: () => void;
  data: Admin | null;
}

const ModalAdminForm = ({ open, onClose, data }: ModalAdminFormProps) => {
  const { data: admin } = useGetAdminByIdQuery(data?.id!, {
    skip: !data?.id,
  });

  const [add, { isLoading: adding }] = useAddAdminMutation();
  const [edit, { isLoading: editing }] = useEditAdminMutation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!data) await add(payload).unwrap();
      else {
        if (!payload.password) delete payload.password;
        payload.id = data?.id!;
        await edit(payload).unwrap();
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("first_name", data?.first_name);
      setValue("last_name", data?.last_name);
      setValue("phone_number", data?.phone_number);
      setValue("email", data?.email);
      setValue("password", "");
    }
  }, [data, open]);

  React.useEffect(() => {
    setValue("first_name", admin?.first_name);
    setValue("last_name", admin?.last_name);
    setValue("phone_number", data?.phone_number);
    setValue("email", admin?.email);
    setValue("password", "");
  }, [admin]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} Admin User
        </DialogTitle>
        <DialogContent>
          <Box>
            <Controller
              name="first_name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="First name"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.first_name}
                  helperText={!!errors.first_name && "This field is required"}
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Last name"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.last_name}
                  helperText={!!errors.last_name && "This field is required"}
                />
              )}
            />
            <Controller
              name="phone_number"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Phone number"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Email"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  type="email"
                  error={!!errors.email}
                  helperText={!!errors.email && "This field is required"}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: !!data ? false : true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Password"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  type="new-password"
                  error={!!errors.password}
                  helperText={
                    !data
                      ? !!errors.password && "This field is required"
                      : "Leave blank if you don't want to change the password"
                  }
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={adding || editing}
          >
            {!data ? "Create" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalAdminForm;
