import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const SelectSimple = ({
  id,
  data,
  onChange,
  title,
  sx,
  value,
}: {
  id?: string;
  data: Array<any>;
  onChange: (event: SelectChangeEvent) => void;
  title: string;
  sx?: any;
  value: string;
}) => {
  return (
    // <Box sx={{ minWidth: 120, mt: 0 }}>
    <FormControl fullWidth size="small" sx={sx}>
      <InputLabel
        id={`${id ? id + "-simple-select-label" : "demo-simple-select-label"}`}
      >
        {title}
      </InputLabel>
      <Select
        labelId={`${
          id ? id + "-simple-select-label" : "demo-simple-select-label"
        }`}
        id={`${id ? id + "-simple-select" : "demo-simple-select"}`}
        value={value}
        label="Period"
        onChange={onChange}
      >
        {data &&
          data.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
    // </Box>
  );
};

export default SelectSimple;
