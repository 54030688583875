import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { useGetFinanceReportQuery } from "../../services/report.service";
import { FinancialReport } from "../../types/report";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import SelectCompany from "../../components/SelectCompany";

const initStartDate = dayjs().startOf("month").toDate();
const initEndDate = dayjs().toDate();

const TableToolbar = ({
  onFilter,
}: {
  onFilter: (company: number, startDate: Date, endDate: Date) => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const [company, setCompany] = React.useState<number>(0);
  const [start, setStart] = React.useState<Date>(initStartDate);
  const [end, setEnd] = React.useState<Date>(initEndDate);

  React.useEffect(() => {
    onFilter(company, start, end);
  }, [company, start, end]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <Box sx={{ mr: 2, width: 300 }}>
        <SelectCompany
          onChange={(val) => setCompany(val || 0)}
          value={company || 0}
          sx={{ mt: 1 }}
        />
      </Box>
      <DesktopDatePicker
        label="Start"
        inputFormat="MMM DD, YYYY"
        value={start}
        onChange={(value) => setStart(value || new Date())}
        renderInput={(params) => (
          <TextField {...params} sx={{ mt: 1, width: 200 }} />
        )}
      />
      <Box sx={{ ml: 1, mr: 1 }}>to</Box>
      <DesktopDatePicker
        label="End"
        inputFormat="MMM DD, YYYY"
        value={end}
        onChange={(value) => setEnd(value || new Date())}
        renderInput={(params) => (
          <TextField {...params} sx={{ mt: 1, width: 200 }} />
        )}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Finance Report" }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const FinanceReportPage = () => {
  useTitle("Finance Report");

  const [pageSize, setPageSize] = React.useState(100);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [filter, setFilter] = React.useState<[number, Date, Date]>([
    0,
    initStartDate,
    initEndDate,
  ]);

  const { data, isLoading, isFetching } = useGetFinanceReportQuery({
    companyId: !!filter[0] ? filter[0] : undefined,
    start_date: dayjs(filter[1]).format("YYYY-MM-DD"),
    end_date: dayjs(filter[2]).format("YYYY-MM-DD"),
  });
  const rowCount = React.useMemo(() => data?.length, [data]);

  const rows: GridRowsProp | undefined = React.useMemo(
    () =>
      data?.map((it, _id) => ({
        ...it,
        id: _id,
      })),
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "job",
        headerName: "Job",
        minWidth: 300,
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `#${params.row.job_id} ${params.row.title}`,
      },
      {
        field: "project_site_number",
        headerName: "Site Number",
        minWidth: 120,
      },
      {
        field: "location",
        headerName: "Client/Location",
        minWidth: 200,
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `${params.row.location_name}`,
      },
      {
        field: "project_state",
        headerName: "Job State",
        minWidth: 100,
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `${params.row.project_state}`,
      },
      {
        field: "company",
        headerName: "Company",
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `${params.row.company_name}`,
      },
      {
        field: "start_date",
        headerName: "Start",
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `${dayjs(params.row?.start_date).format("MMM DD, YYYY hh:mm A")}`,
      },
      // {
      //   field: "percentage_uplift",
      //   headerName: "Company Uplift(%)",
      // },
      {
        field: "talent_name",
        headerName: "Talent",
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `#${params.row.talent_id} ${params.row.talent_name}`,
      },
      {
        field: "type",
        headerName: "Contract",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `${params.row.type.toUpperCase()}`,
      },
      {
        field: "price",
        headerName: "Rate/Price",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) =>
          `$${
            params.row.type == "hourly"
              ? params.row.rate_hour
              : params.row.fix_price
          }`,
      },
      {
        field: "total_working",
        headerName: "Hour On Site",
        headerAlign: "center",
        align: "center",
      },
      {
        field: "total_earning",
        headerName: "Labor Cost",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `$${Number(params.row.total_earning)?.toFixed(2) || ""}`,
      },
      // {
      //   field: "total_project_uplift",
      //   headerName: "Ticket Fee",
      //   align: "right",
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) => `${params.row.total_project_uplift?.toFixed(2) || ""}`,
      // },
      {
        field: "total_additional_hours",
        headerName: "Additional Hours",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `$${params.row.total_additional_hours?.toFixed(2) || 0}`,
      },
      // {
      //   field: "total_additional_hours_uplift",
      //   headerName: "AH-Fee",
      //   align: "right",
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) => `${params.row.total_additional_hours_uplift?.toFixed(2) || ""}`,
      // },
      {
        field: "total_reimbursement",
        headerName: "Reimbursement",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `$${params.row.total_reimbursement?.toFixed(2) || 0}`,
      },
      // {
      //   field: "total_reimbursement_uplift",
      //   headerName: "R-Fee",
      //   align: "right",
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) => `${params.row.total_reimbursement_uplift?.toFixed(2) || ""}`,
      // },
      {
        field: "total_bonus",
        headerName: "Bonus",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `$${params.row.total_bonus?.toFixed(2) || 0}`,
      },
      // {
      //   field: "total_bonus_uplift",
      //   headerName: "B-Fee",
      //   align: "right",
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) => `${params.row.total_bonus_uplift?.toFixed(2) || ""}`,
      // },
      {
        field: "income",
        headerName: "P-Fees",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => `$${Number(params.row.fixed_price_fee)?.toFixed(2) || ""}`,
      },
      // {
      //   field: "payroll",
      //   headerName: "Total Cost",
      //   headerAlign: "center",
      //   align: "center",
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, FinancialReport>
      //   ) => {
      //     const totalEarning = Number(params.row.total_earning) || 0;
      //     const totalBonus = Number(params.row.total_bonus) || 0;
      //     const totalReimbursement =
      //       Number(params.row.total_reimbursement) || 0;
      //     const totalAdditionalHours =
      //       Number(params.row.total_additional_hours) || 0;

      //     const total =
      //       totalEarning +
      //       totalBonus +
      //       totalReimbursement +
      //       totalAdditionalHours;

      //     return `$${total.toFixed(2)}`;
      //   },
      // },
      {
        field: "gross",
        headerName: "G-Revenue",
        headerAlign: "center",
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, FinancialReport>
        ) => {
          const totalEarning = Number(params.row.total_earning) || 0;
          const totalBonus = Number(params.row.total_bonus) || 0;
          const totalReimbursement =
            Number(params.row.total_reimbursement) || 0;
          const totalAdditionalHours =
            Number(params.row.total_additional_hours) || 0;
          const totalFixedUplift = Number(params.row.fixed_price_fee) || 0;

          const total =
            totalEarning +
            totalBonus +
            totalReimbursement +
            totalAdditionalHours +
            totalFixedUplift;

          return `$${total.toFixed(2)}`;
        },
      },
    ],
    []
  );

  const handleFilter = React.useCallback(
    (company: number, start: Date, end: Date) => {
      setFilter([company, start, end]);
    },
    []
  );

  const CustomToolbar = React.useMemo(
    () => () => <TableToolbar onFilter={handleFilter} />,
    [handleFilter]
  );

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Paper style={{ height: "85vh" }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          // paginationMode="server"
          rowCount={rowCount}
          // page={page}
          // onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[100]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          // sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnFilter
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
        />
      </Paper>
    </Box>
  );
};
export default FinanceReportPage;
