import { AppVersion } from "../types/appVersion";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "AppVersions";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const appVersionApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getAppVersions: builder.query<
      QueryResponse<AppVersion>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
      }
    >({
      query: ({ page, pageSize, sortField, sortMode, search }) => ({
        url: "api/admin/app-versions",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `version|desc`,
          withTrashed: true,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getAppVersionById: builder.query<AppVersion, number>({
      query: (id) => ({
        url: `api/admin/app-versions/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    addAppVersion: builder.mutation<AppVersion, Omit<AppVersion, "id">>({
      query: (body) => {
        // let formData = new FormData();
        // Object.entries(body).forEach(([key, value]) => {
        //   if (typeof value == "number") formData.append(key, value.toString());
        //   else formData.append(key, value as string);
        // });
        return {
          url: `api/admin/app-versions`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    editAppVersion: builder.mutation<
      AppVersion,
      Partial<AppVersion> & Pick<AppVersion, "id">
    >({
      query: (body) => {
        // let formData = new FormData();
        // // formData.append("_method", "put");

        // Object.entries(body).forEach(([key, value]) => {
        //   if (typeof value == "number") formData.append(key, value.toString());
        //   else formData.append(key, value as string);
        // });

        return {
          url: `api/admin/app-versions/${body.id}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteAppVersion: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/app-versions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    restoreAppVersion: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/app-versions/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    enabledAppVersion: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/app-versions/${id}/enabled`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    disabledAppVersion: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/app-versions/${id}/disabled`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAppVersionsQuery,
  useGetAppVersionByIdQuery,
  useAddAppVersionMutation,
  useEditAppVersionMutation,
  useDeleteAppVersionMutation,
  useRestoreAppVersionMutation,
  useEnabledAppVersionMutation,
  useDisabledAppVersionMutation,
} = appVersionApi;
