import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useGetAllCompaniesQuery } from "../services/company.service";
import { Company } from "../types/company";

const SelectCompany = ({
  onChange,
  sx,
  value,
  error,
  helperText,
}: {
  onChange: (arg?: number) => void;
  sx?: any;
  value: number;
  error?: boolean;
  helperText?: React.ReactNode;
}) => {
  const {
    data: companies,
    isLoading,
    isFetching,
  } = useGetAllCompaniesQuery({
    sortField: "name",
    sortMode: "asc",
  });

  const selected = React.useMemo(
    () => companies?.find((it) => it.id === value),
    [companies, value]
  );

  return (
    <Autocomplete
      fullWidth
      options={companies || []}
      value={selected || null}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Company"
          placeholder="Search..."
          sx={sx}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(event: any, newValue: Company | null) => {
        onChange(newValue?.id);
      }}
      loading={isLoading}
    />
  );
};
export default SelectCompany;
