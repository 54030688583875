import React from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { useGetTotalCompanyQuery } from "../../services/report.service";
import DonutChart from "./DonutChart";

interface DonutChartCustomerProps {
  id?: string;
}

const DonutChartCustomer = (props: DonutChartCustomerProps) => {
  const { id } = props;
  const { data: dataApi, isFetching, isLoading } = useGetTotalCompanyQuery();
  const theme = useTheme();

  const data = React.useMemo(() => {
    return [
      {
        name: "Active",
        value: dataApi?.total_active || 0,
        color: theme.palette.primary.main,
      },
      {
        name: "Not Active",
        value: dataApi?.total_inactive || 0,
        color: theme.palette.grey[500],
      },
      {
        name: "Idle",
        value: dataApi?.total_idle || 0,
        color: theme.palette.secondary.main,
      },
    ];
  }, [dataApi]);

  if (isLoading)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );

  return <DonutChart id={id} data={data} />;
};

export default DonutChartCustomer;
