import * as React from "react";
import { GridRowsProp, GridSortModel } from "@mui/x-data-grid";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
  Stack,
  Chip,
  LinearProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetProjectsQuery } from "../../services/project.service";
import dayjs from "dayjs";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { Project } from "../../types/project";

const ProjectNewsPanel = () => {
  const navigate = useNavigate();
  const user = useCurrentUser();

  const { data, isLoading, refetch, isFetching } = useGetProjectsQuery({
    page: 0,
    pageSize: 5,
    sortField: "created_at",
    sortMode: "desc",
  });

  const rows: Project[] = React.useMemo(() => data?.data || [], [data]);

  if (isLoading)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );

  if (rows && rows?.length == 0)
    return (
      <Box>
        <Typography>No projects, yet!</Typography>
      </Box>
    );

  return (
    <Box>
      {isFetching && <LinearProgress />}
      <List>
        {rows?.map((row, index) => (
          <>
            <ListItem key={index}>
              <ListItemText
                primary={
                  <Link
                    onClick={() => navigate(`/jobs/${row.id}`)}
                    component="button"
                    variant="body2"
                  >
                    #${row.id} - ${row.title}
                  </Link>
                }
                secondary={
                  <>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {`${row?.company?.name}`}
                    </Typography>
                    {" — "}
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="caption"
                    >
                      {!!row.customer
                        ? `${row.customer.first_name} ${row.customer.last_name}`
                        : !!row.location
                        ? row.location.name
                        : "?"}
                    </Typography>
                  </>
                }
              />
              <Stack sx={{ alignItems: "flex-end" }}>
                {/* <Chip label={row?.status} size="small" /> */}
                <Typography variant="caption">
                  {row?.status?.toUpperCase()}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {dayjs(row?.created_at).format("MMM DD, YYYY hh:mm A")}
                </Typography>
              </Stack>
            </ListItem>
            {index < rows.length - 1 && <Divider component="li" />}
          </>
        ))}
      </List>
      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <Button variant="outlined" onClick={() => navigate(`/jobs`)}>
          More
        </Button>
        <Button onClick={refetch} sx={{ ml: 2 }}>
          Refresh
        </Button>
      </Box>
    </Box>
  );
};
export default ProjectNewsPanel;
