import { BusinessVertical } from "../types/vertical";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "Verticals";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const verticalApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessVerticals: builder.query<
      QueryResponse<BusinessVertical>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        withTrashed?: boolean;
      }
    >({
      query: ({
        page,
        pageSize,
        sortField,
        sortMode,
        search,
        withTrashed = true,
      }) => ({
        url: "api/admin/business-verticals",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          withTrashed,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getAllBusinessVerticals: builder.query<
      Array<BusinessVertical>,
      { sortField?: string; sortMode?: string | null; search?: string }
    >({
      query: ({ sortField, sortMode, search }) => ({
        url: "api/business-verticals",
        method: "GET",
        params: {
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST", mode: "ALL" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST", mode: "ALL" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getBusinessVerticalById: builder.query<BusinessVertical, number>({
      query: (id) => ({
        url: `api/admin/business-verticals/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    addBusinessVertical: builder.mutation<
      BusinessVertical & { skills: number[] },
      Omit<BusinessVertical, "id">
    >({
      query: (body) => ({
        url: `api/admin/business-verticals`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    editBusinessVertical: builder.mutation<
      BusinessVertical,
      Partial<BusinessVertical & { skills: number[] }> &
        Pick<BusinessVertical, "id">
    >({
      query: (body) => ({
        url: `api/admin/business-verticals/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
        { type: "VerticalSkills", id: data.id },
      ],
    }),
    deleteBusinessVertical: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/business-verticals/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    restoreBusinessVertical: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/business-verticals/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBusinessVerticalsQuery,
  useGetAllBusinessVerticalsQuery,
  useGetBusinessVerticalByIdQuery,
  useAddBusinessVerticalMutation,
  useEditBusinessVerticalMutation,
  useDeleteBusinessVerticalMutation,
  useRestoreBusinessVerticalMutation,
} = verticalApi;
