import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import { ManualDeposit } from "../../types/finance";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAddManualDepositMutation } from "../../services/finance.service";
import SelectReason from "../../components/SelectReason";
import SelectCompany from "../../components/SelectCompany";

interface ModalManualDepositFormProps {
  open: boolean;
  onClose: () => void;
  data: ManualDeposit | null;
}

const ModalManualDepositForm = ({
  open,
  onClose,
  data,
}: ModalManualDepositFormProps) => {
  const [add, { isLoading: adding }] = useAddManualDepositMutation();

  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      await add(payload).unwrap();
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("reason", data?.reason?.id);
      setValue("company", data?.company?.id);
      setValue("amount", data?.amount || 100);
      setValue("notes", data?.notes);

      clearErrors();
    }
  }, [data, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Add" : "Edit"} Manual Deposit
        </DialogTitle>
        <DialogContent>
          <Box>
            <Controller
              name="reason"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SelectReason
                  onChange={onChange}
                  value={value}
                  error={!!errors.reason}
                  helperText={!!errors.reason && "This field is required"}
                />
              )}
            />
            <Controller
              name="company"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SelectCompany
                  onChange={onChange}
                  value={value}
                  error={!!errors.company}
                  helperText={!!errors.company && "This field is required"}
                />
              )}
            />
            <Controller
              name="amount"
              control={control}
              rules={{ required: true, min: 100 }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Amount (min: 100)"
                  value={value}
                  type="number"
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.amount}
                  helperText={!!errors.amount && "This field is required"}
                />
              )}
            />
            <Controller
              name="notes"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Notes"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={adding}>
            {!data ? "Create" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalManualDepositForm;
