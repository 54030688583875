import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
  GridToolbarExport,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Stack,
  Paper,
  Button,
  Link,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { toast } from "react-toastify";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { ForumThread } from "../../types/forum";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  useDeleteForumThreadMutation,
  useGetForumThreadsQuery,
} from "../../services/forum.service";
import DeleteOutline from "@mui/icons-material/DeleteOutline";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <GridToolbarExport
          sx={{ ml: 2 }}
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Social Feeds" }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const SocialFeedsPage = () => {
  useTitle("Social Feeds");
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    row: GridRowModel<ForumThread> | null;
  }>({
    open: false,
    row: null,
  });
  const [formModal, setFormModal] = React.useState<{
    open: boolean;
    data: ForumThread | null;
  }>({
    open: false,
    data: null,
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const { data, isLoading, isFetching } = useGetForumThreadsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const [doDelete, { isLoading: deleting }] = useDeleteForumThreadMutation();

  const handleDeleteRow = (row: GridRowModel<ForumThread>) => {
    setDeleteModal({ open: true, row });
  };

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: "id", width: 50 },
      { field: "title", hide: true },
      {
        field: "user",
        hide: true,
        valueGetter: (params: GridValueGetterParams<undefined, ForumThread>) =>
          params.row.user?.full_name,
      },
      {
        field: "thread",
        headerName: "Thread Title / Poster",
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<undefined, ForumThread>) => (
          <Box>
            <Box>
              <Link
                onClick={() => navigate(`/social/feeds/${params.row.id}`)}
                component="button"
                variant="body2"
              >
                #{params.row.title}
              </Link>
            </Box>
            <Typography variant="caption">
              {params.row.user?.full_name}
            </Typography>
          </Box>
        ),
      },
      // {
      //   field: "info",
      //   headerName: "Views/ Comments/ Likes",
      //   minWidth: 200,
      //   disableExport: true,
      //   renderCell: (params: GridRenderCellParams<undefined, ForumThread>) => (
      //     <Stack direction={"row"} alignItems={"center"}>
      //       <Typography variant="body2">{params.row.total_view}</Typography>{" "}
      //       <VisibilityOutlined fontSize="inherit" htmlColor="#999" />
      //       <Typography variant="body2" style={{ paddingLeft: 10 }}>
      //         {params.row.total_comments}
      //       </Typography>{" "}
      //       <CommentOutlined fontSize="inherit" htmlColor="#999" />
      //       <Typography variant="body2" style={{ paddingLeft: 10 }}>
      //         {params.row.total_likes}
      //       </Typography>{" "}
      //       <ThumbUpAltOutlined fontSize="inherit" htmlColor="#999" />
      //     </Stack>
      //   ),
      // },
      {
        field: "Views",
        headerName: "# of Views",
        minWidth: 120,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams<undefined, ForumThread>) =>
          params.row.total_view || "-",
      },
      {
        field: "Comments",
        headerName: "# of Comments",
        minWidth: 120,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams<undefined, ForumThread>) =>
          params.row.total_comments || "-",
      },
      {
        field: "Likes",
        headerName: "# of Likes",
        minWidth: 120,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams<undefined, ForumThread>) =>
          params.row.total_likes || "-",
      },
      {
        field: "num_of_doc",
        headerName: "Attachments",
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams<undefined, ForumThread>) =>
          params.row.documents?.length || "-",
      },
      {
        field: "is_locked",
        headerName: "Locked?",
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams<undefined, ForumThread>) =>
          params.row.is_locked ? "Locked" : "-",
      },
      {
        field: "is_pinned",
        headerName: "Pinned?",
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams<undefined, ForumThread>) =>
          params.row.is_pinned ? "Pinned" : "-",
      },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 150,
        align: "center",
        headerAlign: "center",
        valueGetter: (params: GridValueGetterParams<undefined, ForumThread>) =>
          `${dayjs(params.row?.created_at).format("MMM DD, hh:mm A")}`,
      },
      {
        field: "control",
        headerName: "Delete",
        align: "center",
        headerAlign: "center",
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<undefined, ForumThread>) => (
          <Stack direction={"row"}>
            <IconButton onClick={() => handleDeleteRow(params.row)}>
              <DeleteOutline fontSize="small" />
            </IconButton>
          </Stack>
        ),
        disableExport: true,
      },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    setFormModal({ open: true, data: null });
  }, []);

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    if (deleteModal.row?.id) {
      try {
        await doDelete(deleteModal.row.id).unwrap();
        closeDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeDeleteModal();
      }
    }
  };

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  return (
    <Paper style={{ height: "85vh" }}>
      <DataGrid
        rows={rows || []}
        columns={columns}
        paginationMode="server"
        rowCount={rowCount}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newSize) => setPageSize(newSize)}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        loading={isLoading || isFetching}
        components={{
          LoadingOverlay: LinearProgress,
          NoResultsOverlay: NoResultsOverlay,
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        disableColumnFilter
        rowHeight={64}
        density="compact"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            borderTop: "1px solid #ddd",
          },
          "& .deleted": {
            fontStyle: "italic",
            color: "#aaa",
          },
        }}
        getRowClassName={getRowClassName}
      />
      <ConfirmDeleteModal
        open={deleteModal.open}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
        message={`Delete Feed #${deleteModal.row?.id} ?`}
      />
    </Paper>
  );
};
export default SocialFeedsPage;
