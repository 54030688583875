import { Chip, ChipProps } from "@mui/material";
import * as React from "react";
import { ProjectStatus } from "../types/project";

const ChipProjectStatus = ({
  status,
  ...props
}: {
  status: ProjectStatus;
} & ChipProps) => {
  return (
    <Chip
      label={status.toUpperCase()}
      size="small"
      sx={{ fontSize: 12 }}
      color={
        status == ProjectStatus.COMPLETED
          ? "success"
          : status == ProjectStatus.PUBLISHED
          ? "info"
          : status == ProjectStatus.IN_PROGRESS
          ? "warning"
          : status == ProjectStatus.CANCELED
          ? "error"
          : undefined
      }
      {...props}
    />
  );
};

export default ChipProjectStatus;
