import { Skill, SkillCategory } from "../types/skill";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "Skills";
const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE, "VerticalSkills"],
});

export const skillApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getSkills: builder.query<
      QueryResponse<Skill & { category: SkillCategory }>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        withTrashed?: boolean;
      }
    >({
      query: ({
        page,
        pageSize,
        sortField,
        sortMode,
        search,
        withTrashed,
      }) => ({
        url: "api/admin/skills",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          withTrashed,
          load: "category",
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getSkillById: builder.query<Skill, number>({
      query: (id) => ({
        url: `api/admin/skills/${id}`,
        method: "GET",
        params: {
          load: "category",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    getSkillsByVertical: builder.query<
      QueryResponse<Skill>,
      { verticalId: number }
    >({
      query: ({ verticalId }) => ({
        url: `api/admin/skills`,
        method: "GET",
        params: {
          page: 1,
          per_page: 1000,
          load: "category",
          businessVertical: verticalId,
        },
      }),
      providesTags: (result, error, param) => [
        { type: TAG_TYPE },
        { type: "VerticalSkills", id: param.verticalId },
      ],
    }),
    addSkill: builder.mutation<Skill, Omit<Skill, "id">>({
      query: (body) => ({
        url: `api/admin/skills`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    editSkill: builder.mutation<Skill, Partial<Skill> & Pick<Skill, "id">>({
      query: (body) => ({
        url: `api/admin/skills/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteSkill: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/skills/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    restoreSkill: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/skills/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSkillsQuery,
  useGetSkillByIdQuery,
  useGetSkillsByVerticalQuery,
  useAddSkillMutation,
  useEditSkillMutation,
  useDeleteSkillMutation,
  useRestoreSkillMutation,
} = skillApi;
