import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridRowClassNameParams,
  GridValueGetterParams,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, Paper, Stack } from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { useTitle } from "../../hooks/useTitle";
import { useGetManualDepositsQuery } from "../../services/finance.service";
import dayjs from "dayjs";
// import ModalManualDepositForm from "./ModalManualDepositForm";
import { ManualDeposit } from "../../types/finance";
import { formatMoney } from "../../utils/helper";

const TableToolbar = () => {
  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <GridToolbarExport
          sx={{ ml: 2 }}
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Manual Deposit" }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const ManualDepositsPage = () => {
  useTitle("Manual Deposits");
  // const navigate = useNavigate();
  // const user = useCurrentUser();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);

  const { data, isLoading, isFetching } = useGetManualDepositsQuery({
    page,
    pageSize,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const CustomToolbar = React.useMemo(() => () => <TableToolbar />, []);

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "created_at",
        headerName: "Time",
        minWidth: 200,
        sortingOrder: ["desc", "asc", null],
        sortable: true,
        filterable: false,
        valueGetter: (
          params: GridValueGetterParams<undefined, ManualDeposit>
        ) => `${dayjs(params.row?.created_at).format("MMM DD, YYYY hh:mm A")}`,
      },
      {
        field: "company.name",
        headerName: "Company Name",
        minWidth: 200,
        sortable: true,
        filterable: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, ManualDeposit>
        ) => `${params.row?.company?.name}`,
      },
      {
        field: "notes",
        headerName: "Notes",
        flex: 1,
        sortable: false,
        filterable: false,
      },
      {
        field: "reason.name",
        headerName: "Reason",
        flex: 1,
        sortable: false,
        filterable: false,
        valueGetter: (
          params: GridValueGetterParams<undefined, ManualDeposit>
        ) => `${params.row?.reason?.name}`,
      },
      {
        field: "amount",
        headerName: "Amount",
        type: "number",
        minWidth: 200,
        sortable: false,
        filterable: false,
        valueGetter: (
          params: GridValueGetterParams<undefined, ManualDeposit>
        ) => `${formatMoney(params.row?.amount || 0)}`,
      },
    ],
    []
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  return (
    <Box>
      <Paper sx={{ height: "85vh", mt: 2 }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[25, 50, 100]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          //   disableColumnFilter
          // rowHeight={42}
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
          getRowClassName={getRowClassName}
        />
      </Paper>
    </Box>
  );
};
export default ManualDepositsPage;
