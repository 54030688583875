import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import PageviewIcon from "@mui/icons-material/Pageview";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";

const ErrorNotFound = () => {
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <PageviewIcon />
        </Avatar>
        <Typography variant="h4" align="center">
          404 - Page Not Found
        </Typography>
        <Typography align="center">
          We are sorry, but the page you are looking for does not exist.
        </Typography>
        <Button onClick={() => navigate("/")} style={{ marginTop: 10 }}>
          Back to Home
        </Button>
      </Box>
    </Container>
  );
};
export default ErrorNotFound;
