import { Project } from "../types/project";
import { isFile } from "../utils/helper";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "Projects";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const projectApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<
      QueryResponse<Project>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        company?: number;
        talents?: number;
      }
    >({
      query: ({
        page,
        pageSize,
        sortField,
        sortMode,
        search,
        company,
        talents,
      }) => ({
        url: "api/admin/projects",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
          withTrashed: true,
          load: "location,customer,manager,company,resources.reportProgresses,reviews",
          search,
          company,
          talents,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getProjectById: builder.query<Project, number>({
      query: (id) => ({
        url: `api/admin/projects/${id}`,
        method: "GET",
        params: {
          load: "company,customer,manager,location,skills,certifications,tasks,resources,documents,verticals,talent,reviews,chats",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    editProject: builder.mutation<
      Project,
      Partial<Project> & Pick<Project, "id">
    >({
      query: (body) => {
        // if (!body.photo) delete body.photo;

        let formData = new FormData();
        formData.append("_method", "put");

        Object.entries(body).forEach(([key, value]) => {
          if (typeof value == "number") formData.append(key, value.toString());
          else if (Array.isArray(value)) {
            // formData.append(key, JSON.stringify(value));
            value.forEach((it: any) => {
              formData.append(`${key}[]`, it);
            });
          } else if (key === "photo") {
            if (isFile(value)) formData.append(key, value as any);
          } else formData.append(key, value as string);
        });

        return {
          url: `api/admin/projects/${body.id}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteProject: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/projects/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    restoreProject: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/projects/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    updateProjectInternalNotes: builder.mutation<
      Project,
      { projectId: number; internal_notes: string; companyId: number }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, internal_notes, companyId }) => {
        return {
          url: `api/company/${companyId}/projects/${projectId}/internal-notes`,
          method: "PUT",
          body: {
            internal_notes,
          },
        };
      },
      invalidatesTags: (result, error, { projectId }) => [
        { type: TAG_TYPE, id: projectId },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useEditProjectMutation,
  useDeleteProjectMutation,
  useRestoreProjectMutation,
  useUpdateProjectInternalNotesMutation,
} = projectApi;
