import React from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { useGetTotalJobQuery } from "../../services/report.service";
import DonutChart from "./DonutChart";

interface DonutChartJobProps {
  id?: string;
}

const DonutChartJob = (props: DonutChartJobProps) => {
  const { id } = props;
  const { data: dataApi, isFetching, isLoading } = useGetTotalJobQuery();
  const theme = useTheme();

  const data = React.useMemo(() => {
    return [
      {
        name: "Completed",
        value: dataApi?.total_completed || 0,
        color: theme.palette.primary.main,
      },
      {
        name: "New",
        value: dataApi?.total_created || 0,
        color: theme.palette.secondary.main,
      },
    ];
  }, [dataApi]);

  if (isLoading)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );

  return <DonutChart id={id} data={data} />;
};

export default DonutChartJob;
