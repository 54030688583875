import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Company } from "../../types/company";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddCompanyMutation,
  useEditCompanyMutation,
  useGetCompanyByIdQuery,
} from "../../services/company.service";
import { useTitle } from "../../hooks/useTitle";
import { Controller, useForm } from "react-hook-form";
import useScroll from "../../hooks/useScroll";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import SelectCountry from "../../components/SelectCountry";
import SelectVertical from "../../components/SelectVertical";
import StickyBox from "react-sticky-box";
import { isManage360 } from "../../utils/helper";
import UploadPhoto from "../talent/UploadPhoto";
import UploadLogo from "./UploadLogo";
import { ProgressBar } from "react-toastify/dist/components";

const CompanyEditPage = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const _isManage360 = React.useMemo(() => isManage360(), []);
  const theme = useTheme();

  const [scrollToBasic, basicRef] = useScroll();
  const [scrollToSetting, settingRef] = useScroll();
  const [scrollToContact, contactRef] = useScroll();
  const [scrollToBilling, billingRef] = useScroll();
  const [scrollToSocial, socialRef] = useScroll();

  const { data, isLoading } = useGetCompanyByIdQuery(parseInt(companyId!), {
    skip: !companyId,
  });
  const [add, { isLoading: adding }] = useAddCompanyMutation();
  const [edit, { isLoading: updating }] = useEditCompanyMutation();

  useTitle(!!data ? "Edit Company" : "Create Company");

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!data) {
        const company: Company = await add(payload).unwrap();
        toast.success("Client created");
        navigate(`/clients/${company.id}`, { replace: true });
      } else {
        if (!payload.password) delete payload.password;
        payload.id = data.id;
        await edit(payload).unwrap();
        toast.success("Client updated");
        navigate(`/clients/${data.id}`, { replace: true });
      }
    } catch (err) {}
  };

  React.useEffect(() => {
    setValue("name", data?.name || "");
    setValue("phone_number", data?.phone_number || "");
    setValue("fax", data?.fax || "");
    setValue("headquarters_address_1", data?.headquarters_address_1 || "");
    setValue("headquarters_address_2", data?.headquarters_address_2 || "");
    setValue("country", data?.country_id || 0);
    setValue("city", data?.city || "");
    setValue("state", data?.state || "");
    setValue("zip", data?.zip || "");
    setValue("linked_in", data?.linked_in || "");
    setValue("facebook", data?.facebook || "");
    setValue("instagram", data?.instagram || "");
    setValue("website", data?.website || "");
    setValue("logo", data?.logo?.[0]?.original_url || "");
    setValue("primary_contact_name", data?.primary_contact_name || "");
    setValue("primary_contact_title", data?.primary_contact_title || "");
    setValue("primary_contact_email", data?.primary_contact_email || "");
    setValue(
      "primary_contact_phone_number",
      data?.primary_contact_phone_number || ""
    );
    setValue("business_vertical", data?.business_vertical_id || 0);
    setValue("color_schema", data?.color_schema || theme.palette.primary.main);
    setValue("billing_email", data?.billing_email);
    setValue("billing_address_1", data?.billing_address_1 || "");
    setValue("billing_address_2", data?.billing_address_2 || "");
    setValue("billing_country", data?.billing_country_id || "");
    setValue("billing_city", data?.billing_city || "");
    setValue("billing_state", data?.billing_state || "");
    setValue("billing_zip", data?.billing_zip || "");
    if (_isManage360) {
      setValue("percentage_uplift", 0);
      setValue("fixed_price_fee", 0);
    } else {
      setValue("percentage_uplift", 0); //force set to 0 - not used anymore
      setValue("fixed_price_fee", data?.fixed_price_fee || 0);
    }
  }, [data, _isManage360]);

  return (
    <Box>
      {isLoading && <LinearProgress />}
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <StickyBox offsetTop={70} offsetBottom={20}>
            <List>
              <ListItem>
                <ListItemButton onClick={scrollToBasic as any}>
                  <ListItemText>General</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToSetting as any}>
                  <ListItemText>Settings</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToContact as any}>
                  <ListItemText>Primary Contact</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToBilling as any}>
                  <ListItemText>Billing Address</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToSocial as any}>
                  <ListItemText>Social Medias</ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </StickyBox>
        </Grid>
        <Grid item xs={9}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Card sx={{ mt: 1 }} ref={basicRef}>
                <CardHeader title="General" />
                <CardContent>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Company's Logo
                  </Typography>
                  <Controller
                    name="logo"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <UploadLogo onChange={onChange} value={value} />
                    )}
                  />
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Company name"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.name}
                        helperText={!!errors.name && "This field is required"}
                      />
                    )}
                  />
                  <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
                    <Controller
                      name="phone_number"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Phone number"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          sx={{ mt: 0 }}
                          error={!!errors.phone_number}
                          helperText={
                            !!errors.phone_number && "This field is required"
                          }
                        />
                      )}
                    />
                    <Controller
                      name="fax"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Fax"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="billing_email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Email (for billings etc)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.billing_email}
                        helperText={
                          !!errors.billing_email && "This field is required"
                        }
                      />
                    )}
                  />
                  <Controller
                    name="headquarters_address_1"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Headquarter Address 1"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.headquarters_address_1}
                        helperText={
                          !!errors.headquarters_address_1 &&
                          "This field is required"
                        }
                      />
                    )}
                  />
                  <Controller
                    name="headquarters_address_2"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Headquarter Address 2 (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Stack direction={"row"} spacing={1} sx={{ mt: 2 }}>
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="City"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          sx={{ mt: 0 }}
                          error={!!errors.city}
                          helperText={!!errors.city && "This field is required"}
                        />
                      )}
                    />
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="State"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!errors.state}
                          helperText={
                            !!errors.state && "This field is required"
                          }
                        />
                      )}
                    />
                    <Controller
                      name="zip"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Zip"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!errors.zip}
                          helperText={!!errors.zip && "This field is required"}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectCountry onChange={onChange} value={value} />
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={settingRef}>
                <CardHeader title="Company Settings" />
                <CardContent>
                  <Controller
                    name="business_vertical"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectVertical onChange={onChange} value={value} />
                    )}
                  />
                  {!_isManage360 && (
                    <Controller
                      name="fixed_price_fee"
                      control={control}
                      rules={{ required: true, min: 0, max: 1000 }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          label="Fixed Fee"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          type="number"
                          error={!!errors.fixed_price_fee}
                          helperText={
                            !!errors.fixed_price_fee && "This field is required"
                          }
                        />
                      )}
                    />
                  )}
                  <Controller
                    name="color_schema"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Color Scheme"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.color_schema}
                        helperText={
                          !!errors.color_schema && "This field is required"
                        }
                      />
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={contactRef}>
                <CardHeader title="Contact Person" />
                <CardContent>
                  <Stack direction="row" spacing={1}>
                    <Controller
                      name="primary_contact_name"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Contact name (optional)"
                          value={value}
                          fullWidth
                          sx={{ mt: 0 }}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                    <Controller
                      name="primary_contact_title"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Title (optional)"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                    <Controller
                      name="primary_contact_email"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Contact email (optional)"
                          value={value}
                          fullWidth
                          sx={{ mt: 0 }}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                    <Controller
                      name="primary_contact_phone_number"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Phonenumber (optional)"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={billingRef}>
                <CardHeader title="Billing Address" />
                <CardContent>
                  <Controller
                    name="billing_address_1"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Billing Address 1 (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="billing_address_2"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Billing Address 2 (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Stack direction={"row"} spacing={1} sx={{ mt: 2 }}>
                    <Controller
                      name="billing_city"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="City (optional)"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          sx={{ mt: 0 }}
                        />
                      )}
                    />
                    <Controller
                      name="billing_state"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="State (optional)"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                    <Controller
                      name="billing_zip"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Zip (optional)"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="billing_country"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectCountry onChange={onChange} value={value} />
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={socialRef}>
                <CardHeader title="Social Medias" />
                <CardContent>
                  <Controller
                    name="linked_in"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="LinkedIn URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="facebook"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Facebook URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="instagram"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Instagram URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="website"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Website URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </CardContent>
              </Card>
              <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 2 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={isLoading}
                  loading={adding || updating}
                  // sx={{ width: "50%" }}
                >
                  {!!data ? "Update Client" : "Create Client"}
                </LoadingButton>
              </Stack>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CompanyEditPage;
