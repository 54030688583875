import * as React from "react";
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEmailTemplatesQuery } from "../../services/email-template.service";
import { useTitle } from "../../hooks/useTitle";
import StickyBox from "react-sticky-box";
import EmailTemplateEditPage from "./EmailTemplateEditPage";
import { EmailTemplate } from "../../types/emailTemplate";

const EmailTemplatesPage = () => {
  useTitle("Email Templates");

  const [currentData, setCurrentData] = React.useState<EmailTemplate | null>(
    null
  );

  const { data, isLoading } = useGetEmailTemplatesQuery();

  const sorted = React.useMemo(() => {
    return data?.slice().sort((a, b) => (a.key > b.key ? 1 : -1));
  }, [data]);

  React.useEffect(() => {
    if (!!sorted && sorted?.length > 0 && !currentData) {
      setCurrentData(sorted[0]);
    }
  }, [currentData, sorted]);

  return (
    <Box>
      {isLoading && <LinearProgress />}
      <Grid container spacing={1}>
        <Grid item lg={3} xs={6}>
          <StickyBox offsetTop={70} offsetBottom={20}>
            <List>
              {sorted?.map((row) => (
                <ListItem key={row.key} dense={true} disablePadding={true}>
                  <ListItemButton onClick={() => setCurrentData(row)}>
                    <ListItemText
                      sx={!!row.template == false ? { color: "#cc0000" } : {}}
                    >
                      {/* {row.key.replaceAll("_", " ").toUpperCase()} */}
                      {row.title}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </StickyBox>
        </Grid>
        <Grid item lg={9} xs={6}>
          <EmailTemplateEditPage data={currentData} />
        </Grid>
      </Grid>
    </Box>
  );
};
export default EmailTemplatesPage;
