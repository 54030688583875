import * as React from "react";
import { Box, Grid, Paper, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { User } from "../../types/users";
import { useTitle } from "../../hooks/useTitle";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { setUser } from "../auth/authSlice";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../hooks/store";

import {
  // useGetAdminByIdQuery,
  useEditAdminMutation,
} from "../../services/admin.service";
import LoadingButton from "@mui/lab/LoadingButton";

const AccountPage = () => {
  useTitle("Account");
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [edit, { isLoading: editing }] = useEditAdminMutation();

  const data = useCurrentUser();

  const onSubmit = async (payload: any) => {
    try {
      if (!payload.password) delete payload.password;
      payload.id = data?.id!;
      await edit(payload).unwrap();
      toast.success("Account updated");
      dispatch(setUser(payload));
    } catch (err) {}
  };

  React.useEffect(() => {
    setValue("first_name", data?.first_name);
    setValue("last_name", data?.last_name);
    setValue("password", "");
    setValue("email", data?.email);
  }, [data]);

  return (
    <Paper>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ p: 4 }}
          >
            <Controller
              name="first_name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="First name"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.first_name}
                  helperText={!!errors.first_name && "This field is required"}
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Last name"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.last_name}
                  helperText={!!errors.last_name && "This field is required"}
                />
              )}
            />
            {/* <Controller
        name="phone_number"
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            label="Phone number"
            value={value}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      /> */}
            {/* <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            label="Email"
            value={value}
            fullWidth
            onChange={onChange}
            onBlur={onBlur}
            type="email"
            error={!!errors.email}
            helperText={!!errors.email && "This field is required"}
          />
        )}
      /> */}
            <Controller
              name="password"
              control={control}
              rules={{ required: !!data ? false : true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Password"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  type="new-password"
                  error={!!errors.password}
                  helperText={
                    !data
                      ? !!errors.password && "This field is required"
                      : "Leave blank if you don't want to change the password"
                  }
                />
              )}
            />

            <Box sx={{ mt: 2 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={editing}
              >
                Update
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default AccountPage;
