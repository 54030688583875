import * as React from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { useGetCountriesQuery } from "../services/country.service";
import { useGetAllBusinessVerticalsQuery } from "../services/vertical.service";
import { BusinessVertical } from "../types/vertical";

const SelectVertical = ({
  onChange,
  value,
}: {
  onChange: (arg?: number) => void;
  value: number;
}) => {
  const {
    data: verticals,
    isLoading,
    isFetching,
  } = useGetAllBusinessVerticalsQuery({
    sortField: "name",
    sortMode: "asc",
  });

  const selected = React.useMemo(
    () => verticals?.find((it) => it.id == value),
    [verticals, value]
  );

  return (
    <Autocomplete
      fullWidth
      options={verticals || []}
      value={selected || null}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField {...params} label="Industry" placeholder="Search..." />
      )}
      onChange={(event: any, newValue: BusinessVertical | null) => {
        onChange(newValue?.id);
      }}
      loading={isLoading}
    />
  );
};
export default SelectVertical;
