import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { UserWorkStatus } from "../../types/users";

const workStatuses = [
  {
    label: "Sole Proprietor",
    value: UserWorkStatus.SOLE_PROPRIETOR,
  },
  {
    label: "Part Time",
    value: UserWorkStatus.PART_TIME,
  },
  {
    label: "Entrepreneur",
    value: UserWorkStatus.ENTREPRENEUR,
  },
];

const SelectWorkStatus = ({
  onChange,
  value,
  error,
  helperText,
}: {
  onChange: (value?: string) => void;
  value: string;
  error?: boolean;
  helperText?: React.ReactNode;
}) => {
  const selected = React.useMemo(
    () => workStatuses?.find((it) => it.value == value),
    [workStatuses, value]
  );

  return (
    <Autocomplete
      fullWidth
      options={workStatuses || []}
      value={selected || null}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Work Status"
          placeholder="Select Work Status..."
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(
        event: any,
        newValue: { label: string; value: string } | null
      ) => {
        onChange(newValue?.value);
      }}
    />
  );
};
export default SelectWorkStatus;
