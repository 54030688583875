import * as React from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import { AppVersion } from "../../types/appVersion";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetAppVersionByIdQuery,
  useAddAppVersionMutation,
  useEditAppVersionMutation,
} from "../../services/app-version.service";
import SelectSimple from "../../components/SelectSimple";
import { Alert } from "@mui/material";

const platformOptions = [
  { value: "ios", label: "ios" },
  { value: "android", label: "android" },
];

interface ModalAppUpdateFormProps {
  open: boolean;
  onClose: () => void;
  data: AppVersion | null;
}

const ModalAppUpdateForm = ({
  open,
  onClose,
  data,
}: ModalAppUpdateFormProps) => {
  const { data: appVersion } = useGetAppVersionByIdQuery(data?.id!, {
    skip: !data?.id,
  });

  const [add, { isLoading: adding }] = useAddAppVersionMutation();
  const [edit, { isLoading: editing }] = useEditAppVersionMutation();

  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!data) await add(payload).unwrap();
      else {
        payload.id = data?.id!;
        await edit(payload).unwrap();
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("app_id", data?.app_id || "");
      setValue("platform", data?.platform || platformOptions[0].value);
      setValue("version", data?.version || 1);
      setValue("version_name", data?.version_name || "");
      setValue("mandatory", data?.mandatory || false);
      setValue("release_notes", data?.release_notes || "");
      setValue("enabled", data?.enabled || false);

      clearErrors();
    }
  }, [data, open]);

  React.useEffect(() => {
    setValue("app_id", appVersion?.app_id || "");
    setValue("platform", appVersion?.platform || platformOptions[0].value);
    setValue("version", appVersion?.version || 1);
    setValue("version_name", appVersion?.version_name || "");
    setValue("mandatory", appVersion?.mandatory || false);
    setValue("release_notes", appVersion?.release_notes || "");
    setValue("enabled", appVersion?.enabled || false);
  }, [appVersion]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} App Version
        </DialogTitle>
        <DialogContent>
          <Box>
            <Controller
              name="app_id"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="App ID"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.app_id}
                  helperText={!!errors.app_id && "This field is required"}
                />
              )}
            />
            <Controller
              name="platform"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SelectSimple
                  title="Platform"
                  data={platformOptions}
                  value={value}
                  sx={{ mt: 2, mb: 1 }}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="version"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Version"
                  value={value}
                  fullWidth
                  type="number"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.version}
                  helperText={!!errors.version && "This field is required"}
                />
              )}
            />
            <Controller
              name="version_name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Version Name"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.version_name}
                  helperText={!!errors.version_name && "This field is required"}
                />
              )}
            />
            <Controller
              name="mandatory"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Mandatory"
                />
              )}
            />
            <Controller
              name="release_notes"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Release Notes (optional)"
                  value={value}
                  rows={5}
                  multiline
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Alert severity="info">
              Only enable if above version is already LIVE on Google/Apple app
              market
            </Alert>
            <Controller
              name="enabled"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Enabled"
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={adding || editing}
          >
            {!data ? "Create" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalAppUpdateForm;
