import React from "react";

interface CornerRibbonProps {
  position?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  children: React.ReactNode;
  style?: React.CSSProperties;
  fontColor?: string;
  backgroundColor?: string;
  containerStyle?: React.CSSProperties;
  className?: string;
}

const CornerRibbon = ({
  position = "top-right",
  children,
  style,
  fontColor = "#f0f0f0",
  backgroundColor = "#2c7",
  containerStyle: userContainerStyle,
  className,
  ...rest
}: CornerRibbonProps) => {
  const positionStyle = React.useMemo(() => {
    let _style = {};
    switch (position) {
      case "top-left":
        _style = {
          top: 0,
          left: 0,
          transform:
            "translateY(-100%) rotate(-90deg) translateX(-70.71067811865476%) rotate(45deg)",
          transformOrigin: "bottom left",
          WebkitTransform:
            "translateY(-100%) rotate(-90deg) translateX(-70.71067811865476%) rotate(45deg)",
        };
        break;
      case "top-right":
        _style = {
          top: 0,
          right: 0,
          transform:
            "translateY(-100%) rotate(90deg) translateX(70.71067811865476%) rotate(-45deg)",
          transformOrigin: "bottom right",
          WebkitTransform:
            "translateY(-100%) rotate(90deg) translateX(70.71067811865476%) rotate(-45deg)",
        };
        break;
      case "bottom-left":
        _style = {
          bottom: 0,
          left: 0,
          transform:
            "translateY(100%) rotate(90deg) translateX(-70.71067811865476%) rotate(-45deg)",
          transformOrigin: "top left",
          WebkitTransform:
            "translateY(100%) rotate(90deg) translateX(-70.71067811865476%) rotate(-45deg)",
        };
        break;
      case "bottom-right":
        _style = {
          right: 0,
          bottom: 0,
          transform:
            "translateY(100%) rotate(-90deg) translateX(70.71067811865476%) rotate(45deg)",
          transformOrigin: "top right",
          WebkitTransform:
            "translateY(100%) rotate(-90deg) translateX(70.71067811865476%) rotate(45deg)",
        };
        break;
      default:
        break;
    }
    return _style;
  }, [position]);

  const computedStyle: React.CSSProperties = React.useMemo(
    () => ({
      position: "absolute",
      padding: "0.1em 2em",
      zIndex: 99,
      textAlign: "center",
      letterSpacing: "2px",
      fontSize: "12px",
      pointerEvents: "auto",
      boxShadow: "0 0 3px rgba(0,0,0,.3)",
      ...(backgroundColor && { backgroundColor }),
      ...(fontColor && { color: fontColor }),
      ...positionStyle,
      ...style,
    }),
    [backgroundColor, fontColor, style]
  );

  const containerStyle: React.CSSProperties = React.useMemo(
    () => ({
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      overflow: "hidden",
      backgroundColor: "transparent",
      pointerEvents: "none",
      ...userContainerStyle,
    }),
    [userContainerStyle]
  );

  return (
    <div style={containerStyle} className={className} {...rest}>
      <div style={computedStyle}>{children}</div>
    </div>
  );
};
export default CornerRibbon;
