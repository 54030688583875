// import { GMAP_API_KEY } from "../configs/api";

type Point = {
  lat: number;
  lng: number;
};

export function getRadius(lat: number, lng: number, latRadius: number) {
  return getDistanceBetweenPoints(
    { lat: lat - latRadius / 2, lng },
    { lat: lat + latRadius / 2, lng }
  );
}

export function getDistanceBetweenPoints(point1: Point, point2: Point): number {
  const earthRadius = 6371;
  const [dLat, dLng] = [
    degToRadians(point2.lat - point1.lat),
    degToRadians(point2.lng - point1.lng),
  ];
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRadians(point1.lat)) *
      Math.cos(degToRadians(point2.lat)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // return Math.abs(earthRadius * c * 350); m
  return (Math.abs(earthRadius * c * 350) / 1000) * 0.62137; //miles
}

function degToRadians(deg: number): number {
  return deg * (Math.PI / 180);
}

export function milesToMeter(miles: number) {
  return Math.round(miles * 1609.344);
}
