import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { BusinessVertical } from "../../types/vertical";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetSkillsByVerticalQuery,
  useGetSkillsQuery,
} from "../../services/skill.service";
import { Skill, SkillCategory } from "../../types/skill";
import {
  useAddBusinessVerticalMutation,
  useEditBusinessVerticalMutation,
} from "../../services/vertical.service";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface ModalBusinessVerticalFormProps {
  open: boolean;
  onClose: () => void;
  data: BusinessVertical | null;
}

const ModalBusinessVerticalForm = ({
  open,
  onClose,
  data,
}: ModalBusinessVerticalFormProps) => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [checked, setChecked] = React.useState<Map<number, boolean>>(new Map());
  const { data: allSkills, isLoading } = useGetSkillsQuery(
    {
      page: 0,
      pageSize: 1000,
    },
    {
      skip: !open,
    }
  );
  const {
    data: initials,
    isLoading: loadingInitials,
    isFetching: fetchingInitials,
  } = useGetSkillsByVerticalQuery(
    { verticalId: data?.id! },
    { skip: !data, refetchOnMountOrArgChange: true }
  );
  const [edit, { isLoading: updating }] = useEditBusinessVerticalMutation();
  const [add, { isLoading: adding }] = useAddBusinessVerticalMutation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const categoryWithSkills = React.useMemo(() => {
    const tmp = new Map<number, Array<Skill & { category: SkillCategory }>>();
    if (!allSkills) return tmp;

    allSkills.data.map((it) => {
      const category = tmp.get(it.category_id);
      if (!!category) {
        category.push(it);
      } else {
        tmp.set(it.category_id, [it]);
      }
    });
    return tmp;
  }, [allSkills]);

  React.useEffect(() => {
    const init = new Map();
    initials?.data.forEach((it) => init.set(it.id, true));
    setChecked(init);
  }, [initials]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(event.target.name);
    setChecked(new Map(checked.set(id, event.target.checked)));
  };

  const onSubmit = async (payload: any) => {
    try {
      const selected = Array.from(checked)
        .filter((entry) => {
          const checked = entry[1];
          return checked;
        })
        .map((entry) => entry[0]);
      if (selected.length == 0) {
        toast.error("Please select at least one skill!");
        return;
      }

      payload.skills = selected;
      if (!data) {
        await add(payload).unwrap();
      } else {
        payload.id = data?.id!;
        await edit(payload).unwrap();
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("name", data?.name);
      setValue("description", data?.description);
      if (!data) {
        setChecked(new Map());
      }
    }
  }, [data, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        {!data ? `Create` : `Edit`} Industry
      </DialogTitle>
      <Box sx={{ pl: 2, pr: 2 }}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)} id="modal-form">
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Industry name"
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.name}
                helperText={!!errors.name && "This field is required"}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Description (optional)"
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                multiline
              />
            )}
          />
        </form>
      </Box>
      <DialogContent sx={{ overflow: "auto" }}>
        {(isLoading || loadingInitials || fetchingInitials) && (
          <Box>
            <CircularProgress size={24} />
          </Box>
        )}
        <Typography fontWeight={500}>Select Skills</Typography>
        <Stack direction={"column"}>
          {Array.from(categoryWithSkills).map((entry) => {
            let id = entry[0];
            let skills = entry[1];

            const categoryName = skills![0].category?.name || "?";
            const getCheckboxes = () =>
              skills.map((skill) => (
                <FormControlLabel
                  sx={{ height: 32 }}
                  control={
                    <Checkbox
                      checked={checked.get(skill.id) || false}
                      onChange={handleChange}
                      name={skill.id.toString()}
                    />
                  }
                  label={skill.name}
                />
              ));
            return (
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">{categoryName}</FormLabel>
                <FormGroup>{getCheckboxes()}</FormGroup>
              </FormControl>
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={updating || adding}
          // onClick={() => formRef?.current?.submit()}
          type="submit"
          form="modal-form"
        >
          {!data ? "Create" : "Update"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default ModalBusinessVerticalForm;
