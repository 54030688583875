import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
} from "@mui/x-data-grid";
import {
  useDeleteSkillCategoryMutation,
  useGetSkillCategoriesQuery,
  useRestoreSkillCategoryMutation,
} from "../../services/skill-category.service";
import LinearProgress from "@mui/material/LinearProgress";
import { Stack, Paper, Button, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { toast } from "react-toastify";
import ModalSkillCategoryForm from "./ModalSkillCategoryForm";
import AddIcon from "@mui/icons-material/Add";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { SkillCategory } from "../../types/skill";
import { useNavigate } from "react-router-dom";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button
          variant="text"
          sx={{ mt: 1 }}
          onClick={() => setQuery("")}
        >
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <Button variant="outlined" onClick={onCreate}>
          <AddIcon /> Add Category
        </Button>
      </Stack>
    </GridToolbarContainer>
  );
};

const SkillCategoriesPage = () => {
  useTitle("Skill Categories");
  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    row: GridRowModel<SkillCategory> | null;
  }>({
    open: false,
    row: null,
  });
  const [formModal, setFormModal] = React.useState<{
    open: boolean;
    data: SkillCategory | null;
  }>({
    open: false,
    data: null,
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const { data, isLoading, isFetching } = useGetSkillCategoriesQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const [doDelete, { isLoading: deleting }] = useDeleteSkillCategoryMutation();
  const [doRestore, { isLoading: restoring }] =
    useRestoreSkillCategoryMutation();

  const handleDeleteRow = (row: GridRowModel<SkillCategory>) => {
    setDeleteModal({ open: true, row });
  };
  const handleEditRow = (row: GridRowModel<SkillCategory>) => {
    setFormModal({ open: true, data: row });
  };
  const handleRestoreRow = async (row: GridRowModel<SkillCategory>) => {
    try {
      await doRestore(row.id).unwrap();
      toast.success("Data restored");
    } catch (err) {}
  };

  const rows: GridRowsProp | undefined = React.useMemo(
    () =>
      data?.data.map((it) => ({
        id: it.id,
        name: it.name,
        deleted_at: it.deleted_at,
      })),
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "name",
        headerName: "Category Name",
        flex: 1,
        sortingOrder: ["desc", "asc", null],
        filterable: true,
      },
      {
        field: "control",
        headerName: "",
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<undefined, SkillCategory>) =>
          !!params.row.deleted_at ? (
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleRestoreRow(params.row)}
            >
              Restore
            </Button>
          ) : (
            <Stack direction={"row"}>
              <IconButton onClick={() => handleEditRow(params.row)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={() => handleDeleteRow(params.row)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Stack>
          ),
      },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    setFormModal({ open: true, data: null });
  }, []);

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    if (deleteModal.row?.id) {
      try {
        await doDelete(deleteModal.row.id).unwrap();
        closeDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeDeleteModal();
      }
    }
  };

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  return (
    <Box>
      <Stack direction={"row-reverse"}>
        <Button variant="text" onClick={() => navigate("/skills")}>
          Manage Skills →
        </Button>
      </Stack>
      <Paper style={{ height: "80vh" }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[25, 50, 100]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnFilter
          // rowHeight={42}
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
        />
        <ConfirmDeleteModal
          open={deleteModal.open}
          onClose={closeDeleteModal}
          onDelete={handleDelete}
          message={`Delete '${deleteModal.row?.name}' ?`}
        />
        <ModalSkillCategoryForm
          open={formModal.open}
          onClose={() => setFormModal({ open: false, data: null })}
          data={formModal.data}
        />
      </Paper>
    </Box>
  );
};
export default SkillCategoriesPage;
