import { Withdrawal } from "../types/withdrawal";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "Withdrawals";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const withdrawalApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getWithdrawals: builder.query<
      QueryResponse<Withdrawal>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
      }
    >({
      query: ({ page, pageSize, sortField, sortMode, search }) => ({
        url: "api/admin/withdrawals",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
          load: "user,bankAccount",
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getWithdrawalById: builder.query<Withdrawal, number>({
      query: (id) => ({
        url: `api/admin/withdrawals/${id}`,
        method: "GET",
        params: {
          load: "user,bankAccount",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    inprogressWithdrawal: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/withdrawals/${id}/in-progress`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    completedWithdrawal: builder.mutation<
      Withdrawal,
      Partial<Withdrawal> & Pick<Withdrawal, "id">
    >({
      query: (body) => {
        let formData = new FormData();
        formData.append("reason", body?.notes || "");

        return {
          url: `api/admin/withdrawals/${body.id}/completed`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    rejectedWithdrawal: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/withdrawals/${id}/rejected`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetWithdrawalsQuery,
  useGetWithdrawalByIdQuery,
  useInprogressWithdrawalMutation,
  useCompletedWithdrawalMutation,
  useRejectedWithdrawalMutation,
} = withdrawalApi;
