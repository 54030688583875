import * as React from "react";
import { Alert, Box } from "@mui/material";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { GMAP_API_KEY } from "../configs/api";

const containerStyle = {
  width: "100%",
  height: "30vh",
};

const options = {
  fullscreenControl: false, // remove the top-right button
  mapTypeControl: false, // remove the top-left buttons
  streetViewControl: false, // remove the pegman
  zoomControl: true, // remove the bottom-right buttons
  scrollwheel: false,
};

const MapPreview = ({
  lat = 0,
  lng = 0,
  zoom = 14,
}: {
  lat?: number;
  lng?: number;
  zoom?: number;
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GMAP_API_KEY,
    // ...otherOptions
  });

  if (loadError) {
    return (
      <Alert severity="error">Map cannot be loaded right now, sorry.</Alert>
    );
  }

  if (!isLoaded) return <p>Loading...</p>;

  return (
    <Box>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat, lng }}
        zoom={zoom}
        options={options}
      >
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    </Box>
  );
};
export default React.memo(MapPreview);
