import React from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import SelectSimple from "../../components/SelectSimple";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useGetProjectSummaryQuery } from "../../services/report.service";
import { formatMoney } from "../../utils/helper";

const periodOptions = [
  { value: "this-week", label: "This Week" },
  { value: "this-month", label: "This Month" },
  { value: "this-year", label: "This Year" },
  { value: "other", label: "Custom Dates" },
];

const ProjectSummaryCard = () => {
  const [period, setPeriod] = React.useState("this-year");
  const [periodDate, setPeriodDate] = React.useState({
    startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
  });

  const { data, isLoading, isFetching } = useGetProjectSummaryQuery({
    startDate: periodDate.startDate,
    endDate: periodDate.endDate,
  });

  const totalPay = React.useMemo(
    () =>
      data?.data
        .map((item) => Number(item?.total_pay))
        .reduce((prev?, curr?) => prev + curr, 0),
    [data]
  );
  const totalFee = React.useMemo(
    () =>
      data?.data
        .map((item) => Number(item?.total_fee))
        .reduce((prev?, curr?) => prev + curr, 0),
    [data]
  );
  const totalCompleted = React.useMemo(
    () =>
      data?.data
        .map((item) => Number(item?.number_of_completed))
        .reduce((prev?, curr?) => prev + curr, 0),
    [data]
  );

  const {
    handleSubmit,
    control,
    clearErrors,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!dayjs(payload.start_date).isSame(dayjs(payload.end_date))) {
        if (dayjs(payload.start_date).isAfter(dayjs(payload.end_date))) {
          setError("end_date", { type: "custom", message: "Invalid" });
          return;
        }
      }
      setPeriodDate({
        startDate: dayjs(payload.start_date).format("YYYY-MM-DD"),
        endDate: dayjs(payload.end_date).format("YYYY-MM-DD"),
      });
    } catch (err: any) {
      toast.error(err);
    }
  };

  React.useEffect(() => {
    setValue("start_date", dayjs().add(-1, "day"));
    setValue("end_date", dayjs());
    clearErrors();
  }, [period]);

  return (
    <Card style={{ height: "100%" }}>
      <CardContent style={{ height: "100%" }}>
        <Stack direction={"row"} sx={{ mb: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ fontSize: 20 }}>Summary</Typography>
          </Box>
          <Box sx={{ minWidth: 120, mt: 0 }}>
            <form key="talent-company-stat" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" spacing={1}>
                <SelectSimple
                  id="talent-company-period"
                  title="Period"
                  data={periodOptions}
                  value={period}
                  sx={{ mt: 0 }}
                  onChange={(e) => {
                    const value = e.target.value as string;
                    if (value === "this-week") {
                      setPeriodDate({
                        startDate: dayjs().startOf("week").format("YYYY-MM-DD"),
                        endDate: dayjs().endOf("week").format("YYYY-MM-DD"),
                      });
                    } else if (value === "this-month") {
                      setPeriodDate({
                        startDate: dayjs()
                          .startOf("month")
                          .format("YYYY-MM-DD"),
                        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
                      });
                    } else if (value === "this-year") {
                      setPeriodDate({
                        startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
                        endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
                      });
                    }
                    setPeriod(value);
                  }}
                />
              </Stack>
              {period === "other" && (
                <Stack>
                  <Controller
                    name="start_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DesktopDatePicker
                        label="Start Date"
                        inputFormat="MM/DD/YYYY"
                        value={value}
                        showToolbar={true}
                        onChange={onChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ mt: 1 }}
                            error={!!errors.start_date}
                            helperText={
                              !!errors.start_date && "This field is required"
                            }
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="end_date"
                    control={control}
                    rules={{
                      required: true,
                      // validate: (val: string) => {
                      //   return dayjs(watch("start_date")).isBefore(val);
                      // },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <DesktopDatePicker
                        label="End Date"
                        inputFormat="MM/DD/YYYY"
                        value={value}
                        showToolbar={true}
                        onChange={onChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ mt: 1 }}
                            error={!!errors.end_date}
                            helperText={
                              !!errors.end_date &&
                              // "This field is required"
                              "The end date must be a date after or equal to start date"
                            }
                          />
                        )}
                      />
                    )}
                  />
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{ mt: 1, height: "100%" }}
                  >
                    Update
                  </LoadingButton>
                </Stack>
              )}
            </form>
          </Box>
        </Stack>
        <Box sx={{ mt: 4 }}>
          {isLoading || isFetching ? (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#eee",
                    padding: 2,
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: "green",
                      fontStyle: "bold",
                    }}
                  >
                    ${formatMoney(totalPay || 0)}
                  </Typography>
                  <Typography variant="caption">Total Pay</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#eee",
                    padding: 2,
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: "green",
                      fontStyle: "bold",
                    }}
                  >
                    ${formatMoney(totalFee || 0)}
                  </Typography>
                  <Typography variant="caption">Total Fee</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#eee",
                    padding: 2,
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: "green",
                      fontStyle: "bold",
                    }}
                  >
                    {totalCompleted || 0}
                  </Typography>
                  <Typography variant="caption">Number of Ticket</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#eee",
                    padding: 2,
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: "green",
                      fontStyle: "bold",
                    }}
                  >
                    ${formatMoney((totalPay || 0) / (totalCompleted || 0))}
                  </Typography>
                  <Typography variant="caption">
                    Average Pay Per Ticket
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
export default ProjectSummaryCard;
