import * as React from "react";
import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useTitle } from "../../hooks/useTitle";
import BarChartSurveyAnswer from "./BarChartSurveyAnswer";
import DonutChartTalent from "./DonutChartTalent";
import DonutChartCustomer from "./DonutChartCustomer";
import DonutChartJob from "./DonutChartJob";
import DonutChartUser from "./DonutChartUser";
import FinanceActivitiesPanel from "./FinanceActivitiesPanel";
import ProjectNewsPanel from "./ProjectNewsPanel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import DailyNewUserCard from "./DailyNewUserCard";
import ModalStatisticSkillByTalent from "./ModalStatisticSkillByTalent";
import ModalStatisticSkillByProject from "./ModalStatisticSkillByProject";
import ModalStatisticVerticalByCompany from "./ModalStatisticVerticalByCompany";
import ProjectSummaryCard from "./ProjectSummaryCard";
import { isManage360 } from "../../utils/helper";

type CustomCardProps = {
  title: string;
  page: string;
};

const CustomCard: React.FC<CustomCardProps> = ({ title, page, children }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = () => {
    navigate(page);
    handleClose();
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Box sx={{ display: "flex", mb: 3 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ fontSize: 20 }}>{title}</Typography>
          </Box>
          <Box>
            <IconButton aria-label="more" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem key={"detail"} onClick={handleOpen}>
                <Typography textAlign="center">More Detail</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        {children}
      </CardContent>
    </Card>
  );
};

const DashboardPage = () => {
  useTitle("Admin Dashboard");
  const user = useCurrentUser();
  const [showModalTalentSkill, setShowModalTalentSkill] = React.useState(false);
  const [showModalProjectSkill, setShowModalProjectSkill] =
    React.useState(false);
  const [showModalCompanyIndustry, setShowModalCompanyIndustry] =
    React.useState(false);
  const _isManage360 = React.useMemo(() => isManage360(), []);

  return (
    <Box>
      <Typography variant="h6">Welcome Back, {user?.first_name} 👋</Typography>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item xs={8}>
          <DailyNewUserCard />
        </Grid>
        {!_isManage360 && (
          <Grid item xs={4}>
            <ProjectSummaryCard />
          </Grid>
        )}
        {/* <Grid item xs={3}>
          <EarningCard />
        </Grid> */}
        <Grid item xs={12}>
          <Stack direction={"row"} alignItems="center">
            <Typography variant="button">More Stats ⇒</Typography>
            <Button
              sx={{ ml: 1, mr: 1 }}
              onClick={() => setShowModalTalentSkill(true)}
            >
              Skill by Talent
            </Button>
            <Button
              sx={{ mr: 1 }}
              onClick={() => setShowModalProjectSkill(true)}
            >
              Skill by Project
            </Button>
            <Button onClick={() => setShowModalCompanyIndustry(true)}>
              Industry by Company
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <CustomCard title="Jobs" page="/jobs">
            <DonutChartJob id="chart-job" />
          </CustomCard>
        </Grid>
        <Grid item xs={8}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              {/* <p>TODO: chart for daily new talents n customers</p> */}
              <Box sx={{ display: "flex", mb: 3 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontSize: 20 }}>
                    How do people know Workz360?
                  </Typography>
                </Box>
              </Box>
              <BarChartSurveyAnswer id="chart-survey-answer" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <CustomCard title="Talents" page="/talents">
            <DonutChartTalent id="chart-talent" />
          </CustomCard>
        </Grid>
        <Grid item xs={4}>
          <CustomCard title="Clients" page="/clients">
            <DonutChartCustomer id="chart-customer" />
          </CustomCard>
        </Grid>
        <Grid item xs={4}>
          <CustomCard title="Admin Users" page="/admins">
            <DonutChartUser id="chart-user" />
          </CustomCard>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Newest Projects" />
            <CardContent>
              <ProjectNewsPanel />
            </CardContent>
          </Card>
        </Grid>
        {!_isManage360 && (
          <Grid item xs={6}>
            <Card>
              <CardHeader title="Finance Activities" />
              <CardContent>
                <FinanceActivitiesPanel />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <ModalStatisticSkillByTalent
        open={showModalTalentSkill}
        onClose={() => setShowModalTalentSkill(!showModalTalentSkill)}
      />
      <ModalStatisticSkillByProject
        open={showModalProjectSkill}
        onClose={() => setShowModalProjectSkill(!showModalProjectSkill)}
      />
      <ModalStatisticVerticalByCompany
        open={showModalCompanyIndustry}
        onClose={() => setShowModalCompanyIndustry(!showModalCompanyIndustry)}
      />
    </Box>
  );
};
export default DashboardPage;
