import * as React from "react";
import { GridRowsProp } from "@mui/x-data-grid";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetBalanceHistoriesQuery } from "../../services/finance.service";
import dayjs from "dayjs";
import { formatMoney } from "../../utils/helper";
import { BalanceHistory } from "../../types/finance";

const FinanceActivitiesPanel = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { data, isLoading, refetch, isFetching } = useGetBalanceHistoriesQuery({
    page: 0,
    pageSize: 5,
    sortField: "created_at",
    sortMode: "desc",
  });

  const rows: BalanceHistory[] = React.useMemo(() => data?.data || [], [data]);

  if (isLoading)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );

  if (rows && rows?.length == 0)
    return (
      <Box>
        <Typography>No activity, yet!</Typography>
      </Box>
    );

  return (
    <Box>
      {isFetching && <LinearProgress />}
      <List>
        {rows?.map((row, index) => (
          <>
            <ListItem key={index}>
              <ListItemText
                primary={
                  row?.model
                    ? row?.model_type === "company"
                      ? `${row?.model?.name || ""}`
                      : `${row?.model?.first_name || ""} ${
                          row?.model?.last_name || ""
                        } `
                    : `${row?.model_type}#${row?.model_id}`
                }
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {row?.notes}
                  </Typography>
                }
              />
              <Stack sx={{ alignItems: "flex-end" }}>
                {/* <Chip label={row?.status} size="small" /> */}
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color={
                    row?.credit > 0 ? "#FF0000" : theme.palette.primary.main
                  }
                >
                  {`${
                    row?.credit > 0
                      ? `- ${formatMoney(row?.credit || 0)}`
                      : `${formatMoney(row?.debit || 0)}`
                  }`}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {dayjs(row?.created_at).format("MMM DD, YYYY hh:mm A")}
                </Typography>
              </Stack>
            </ListItem>
            {index < rows.length - 1 && <Divider component="li" />}
          </>
        ))}
      </List>
      <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
        <Button variant="outlined" onClick={() => navigate(`/finances`)}>
          More
        </Button>
        <Button onClick={refetch} sx={{ ml: 2 }}>
          Refresh
        </Button>
      </Box>
    </Box>
  );
};
export default FinanceActivitiesPanel;
