import {
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Rating,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetTalentByIdQuery } from "../../services/talent.service";
import { useTitle } from "../../hooks/useTitle";
import { useGetTalentReviewsQuery } from "../../services/review.service";
import dayjs from "dayjs";

const TalentReviewsPage = () => {
  const { talentId } = useParams();

  const { data: talent } = useGetTalentByIdQuery(parseInt(talentId!), {
    skip: !talentId,
  });

  const { data, isLoading, isFetching } = useGetTalentReviewsQuery(
    parseInt(talentId!),
    {
      skip: !talentId,
    }
  );

  useTitle(
    `Talent #${talent?.id}`,
    `${talent?.first_name} ${talent?.last_name} | Talent`
  );

  return (
    <Box>
      <Typography variant="h5">Reviews For {talent?.full_name}</Typography>
      {(isLoading || isFetching) && <LinearProgress sx={{ mt: 1 }} />}
      {data?.length == 0 && (
        <Box>
          <Typography color={"text.disabled"}>No Review, Yet</Typography>
        </Box>
      )}
      {data?.map((review) => (
        <Card sx={{ mt: 2 }}>
          <CardHeader
            title={`#${review.project?.id} ${review?.project?.title}`}
            subheader={review.project?.company?.name}
          />
          <CardContent>
            <Rating value={review.star} readOnly={true} />
            <Box>
              <Typography sx={{ fontStyle: "italic" }}>
                {review.review}
              </Typography>
              <Typography variant="caption" color={"text.disabled"}>
                {dayjs(review.created_at).format("MMM DD, YYYY hh:mm A")}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};
export default TalentReviewsPage;
