import React from "react";
import {
  Box,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import SelectSimple from "../../components/SelectSimple";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import BarChartNewTalentCompany from "./BarChartNewTalentCompany";
import { toast } from "react-toastify";

const periodOptions = [
  { value: "this-week", label: "This Week" },
  { value: "this-month", label: "This Month" },
  { value: "this-year", label: "This Year" },
  { value: "other", label: "Custom Dates" },
];

const DailyNewUserCard = () => {
  const [talentCompanyPeriod, setTalentCompanyPeriod] =
    React.useState("this-year");

  const [talentCompanyPeriodDate, setTalentCompanyPeriodDate] = React.useState({
    startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
  });

  const {
    handleSubmit,
    control,
    clearErrors,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!dayjs(payload.start_date).isSame(dayjs(payload.end_date))) {
        if (dayjs(payload.start_date).isAfter(dayjs(payload.end_date))) {
          setError("end_date", { type: "custom", message: "Invalid" });
          return;
        }
      }
      setTalentCompanyPeriodDate({
        startDate: dayjs(payload.start_date).format("YYYY-MM-DD"),
        endDate: dayjs(payload.end_date).format("YYYY-MM-DD"),
      });
    } catch (err: any) {
      toast.error(err);
    }
  };

  React.useEffect(() => {
    setValue("start_date", dayjs().add(-1, "day"));
    setValue("end_date", dayjs());
    clearErrors();
  }, [dayjs, talentCompanyPeriod]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Box sx={{ display: "flex", mb: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ fontSize: 20 }}>
              New Talents & Clients
            </Typography>
          </Box>
          <Box sx={{ minWidth: 120, mt: 0 }}>
            <form key="talent-company-stat" onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="row" spacing={1}>
                <SelectSimple
                  id="talent-company-period"
                  title="Period"
                  data={periodOptions}
                  value={talentCompanyPeriod}
                  sx={{ mt: 0 }}
                  onChange={(e) => {
                    const value = e.target.value as string;
                    if (value === "this-week") {
                      setTalentCompanyPeriodDate({
                        startDate: dayjs().startOf("week").format("YYYY-MM-DD"),
                        endDate: dayjs().endOf("week").format("YYYY-MM-DD"),
                      });
                    } else if (value === "this-month") {
                      setTalentCompanyPeriodDate({
                        startDate: dayjs()
                          .startOf("month")
                          .format("YYYY-MM-DD"),
                        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
                      });
                    } else if (value === "this-year") {
                      setTalentCompanyPeriodDate({
                        startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
                        endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
                      });
                    }
                    setTalentCompanyPeriod(value);
                  }}
                />
                {talentCompanyPeriod === "other" && (
                  <>
                    <Controller
                      name="start_date"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DesktopDatePicker
                          label="Start Date"
                          inputFormat="MM/DD/YYYY"
                          value={value}
                          showToolbar={true}
                          onChange={onChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ mt: 1, width: "75%" }}
                              error={!!errors.start_date}
                              helperText={
                                !!errors.start_date && "This field is required"
                              }
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name="end_date"
                      control={control}
                      rules={{
                        required: true,
                        // validate: (val: string) => {
                        //   return dayjs(watch("start_date")).isBefore(val);
                        // },
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DesktopDatePicker
                          label="End Date"
                          inputFormat="MM/DD/YYYY"
                          value={value}
                          showToolbar={true}
                          onChange={onChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ mt: 1, width: "75%" }}
                              error={!!errors.end_date}
                              helperText={
                                !!errors.end_date &&
                                // "This field is required"
                                "The end date must be a date after or equal to start date"
                              }
                            />
                          )}
                        />
                      )}
                    />
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      sx={{ mt: 1, height: "100%" }}
                    >
                      Update
                    </LoadingButton>
                  </>
                )}
              </Stack>
            </form>
          </Box>
        </Box>
        <BarChartNewTalentCompany
          id="chart-talent-company"
          params={talentCompanyPeriodDate}
        />
      </CardContent>
    </Card>
  );
};
export default DailyNewUserCard;
