import * as React from "react";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Talent, UserWorkStatus } from "../../types/users";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddTalentMutation,
  useDeleteUserCertificationMutation,
  useDeleteUserEducationMutation,
  useEditTalentMutation,
  useGetTalentByIdQuery,
} from "../../services/talent.service";
import { useTitle } from "../../hooks/useTitle";
import { Controller, useForm } from "react-hook-form";
import useScroll from "../../hooks/useScroll";
import SelectSkills from "./SelectSkills";
import SelectLanguages from "./SelectLanguages";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import SelectCountry from "../../components/SelectCountry";
import UploadPhoto from "./UploadPhoto";
import AddIcon from "@mui/icons-material/Add";
import ReactStickyBox from "react-sticky-box";
import {
  getOriginalLastName,
  getPrivateCompanyName,
  isManage360,
} from "../../utils/helper";
import { useGetCompaniesQuery } from "../../services/company.service";
import { GridSortModel } from "@mui/x-data-grid";
import { Company } from "../../types/company";
import SelectWorkStatus from "./SelectWorkStatus";
import SelectAvailability from "./SelectAvailability";
import SelectSimple from "../../components/SelectSimple";
import SubtitlesOutlined from "@mui/icons-material/SubtitlesOutlined";
import SchoolOutlined from "@mui/icons-material/SchoolOutlined";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { UserCertification } from "../../types/certification";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import ModalUserCertificationForm from "./ModalUserCertificationForm";
import ModalUserEducationForm from "./ModalUserEducationForm";
import { UserEducation } from "../../types/education";
import { EditOutlined } from "@mui/icons-material";
import SelectVerticals from "../../components/SelectVerticals";

const TalentEditPage = () => {
  const { talentId } = useParams();
  const navigate = useNavigate();
  const [scrollToLogin, loginRef] = useScroll();
  const [scrollToBasic, basicRef] = useScroll();
  const [scrollToSetting, settingRef] = useScroll();
  const [scrollToSkill, skillRef] = useScroll();
  const [scrollToLanguage, languageRef] = useScroll();
  const [scrollToWorkStatus, workStatusRef] = useScroll();
  const [scrollToAvail, availRef] = useScroll();
  const [scrollToCert, certRef] = useScroll();
  const [scrollToEdu, eduRef] = useScroll();

  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const [options, setOptions] = React.useState<string[]>([]);
  const [inputValue, setInputValue] = React.useState<string>("");
  const _isManage360 = React.useMemo(() => isManage360(), []);
  const { data: companyData, isFetching: companyLoading } =
    useGetCompaniesQuery({
      page: 0,
      pageSize: 1000,
      sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
      sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
      search: searchQuery,
    });

  const [doDeleteCert] = useDeleteUserCertificationMutation();
  const [doDeleteEdu] = useDeleteUserEducationMutation();

  const [certModal, setCertModal] = React.useState<{
    open: boolean;
    data: UserCertification | null;
  }>({
    open: false,
    data: null,
  });
  const [deleteCertModal, setConfirmDeleteCertModal] = React.useState<{
    open: boolean;
    id: number;
  }>({
    open: false,
    id: 0,
  });
  const closeCertDeleteModal = () =>
    setConfirmDeleteCertModal({ ...deleteCertModal, open: false });
  const handleCertDelete = async () => {
    if (deleteCertModal.id > 0) {
      try {
        await doDeleteCert({
          id: deleteCertModal.id,
          user_id: parseInt(talentId!),
        }).unwrap();
        closeCertDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeCertDeleteModal();
      }
    }
  };

  const [eduModal, setEduModal] = React.useState<{
    open: boolean;
    data: UserEducation | null;
  }>({
    open: false,
    data: null,
  });
  const [deleteEduModal, setConfirmDeleteEduModal] = React.useState<{
    open: boolean;
    id: number;
  }>({
    open: false,
    id: 0,
  });
  const closeEduDeleteModal = () =>
    setConfirmDeleteEduModal({ ...deleteEduModal, open: false });
  const handleEduDelete = async () => {
    if (deleteEduModal.id > 0) {
      try {
        await doDeleteEdu({
          id: deleteEduModal.id,
          user_id: parseInt(talentId!),
        }).unwrap();
        closeEduDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeEduDeleteModal();
      }
    }
  };

  React.useEffect(() => {
    const tempOptions = companyData?.data
      .filter((it: Company) => it.status === "active")
      .map((item: Company) => item.name);
    setOptions(tempOptions || []);
  }, [companyData]);
  const { data, isLoading, isFetching, isError, error } = useGetTalentByIdQuery(
    parseInt(talentId!),
    {
      skip: !talentId,
    }
  );
  const [add, { isLoading: adding }] = useAddTalentMutation();
  const [edit, { isLoading: updating }] = useEditTalentMutation();

  useTitle(!!data ? "Edit Talent" : "Create Talent");

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!data) {
        const talent: Talent = await add(payload).unwrap();
        toast.success("Talent created");
        navigate(`/talents/${talent.id}`, { replace: true });
      } else {
        if (!payload.password) delete payload.password;
        if (payload.company !== "") {
          payload.last_name =
            payload.last_name.trim() + ` [${payload.company}]`;
        }
        const selectedCompany = companyData?.data.find((company) => {
          return company?.name === payload.company;
        });
        payload.company = selectedCompany?.id;
        payload.id = data.id;

        payload.avail_monday = payload.workAvails.includes("avail_monday")
          ? 1
          : 0;
        payload.avail_tuesday = payload.workAvails.includes("avail_tuesday")
          ? 1
          : 0;
        payload.avail_wednesday = payload.workAvails.includes("avail_wednesday")
          ? 1
          : 0;
        payload.avail_thursday = payload.workAvails.includes("avail_thursday")
          ? 1
          : 0;
        payload.avail_friday = payload.workAvails.includes("avail_friday")
          ? 1
          : 0;
        payload.avail_saturday = payload.workAvails.includes("avail_saturday")
          ? 1
          : 0;
        payload.avail_sunday = payload.workAvails.includes("avail_sunday")
          ? 1
          : 0;
        delete payload.workAvails;

        await edit(payload).unwrap();
        toast.success("Talent updated");

        navigate(`/talents/${data.id}`, { replace: true });
      }
    } catch (err) {}
  };

  React.useEffect(() => {
    setValue("email", data?.email || "");
    setValue("password", "");
    // setValue("security_questions", data?.security_questions || "");
    // setValue("security_answer", data?.security_answer || "");
    setValue("first_name", data?.first_name || "");
    setValue("last_name", getOriginalLastName(data?.last_name) || "");
    setValue("phone_number", data?.phone_number || "");
    setValue("physical_address_1", data?.physical_address_1 || "");
    setValue("physical_address_2", data?.physical_address_2 || "");
    setValue("country", data?.country_id || 0);
    setValue("city", data?.city || "");
    setValue("state", data?.state || "");
    setValue("zip", data?.zip || "");
    setValue("linkedin", data?.linkedin || "");
    setValue("facebook", data?.facebook || "");
    setValue("instagram", data?.instagram || "");
    setValue("website", data?.website || "");
    setValue("bio", data?.bio || "");
    setValue("min_pay", data?.min_pay || "");
    setValue("max_pay", data?.max_pay || "");
    setValue("commute_radius", data?.commute_radius || "");
    const skillIds = data?.skills?.map((it) => it.id);
    setValue("skills", skillIds || []);
    const verticalIds = data?.business_verticals?.map((it) => it.id);
    setValue("business_verticals", verticalIds || []);
    const languageIds = data?.languages?.map((it) => it.id);
    setValue("languages", languageIds || []);
    setValue("photo", data?.photo[0]?.original_url);
    setValue("company", getPrivateCompanyName(data?.last_name) || "");
    setValue("work_status", data?.talent?.work_status || "");
    setValue("is_valid_us", data?.talent?.is_valid_us || "");

    const avails = [];
    if (!!data?.talent?.avail_monday) avails.push("avail_monday");
    if (!!data?.talent?.avail_monday) avails.push("avail_tuesday");
    if (!!data?.talent?.avail_sunday) avails.push("avail_sunday");
    if (!!data?.talent?.avail_wednesday) avails.push("avail_wednesday");
    if (!!data?.talent?.avail_thursday) avails.push("avail_thursday");
    if (!!data?.talent?.avail_friday) avails.push("avail_friday");
    if (!!data?.talent?.avail_saturday) avails.push("avail_saturday");
    if (!!data?.talent?.avail_sunday) avails.push("avail_sunday");
    setValue("workAvails", avails);
  }, [data]);

  //@ts-ignore
  if (isError && error?.status == 404) {
    return (
      <Box>
        <Alert severity="error">
          <AlertTitle>ERROR</AlertTitle>
          Talent not found or deleted
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      {isFetching && <LinearProgress />}
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <ReactStickyBox offsetTop={70} offsetBottom={20}>
            <List>
              <ListItem>
                <ListItemButton onClick={scrollToLogin as any}>
                  <ListItemText>Login</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToBasic as any}>
                  <ListItemText>Basic Profile</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToSetting as any}>
                  <ListItemText>Independent Professional Settings</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToSkill as any}>
                  <ListItemText>Skills</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToLanguage as any}>
                  <ListItemText>Languages</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToWorkStatus as any}>
                  <ListItemText>Work Status</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToWorkStatus as any}>
                  <ListItemText>Availability</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToCert as any}>
                  <ListItemText>Certificates & Licenses</ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={scrollToEdu as any}>
                  <ListItemText>Educations</ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </ReactStickyBox>
        </Grid>
        <Grid item xs={9}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Card ref={loginRef}>
                <CardHeader title="Login" />
                <CardContent>
                  <Stack direction="row" spacing={1}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Email"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          sx={{ mt: 0 }}
                          error={!!errors.email}
                          helperText={
                            !!errors.email && "This field is required"
                          }
                        />
                      )}
                    />
                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: !!data ? false : true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <>
                          <TextField
                            InputLabelProps={{ shrink: !!value }}
                            label="Password"
                            value={value}
                            fullWidth
                            onChange={onChange}
                            onBlur={onBlur}
                            sx={{ mt: 0 }}
                            error={!!errors.password}
                            helperText={
                              !data
                                ? !!errors.password && "This field is required"
                                : "Leave blank if you don't want to change the password"
                            }
                            type="new-password"
                          />
                        </>
                      )}
                    />
                  </Stack>
                  {/*
                  <Controller
                    name="security_questions"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Security Question (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="security_answer"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Security Answer (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  */}
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={basicRef}>
                <CardHeader title="Basic Profile" />
                <CardContent>
                  <Controller
                    name="photo"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <UploadPhoto onChange={onChange} value={value} />
                    )}
                  />
                  <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
                    <Controller
                      name="first_name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="First name"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          sx={{ mt: 0 }}
                          error={!!errors.first_name}
                          helperText={
                            !!errors.first_name && "This field is required"
                          }
                        />
                      )}
                    />
                    <Controller
                      name="last_name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Last name"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!errors.last_name}
                          helperText={
                            !!errors.last_name && "This field is required"
                          }
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="company"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Autocomplete
                        disablePortal
                        options={options}
                        sx={{ mt: 2 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Companies" />
                        )}
                        onChange={(event: any, newValue: string | null) => {
                          setValue("company", newValue || "");
                        }}
                        onBlur={onBlur}
                        value={value || ""}
                        loading={companyLoading}
                      />
                    )}
                  />
                  <Controller
                    name="phone_number"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Phone number"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.phone_number}
                        helperText={
                          !!errors.phone_number && "This field is required"
                        }
                      />
                    )}
                  />
                  <Controller
                    name="physical_address_1"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Physical Address 1"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors.physical_address_1}
                        helperText={
                          !!errors.physical_address_1 &&
                          "This field is required"
                        }
                      />
                    )}
                  />
                  <Controller
                    name="physical_address_2"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Physical Address 2 (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="country"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectCountry onChange={onChange} value={value} />
                    )}
                  />
                  <Stack direction={"row"} spacing={1} sx={{ mt: 2 }}>
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="City"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          sx={{ mt: 0 }}
                          error={!!errors.city}
                          helperText={!!errors.city && "This field is required"}
                        />
                      )}
                    />
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="State"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!errors.state}
                          helperText={
                            !!errors.state && "This field is required"
                          }
                        />
                      )}
                    />
                    <Controller
                      name="zip"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <TextField
                          InputLabelProps={{ shrink: !!value }}
                          label="Zip"
                          value={value}
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!errors.zip}
                          helperText={!!errors.zip && "This field is required"}
                        />
                      )}
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={settingRef}>
                <CardHeader title="Work Settings" />
                <CardContent>
                  <Controller
                    name="bio"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Tell us about you (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        multiline
                        rows={6}
                        sx={{ my: 0 }}
                      />
                    )}
                  />
                  {!_isManage360 && (
                    <Stack direction="row" spacing={1} sx={{ mt: 3 }}>
                      <Controller
                        name="min_pay"
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            InputLabelProps={{ shrink: !!value }}
                            label="Minimum Pay ($)"
                            value={value}
                            fullWidth
                            onChange={onChange}
                            onBlur={onBlur}
                            sx={{ mt: 0 }}
                            type="number"
                            error={!!errors.min_pay}
                            helperText={
                              !!errors.min_pay && "This field is required"
                            }
                          />
                        )}
                      />
                      <Controller
                        name="max_pay"
                        control={control}
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            InputLabelProps={{ shrink: !!value }}
                            label="Maximum Pay ($)"
                            value={value}
                            fullWidth
                            onChange={onChange}
                            onBlur={onBlur}
                            type="number"
                            error={!!errors.max_pay}
                            helperText={
                              !!errors.max_pay && "This field is required"
                            }
                          />
                        )}
                      />
                    </Stack>
                  )}
                  <Controller
                    name="commute_radius"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Commute Radius (miles)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        type="number"
                        error={!!errors.commute_radius}
                        helperText={
                          !!errors.commute_radius && "This field is required"
                        }
                      />
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={skillRef}>
                <CardHeader title="Skills" />
                <CardContent>
                  <Controller
                    name="skills"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectSkills onChange={onChange} values={value} />
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={skillRef}>
                <CardHeader title="Industries" />
                <CardContent>
                  <Controller
                    name="business_verticals"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectVerticals onChange={onChange} values={value} />
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={languageRef}>
                <CardHeader title="Languages" />
                <CardContent>
                  <Controller
                    name="languages"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectLanguages onChange={onChange} values={value} />
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={workStatusRef}>
                <CardHeader title="Work Status & Availability" />
                <CardContent>
                  <Controller
                    name="work_status"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <SelectWorkStatus
                        onChange={onChange}
                        value={value}
                        error={!!errors.work_status}
                        helperText={
                          !!errors.work_status && "This field is required"
                        }
                      />
                    )}
                  />
                  <Controller
                    name="workAvails"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectAvailability onChange={onChange} values={value} />
                    )}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={certRef}>
                <CardHeader
                  title="Certificates & Licenses"
                  action={
                    <IconButton
                      onClick={() => setCertModal({ open: true, data: null })}
                    >
                      <AddIcon />
                    </IconButton>
                  }
                />
                <CardContent>
                  {data?.certifications?.length == 0 ? (
                    <Typography
                      variant="body2"
                      color="#aaa"
                      sx={{ fontStyle: "italic" }}
                    >
                      No certification added
                    </Typography>
                  ) : (
                    data?.certifications?.map((it) => (
                      <ListItem
                        key={it.id}
                        secondaryAction={
                          <Stack direction={"row"}>
                            <IconButton
                              edge="end"
                              size="small"
                              onClick={() =>
                                setCertModal({
                                  open: true,
                                  data: it,
                                })
                              }
                            >
                              <EditOutlined />
                            </IconButton>
                            <IconButton
                              edge="end"
                              size="small"
                              color="error"
                              onClick={() =>
                                setConfirmDeleteCertModal({
                                  open: true,
                                  id: it.id,
                                })
                              }
                            >
                              <DeleteOutline />
                            </IconButton>
                          </Stack>
                        }
                      >
                        <ListItemIcon>
                          <SubtitlesOutlined />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${it.certification?.name} (${it.certification?.provider})`}
                          secondary={`Issued: ${it.issue_date}`}
                        />
                      </ListItem>
                    ))
                  )}
                </CardContent>
              </Card>
              {/* <Card sx={{ mt: 1 }} ref={workRef}>
                  <CardHeader
                    title="Work Experiences"
                    action={
                      <IconButton>
                        <AddIcon />
                      </IconButton>
                    }
                  />
                  <CardContent></CardContent>
                </Card> */}
              <Card sx={{ mt: 1 }} ref={eduRef}>
                <CardHeader
                  title="Educations"
                  action={
                    <IconButton
                      onClick={() => setEduModal({ open: true, data: null })}
                    >
                      <AddIcon />
                    </IconButton>
                  }
                />
                <CardContent>
                  {data?.educations?.length == 0 ? (
                    <Typography
                      variant="body2"
                      color="#aaa"
                      sx={{ fontStyle: "italic" }}
                    >
                      No education added
                    </Typography>
                  ) : (
                    data?.educations?.map((it) => (
                      <ListItem
                        key={it.id}
                        secondaryAction={
                          <Stack direction={"row"}>
                            <IconButton
                              edge="end"
                              size="small"
                              onClick={() =>
                                setEduModal({
                                  open: true,
                                  data: it,
                                })
                              }
                            >
                              <EditOutlined />
                            </IconButton>
                            <IconButton
                              edge="end"
                              size="small"
                              color="error"
                              onClick={() =>
                                setConfirmDeleteEduModal({
                                  open: true,
                                  id: it.id,
                                })
                              }
                            >
                              <DeleteOutline />
                            </IconButton>
                          </Stack>
                        }
                      >
                        <ListItemIcon>
                          <SchoolOutlined />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${it.school}`}
                          secondary={`${it.field_of_study || ""}`}
                        />
                      </ListItem>
                    ))
                  )}
                </CardContent>
              </Card>
              <Card sx={{ mt: 1 }} ref={settingRef}>
                <CardHeader title="Social Media" />
                <CardContent>
                  <Controller
                    name="linkedin"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="LinkedIn URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="facebook"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Facebook URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="instagram"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Instagram URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    name="website"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        InputLabelProps={{ shrink: !!value }}
                        label="Website URL (optional)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </CardContent>
              </Card>
              <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 2 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={isLoading}
                  loading={adding || updating}
                  // sx={{ width: "50%" }}
                >
                  {!!data ? "Update Talent" : "Create Talent"}
                </LoadingButton>
              </Stack>
            </Box>
          </form>
        </Grid>
      </Grid>
      <ConfirmDeleteModal
        open={deleteCertModal.open}
        onClose={closeCertDeleteModal}
        onDelete={handleCertDelete}
        message={`Delete User Certification #${deleteCertModal.id} ?`}
      />
      <ConfirmDeleteModal
        open={deleteEduModal.open}
        onClose={closeEduDeleteModal}
        onDelete={handleEduDelete}
        message={`Delete User Education #${deleteEduModal.id} ?`}
      />
      <ModalUserCertificationForm
        open={certModal.open}
        onClose={() => setCertModal({ open: false, data: null })}
        userId={parseInt(talentId!)}
        data={certModal.data}
      />
      <ModalUserEducationForm
        open={eduModal.open}
        onClose={() => setEduModal({ open: false, data: null })}
        userId={parseInt(talentId!)}
        data={eduModal.data}
      />
    </Box>
  );
};
export default TalentEditPage;
