import * as React from "react";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useTitle } from "../../hooks/useTitle";
import ProjectDetailsPanel from "./ProjectDetailsPanel";
import ProjectMessagesPanel from "./ProjectMessagesPanel";
import { Project, ProjectStatus } from "../../types/project";
import { useGetProjectByIdQuery } from "../../services/project.service";
import { Alert, Divider, LinearProgress, Skeleton } from "@mui/material";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ProjectTalentsPanel from "./ProjectTalentsPanel";
import qs from "qs";
import { formatWithTimezone } from "../../utils/dateHelper";
import ChipProjectStatus from "../../components/ChipProjectStatus";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/*<Typography>{children}</Typography>*/}
          {children}
        </Box>
      )}
    </div>
  );
}

const ProjectMeta = ({
  icon,
  value,
}: {
  icon: React.ReactNode;
  value: string;
}) => (
  <Button
    variant="text"
    startIcon={icon}
    size="small"
    sx={{ mr: 2, textTransform: "unset", color: "text.secondary" }}
  >
    {value}
  </Button>
);

// const StatusChip = ({ status }: { status?: string }) => {
//   status = status?.toLowerCase();
//   const style = { fontWeight: 500 };

//   if (status == ProjectStatus.DRAFT)
//     return <Chip label={"DRAFT"} sx={style} color="default" />;
//   if (status == ProjectStatus.PUBLISHED)
//     return <Chip label={"PUBLISHED"} sx={style} color="secondary" />;
//   if (status == ProjectStatus.IN_PROGRESS)
//     return <Chip label={"IN PROGRESS"} sx={style} color="primary" />;
//   if (status == ProjectStatus.COMPLETED)
//     return <Chip label={"COMPLETED"} sx={style} color="info" />;
//   if (status == ProjectStatus.CANCELED)
//     return <Chip label={"CANCELED"} sx={style} color="error" />;
//   else return <Chip label={status} sx={style} color="default" />;
// };

const ProjectHeader = ({
  data,
  loading,
}: {
  data?: Project;
  loading: Boolean;
}) => {
  const { city, state, zip } = React.useMemo(() => {
    if (!!data?.customer) {
      return data?.customer;
    } else if (!!data?.location) {
      return data?.location;
    } else
      return {
        city: "?",
        state: "?",
        zip: "?",
      };
  }, [data]);

  if (loading)
    return (
      <Stack direction="column" sx={{ mb: 4 }} flex={1}>
        <Skeleton variant="text" sx={{ width: "30%" }} />
        <Skeleton variant="text" sx={{ width: "50%" }} />
      </Stack>
    );

  return (
    <Stack direction="column" sx={{ mb: 4 }} flex={1}>
      <Stack direction="row">
        <Box sx={{ mr: 1 }}>
          <ChipProjectStatus status={data?.status as ProjectStatus} />
        </Box>
        <Typography variant="h5">{`#${data?.id} ${data?.title}`}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <ProjectMeta
          icon={<BusinessCenterIcon />}
          value={!!data?.is_private ? "Private Job" : "Public Job"}
        />
        <ProjectMeta
          icon={<BusinessCenterIcon />}
          value={data?.type == "fixed" ? "Fixed Price" : "Hourly Rate"}
        />
        <ProjectMeta
          icon={<CalendarMonthIcon />}
          value={`${formatWithTimezone(data?.start_date, data?.timezone)}
          `}
        />
        <ProjectMeta
          icon={<LocationCityIcon />}
          value={`${city}, ${state} ${zip}`}
        />
      </Stack>
      {data?.status == ProjectStatus.CANCELED && (
        <Alert severity="error">
          Cancel Reason: {data?.canceled_reason || "n/a"}
        </Alert>
      )}
    </Stack>
  );
};

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ProjectDetailPage = () => {
  const { projectId } = useParams();

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data, isLoading, isFetching } = useGetProjectByIdQuery(
    parseInt(projectId!),
    {
      skip: !projectId,
    }
  );

  useTitle(`Project #${data?.id}`, `${data?.title} | Project`);

  React.useEffect(() => {
    const params = qs.parse(window.location.href.split("?")[1]);
    console.log(params);
    if (params.t) {
      setValue(Number(params.t));
    }
  }, []);

  return (
    <Box>
      <ProjectHeader data={data} loading={isLoading || isFetching} />
      {(isLoading || isFetching) && <LinearProgress />}
      <Box sx={{ bgcolor: "background.paper", width: "auto" }} boxShadow={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Talents" {...a11yProps(1)} />
          <Tab label="Messages" {...a11yProps(2)} />
          {/* <Tab label="Log" {...a11yProps(3)} /> */}
        </Tabs>
        <Divider />
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ProjectDetailsPanel data={data} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {!!data && <ProjectTalentsPanel project={data} />}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {!!data && <ProjectMessagesPanel project={data} />}
        </TabPanel>
        {/* <TabPanel value={value} index={3} dir={theme.direction}>
          <ProjectHistoriesPanel />
        </TabPanel> */}
      </Box>
    </Box>
  );
};
export default ProjectDetailPage;
