import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import { Skill, SkillCategory } from "../../types/skill";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import {
  useGetSkillByIdQuery,
  useAddSkillMutation,
  useEditSkillMutation,
} from "../../services/skill.service";
import { useGetSkillCategoriesQuery } from "../../services/skill-category.service";
import { Stack } from "@mui/material";
import ModalSkillCategoryForm from "./ModalSkillCategoryForm";
import { isManage360 } from "../../utils/helper";

interface ModalSkillFormProps {
  open: boolean;
  onClose: () => void;
  data: Skill | null;
}

const ModalSkillForm = ({ open, onClose, data }: ModalSkillFormProps) => {
  const [formModal, setFormModal] = React.useState<{
    open: boolean;
    data: SkillCategory | null;
  }>({
    open: false,
    data: null,
  });

  const { data: skill } = useGetSkillByIdQuery(data?.id!, {
    skip: true, //!data?.id,
  });
  const { data: categories, isLoading: loadingCategory } =
    useGetSkillCategoriesQuery({
      page: 0,
      pageSize: 100,
      sortField: "name",
      sortMode: "asc",
    });

  const [add, { isLoading: adding }] = useAddSkillMutation();
  const [edit, { isLoading: editing }] = useEditSkillMutation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!data) await add(payload).unwrap();
      else {
        payload.id = data?.id!;
        await edit(payload).unwrap();
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("name", data?.name);
      setValue("category", data?.category_id);
      setValue("description", data?.description);
      setValue("min_price", data?.min_price);
      setValue("max_price", data?.max_price);
    }
  }, [data, open]);

  React.useEffect(() => {
    setValue("name", skill?.name);
    setValue("category", skill?.category_id);
    setValue("description", skill?.description);
    setValue("min_price", skill?.min_price);
    setValue("max_price", skill?.max_price);
  }, [skill]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} Skill
        </DialogTitle>
        <DialogContent>
          <Box>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Skill name"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.name}
                  helperText={!!errors.name && "This field is required"}
                />
              )}
            />
            <Controller
              name="category"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ref } }) => (
                <FormControl fullWidth size="small" error={!!errors.category}>
                  <InputLabel id="select-label">Skill category</InputLabel>
                  <Select
                    labelId="select-label"
                    value={value}
                    label="Skill category"
                    onChange={onChange}
                    disabled={loadingCategory}
                  >
                    {categories?.data.map((it, idx) => (
                      <MenuItem value={it.id} key={idx}>
                        {it.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors.category && (
                    <FormHelperText>This field is required</FormHelperText>
                  )}
                  <Stack direction="row" justifyContent={"flex-end"}>
                    <Button
                      variant="text"
                      size="small"
                      onClick={() => setFormModal({ data: null, open: true })}
                    >
                      <AddIcon fontSize={"small"} />
                      New Category
                    </Button>
                  </Stack>
                </FormControl>
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Description (optional)"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  multiline
                />
              )}
            />
            {!isManage360() && (
              <>
                <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                  <Controller
                    name="min_price"
                    control={control}
                    rules={{ required: true, min: 1 }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        label="Min Price ($)"
                        value={value}
                        fullWidth
                        sx={{ mt: 0 }}
                        onChange={onChange}
                        onBlur={onBlur}
                        type="number"
                        error={!!errors.min_price}
                        helperText={
                          !!errors.min_price && "This field is required"
                        }
                      />
                    )}
                  />
                  <Controller
                    name="max_price"
                    control={control}
                    rules={{ required: true, min: 1 }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <TextField
                        label="Max Price ($)"
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        type="number"
                        error={!!errors.max_price}
                        helperText={
                          !!errors.max_price && "This field is required"
                        }
                      />
                    )}
                  />
                </Stack>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={adding || editing}
          >
            {!data ? "Create" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
      <ModalSkillCategoryForm
        open={formModal.open}
        onClose={() => setFormModal({ open: false, data: null })}
        data={formModal.data}
      />
    </Dialog>
  );
};
export default ModalSkillForm;
