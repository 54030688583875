import * as React from "react";

const Linkify = ({ url }: { url?: string }) => {
  const theUrl = !url?.startsWith("http") ? "https://" + url : url;

  if (!url) return <span style={{ color: "#aaa" }}>n/a</span>;
  return (
    <a href={theUrl} target="_blank">
      {url}
    </a>
  );
};
export default React.memo(Linkify);
