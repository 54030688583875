import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridToolbarContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  LinearProgress,
  Link,
  Paper,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
import useDebounce from "../../hooks/useDebounce";
import { Project, ProjectResource } from "../../types/project";
import { Talent } from "../../types/users";
import { useNavigate } from "react-router-dom";
import { useGetProjectsQuery } from "../../services/project.service";
// import ProjectDrawer from "../talent/ProjectDrawer";
import dayjs from "dayjs";
import ProjectDrawer from "../project/ProjectDrawer";

const TableToolbar = ({ onSearch }: { onSearch: (query?: string) => void }) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
    </GridToolbarContainer>
  );
};

const TalentJobsPanel = ({ data: dataTalent }: { data?: Talent }) => {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState<Project | null>(null);

  const { data, isLoading, isFetching } = useGetProjectsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
    talents: dataTalent?.id,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "title",
        headerName: "Job Title",
        flex: 1,
        sortable: true,
        filterable: false,
        minWidth: 300,
        // valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
        //   `#${params.row.id} - ${params.row.title}`,
        renderCell: (params: GridRenderCellParams<undefined, Project>) => (
          <Link
            component="button"
            variant="body2"
            onClick={() => onView(params.row)}
          >
            #{params.row.id} - {params.row.title}
          </Link>
        ),
      },
      {
        field: "resources.status",
        headerName: "Status",
        minWidth: 120,
        sortable: true,
        filterable: true,
        valueGetter: (params: GridValueGetterParams<undefined, Project>) => {
          const resource = params.row.resources?.find(
            (item: ProjectResource) => item.resource_id === dataTalent?.id
          );
          return `${resource?.status?.toUpperCase()}`;
        },
      },
      {
        field: "resources.rate",
        headerName: "Rate",
        minWidth: 100,
        sortable: false,
        filterable: false,
        valueGetter: (params: GridValueGetterParams<undefined, Project>) => {
          const resource = params.row.resources?.find(
            (item: ProjectResource) => item.resource_id === dataTalent?.id
          );
          return params.row.type == "fixed"
            ? `$${resource?.price} (fixed)`
            : `$${resource?.rate}/hr`;
          // return `${resource?.rate ? `$${resource?.rate}/hr` : ""}`;
        },
      },
      {
        field: "customer_id",
        headerName: "Customer",
        minWidth: 200,
        sortable: true,
        filterable: true,
        renderCell: (params: GridRenderCellParams<undefined, Project>) =>
          !!params.row.company ? (
            <Typography variant="body2">{params.row.company.name}</Typography>
          ) : (
            <Typography>?</Typography>
          ),
      },
      {
        field: "resources.hired_at",
        headerName: "Hired At",
        minWidth: 200,
        sortable: false,
        filterable: false,
        valueGetter: (params: GridValueGetterParams<undefined, Project>) => {
          const resource = params.row.resources?.find(
            (item: ProjectResource) => item.resource_id === dataTalent?.id
          );
          return `${
            resource?.hired_at
              ? dayjs(resource?.hired_at).format("MMM DD, YYYY hh:mm A")
              : ""
          }`;
        },
      },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const CustomToolbar = React.useMemo(
    () => () => <TableToolbar onSearch={handleSearch} />,
    [handleSearch]
  );

  const onView = (item: any) => {
    setCurrentItem(item);
    setOpen(true);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Paper style={{ height: "65vh" }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[25, 50, 100]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnFilter
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
        />
      </Paper>
      <ProjectDrawer data={currentItem!} open={open} setOpen={setOpen} />
    </Box>
  );
};
export default TalentJobsPanel;
