import * as React from "react";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  colors,
  useTheme,
} from "@mui/material";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import FaceIcon from "@mui/icons-material/Face";
import { Talent } from "../../types/users";
import { useTitle } from "../../hooks/useTitle";
import TalentDrawer from "./TalentDrawer";
import {
  Circle,
  GoogleMap,
  useLoadScript,
  OverlayView,
} from "@react-google-maps/api";
import { GMAP_API_KEY } from "../../configs/api";
import { useGetTalentsQuery } from "../../services/talent.service";
import { getDistanceBetweenPoints } from "../../utils/mapHelper";

const mapContainerStyle = {
  width: "100%",
  height: "85vh",
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  clickableIcons: false,
};

const CustomMarker = (props: any) => {
  const { name, photo, onClick } = props;
  const theme = useTheme();

  return (
    <OverlayView {...props}>
      <Box sx={{ marginTop: -2.5, marginLeft: -2.5 }}>
        <Avatar
          alt={name}
          src={photo?.[0]?.original_url}
          onClick={onClick}
          sx={{ backgroundColor: theme.palette.primary.main }}
        />
      </Box>
    </OverlayView>
  );
};

//Center coordinates for US
const DEFAULT_LAT = 37.0902;
const DEFAULT_LNG = -95.7129;

//see all US
const DEFAULT_ZOOM = 5;
const DEFAULT_RADIUS = 11000; //m --> changed according to DEFAULT_ZOOM
const mapLibraries = ["geometry"];

const TalentsMapPage = () => {
  useTitle("Talents Map");
  const [center, setCenter] = React.useState({
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
  });
  const [searchParam, setSearchParam] = React.useState({
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
    radius: DEFAULT_RADIUS,
  });

  const { data, isFetching } = useGetTalentsQuery({
    page: 0,
    pageSize: 100,
    distance: `${searchParam.lat}|${searchParam.lng}|${searchParam.radius}`,
  });

  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState<Talent | null>(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GMAP_API_KEY,
    //@ts-ignore
    libraries: mapLibraries,
  });

  const mapRef = React.useRef<google.maps.Map>();

  const onMapLoad = React.useCallback(
    (map) => {
      map.setCenter(center);
      mapRef.current = map;
    },
    [center]
  );

  const getRadius = React.useCallback(() => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      if (!bounds) return null;

      // computeDistanceBetween returns meters
      const nsRadius = google.maps.geometry.spherical.computeDistanceBetween(
        bounds.getCenter().toJSON(),
        {
          lat: bounds.getNorthEast().toJSON().lat,
          lng: bounds.getCenter().toJSON().lng,
        }
      );
      // console.log(`nsRadius: ${nsRadius.toFixed(2)}`);
      const ewRadius = google.maps.geometry.spherical.computeDistanceBetween(
        bounds.getCenter().toJSON(),
        {
          lat: bounds.getCenter().toJSON().lat,
          lng: bounds.getNorthEast().toJSON().lng,
        }
      );
      const radius = nsRadius >= ewRadius ? nsRadius : ewRadius;
      return Number(radius.toFixed(0));
    }
  }, [mapRef.current]);

  const onDragEnd = React.useCallback(() => {
    if (!mapRef.current) return;
    const lat = mapRef.current?.getCenter()?.lat() || 0;
    const lng = mapRef.current?.getCenter()?.lng() || 0;
    const dist = getDistanceBetweenPoints(
      { lat, lng },
      { lat: center.lat, lng: center.lng }
    );
    if (dist >= 1) {
      // if center changed more than 1 mile
      setCenter({ lat, lng });
      setSearchParam({ lat, lng, radius: getRadius() || DEFAULT_RADIUS });
    }
  }, [getRadius]);

  const onZoomChanged = React.useCallback(() => {
    if (!mapRef.current) return;
    const lat = mapRef.current?.getCenter()?.lat() || 0;
    const lng = mapRef.current?.getCenter()?.lng() || 0;
    setCenter({ lat, lng });
    setSearchParam({ lat, lng, radius: getRadius() || DEFAULT_RADIUS });
  }, [getRadius]);

  const handleMarkerClick = (item: Talent) => {
    setCurrentItem(item);
    setOpen(true);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        width: "auto",
        boxShadow: 1,
        height: "85vh",
      }}
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        // center={center}
        zoom={DEFAULT_ZOOM}
        options={options}
        onDragEnd={onDragEnd}
        onZoomChanged={onZoomChanged}
        onLoad={onMapLoad}
      >
        <React.Fragment>
          <Box
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              zIndex: 1,
            }}
          >
            {isFetching ? (
              <Chip
                icon={<CircularProgress size={20} />}
                label={`Searching talents...`}
              />
            ) : (
              <>
                {!data?.meta.total ? (
                  <Chip
                    label={`Talents not found, try move to another location or change zoom level`}
                    color="warning"
                  />
                ) : (
                  <Chip
                    icon={<FaceIcon />}
                    label={`${data?.meta.total} talents found (max: 100 per map)`}
                    color="info"
                  />
                )}
              </>
            )}
          </Box>
          <OverlayView
            key={"#company-marker"}
            position={{
              lat: DEFAULT_LAT,
              lng: DEFAULT_LNG,
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <Box sx={{ marginTop: -2.5, marginLeft: -2.5 }}>
              <Chip
                icon={<HomeRepairServiceIcon />}
                label={`Workz360`}
                color="success"
              />
            </Box>
          </OverlayView>

          {/*
           * for debuging change circle component propreties visible=true
           */}
          {/* <Circle
            center={center} // required
            radius={searchParam.radius} // required
            options={{
              strokeColor: colors.red[400],
              fillColor: colors.red[400],
            }}
            visible={true}
          /> */}

          {data?.data.map((talent) => (
            <CustomMarker
              id={talent.id}
              key={`#talent-marker-${talent.id}`}
              name={talent.full_name}
              photo={talent.photo}
              position={{
                lat: talent.coordinate?.coordinates[1] || 0,
                lng: talent.coordinate?.coordinates[0] || 0,
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              onClick={() => handleMarkerClick(talent)}
            />
          ))}
        </React.Fragment>
      </GoogleMap>
      <TalentDrawer data={currentItem!} open={open} setOpen={setOpen} />
    </Box>
  );
};
export default TalentsMapPage;
