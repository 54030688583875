import { Company } from "../types/company";
import { BusinessVertical } from "../types/vertical";
import { User } from "../types/users";
import { isFile } from "../utils/helper";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "Companies";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const companyApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<
      QueryResponse<Company & { business_vertical: BusinessVertical }>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
      }
    >({
      query: ({ page, pageSize, sortField, sortMode, search }) => ({
        url: "api/admin/companies",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `first_name|asc`,
          withTrashed: true,
          load: "logo,businessVertical,billingCountry,country",
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getAllCompanies: builder.query<
      Array<Company>,
      { sortField?: string; sortMode?: string | null; search?: string }
    >({
      query: ({ sortField, sortMode, search }) => ({
        url: "api/admin/companies",
        method: "GET",
        params: {
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST", mode: "ALL" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST", mode: "ALL" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getCompanyById: builder.query<Company, number>({
      query: (id) => ({
        url: `api/admin/companies/${id}`,
        method: "GET",
        params: {
          load: "totalJobSuccessRate,totalJob,logo,businessVertical,billingCountry,country,logo",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    getCompanyByIdUser: builder.query<
      QueryResponse<User>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        company: number;
      }
    >({
      query: ({ page, pageSize, sortField, sortMode, search, company }) => ({
        url: `api/admin/companies/${company}/user`,
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `first_name|asc`,
          withTrashed: true,
          // load: "logo,businessVertical,billingCountry,country",
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    addCompany: builder.mutation<Company, Omit<Company, "id">>({
      query: (body) => {
        let formData = new FormData();
        Object.entries(body).forEach(([key, value]) => {
          if (typeof value == "number") formData.append(key, value.toString());
          else if (Array.isArray(value)) {
            // formData.append(key, JSON.stringify(value));
            value.forEach((it: any) => {
              formData.append(`${key}[]`, it);
            });
          } else if (key == "logo") {
            if (isFile(value)) formData.append(key, value as any);
          } else formData.append(key, value as string);
        });
        return {
          url: `api/admin/companies`,
          method: "POST",
          body: formData,
          // body: {
          //   role: ROLE_TALENT_ID,
          //   ...body
          // },
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    editCompany: builder.mutation<
      Company,
      Partial<Company> & Pick<Company, "id">
    >({
      query: (body) => {
        // if (!body.photo) delete body.photo;

        let formData = new FormData();
        formData.append("_method", "put");

        Object.entries(body).forEach(([key, value]) => {
          if (typeof value == "number") formData.append(key, value.toString());
          else if (Array.isArray(value)) {
            // formData.append(key, JSON.stringify(value));
            value.forEach((it: any) => {
              formData.append(`${key}[]`, it);
            });
          } else if (key == "logo") {
            if (isFile(value)) formData.append(key, value as any);
          } else formData.append(key, value as string);
        });

        return {
          url: `api/admin/companies/${body.id}`,
          method: "POST",
          body: formData,
          // body: {
          //   role: ROLE_TALENT_ID,
          //   ...body
          // },
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteCompany: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/companies/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    restoreCompany: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/companies/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    activateCompany: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/companies/${id}/active`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    disableCompany: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/companies/${id}/in-active`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    getCompanyTotalJobSuccessRateById: builder.query<any, number>({
      query: (id) => ({
        url: `api/admin/companies/${id}/total-job-success-rate`,
        method: "GET",
      }),
    }),
    getCompanyTotalJobById: builder.query<any, number>({
      query: (id) => ({
        url: `api/admin/companies/${id}/total-job`,
        method: "GET",
      }),
    }),
    getCompanyTotalSpentById: builder.query<any, number>({
      query: (id) => ({
        url: `api/admin/companies/${id}/total-spent`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompaniesQuery,
  useGetAllCompaniesQuery,
  useGetCompanyByIdQuery,
  useGetCompanyByIdUserQuery,
  useAddCompanyMutation,
  useEditCompanyMutation,
  useDeleteCompanyMutation,
  useRestoreCompanyMutation,
  useActivateCompanyMutation,
  useDisableCompanyMutation,
  useGetCompanyTotalJobSuccessRateByIdQuery,
  useGetCompanyTotalJobByIdQuery,
  useGetCompanyTotalSpentByIdQuery,
} = companyApi;
