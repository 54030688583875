import { Resource } from "../types/resource";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "Resources";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const resourceApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getResources: builder.query<
      QueryResponse<Resource>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
      }
    >({
      query: ({ page, pageSize, sortField, sortMode, search }) => ({
        url: "api/admin/resources",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `category|asc`,
          withTrashed: true,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getResourceById: builder.query<Resource, number>({
      query: (id) => ({
        url: `api/admin/resources/${id}`,
        method: "GET",
        params: {},
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    createResource: builder.mutation<
      Resource,
      Partial<Resource> & Omit<Resource, "id">
    >({
      query: (body) => {
        return {
          url: `api/admin/resources`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    editResource: builder.mutation<
      Resource,
      Partial<Resource> & Pick<Resource, "id">
    >({
      query: (body) => {
        return {
          url: `api/admin/resources/${body.id}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteResource: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/resources/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    restoreResource: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/resources/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetResourcesQuery,
  useGetResourceByIdQuery,
  useCreateResourceMutation,
  useEditResourceMutation,
  useDeleteResourceMutation,
  useRestoreResourceMutation,
} = resourceApi;
