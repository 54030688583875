import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
  GridValueGetterParams,
  GridToolbarExport,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Stack,
  Paper,
  Button,
  Link,
  IconButton,
  TextField,
  Rating,
  Avatar,
  Typography,
  Chip,
} from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { Talent } from "../../types/users";
import { useNavigate } from "react-router-dom";
import {
  useActivateCompanyMutation,
  useDisableCompanyMutation,
  useGetCompaniesQuery,
} from "../../services/company.service";
import { Company } from "../../types/company";
import CompanyDrawer from "./CompanyDrawer";
import { formatMoney, isManage360, ucFirstAllWords } from "../../utils/helper";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        {/* <Button variant="outlined" onClick={onCreate}>
          <AddIcon /> Add Client
        </Button> */}
        <GridToolbarExport
          sx={{ ml: 2 }}
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Clients" }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const CompaniesPage = () => {
  useTitle("Clients");
  const navigate = useNavigate();
  const _isManage360 = React.useMemo(() => isManage360(), []);

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);

  const { data, isLoading, isFetching } = useGetCompaniesQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const [doActivate, { isLoading: activing }] = useActivateCompanyMutation();
  const [doDisable, { isLoading: inactiving }] = useDisableCompanyMutation();

  const handleEditRow = (row: GridRowModel<Talent>) => {
    //
  };

  const handleUpdateStatusRow = async (
    row: GridRowModel<Company>,
    newStatus: string
  ) => {
    try {
      if (newStatus === "active") {
        await doActivate(row.id).unwrap();
      }
      if (newStatus === "in-active") {
        await doDisable(row.id).unwrap();
      }
      toast.success("Successfully updated");
    } catch (err) {}
  };

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () =>
      [
        {
          field: "customer_name",
          headerName: "Client Name",
          flex: 1,
          minWidth: 300,
          sortingOrder: ["desc", "asc", null],
          filterable: true,
          renderCell: (params: GridRenderCellParams<undefined, Company>) => (
            <Stack direction={"row"} alignItems="center">
              {/* <Avatar alt={`${params.row.name}`} src={`dummy`} sx={{ mr: 1 }} /> */}
              <Stack>
                <Stack direction={"row"} alignItems="center">
                  <Link
                    onClick={() => onView(params.row)}
                    variant={"body2"}
                    component={"button"}
                  >
                    {params.row.name}
                  </Link>
                  <Rating
                    value={params.row.total_rating}
                    readOnly
                    size="small"
                    sx={{ ml: 2 }}
                  />
                </Stack>
                <Typography variant="caption">
                  {params.row.primary_contact_name || "Unknwon"} -{" "}
                  {params.row.primary_contact_phone_number || "Unknown"}
                </Typography>
              </Stack>
            </Stack>
          ),
          disableExport: true,
        },
        {
          field: "name",
          headerName: "Customer Name",
          hide: true,
        },
        {
          field: "primary_contact_name",
          headerName: "Contact Name",
          hide: true,
        },
        {
          field: "primary_contact_phone_number",
          headerName: "Contact Number",
          hide: true,
        },
        {
          field: "total_rating",
          headerName: "Rating",
          hide: true,
        },
        {
          field: "balance_decrypt",
          headerName: "Balance",
          headerAlign: "center",
          minWidth: 120,
          sortable: false,
          filterable: false,
          align: "right",
          valueGetter: (params: GridValueGetterParams<undefined, Company>) =>
            `${formatMoney(params.row.balance_decrypt)}`,
        },
        {
          field: "fixed_price_fee",
          headerName: "Fixed Fee",
          headerAlign: "center",
          minWidth: 120,
          sortable: false,
          filterable: false,
          align: "center",
          valueGetter: (params: GridValueGetterParams<undefined, Company>) =>
            !!params.row.fixed_price_fee
              ? `${formatMoney(params.row.fixed_price_fee)}`
              : `(Not Set)`,
        },
        {
          field: "business_vertical_id",
          headerName: "Industry",
          headerAlign: "center",
          minWidth: 150,
          sortable: true,
          filterable: false,
          align: "center",
          valueGetter: (params: GridValueGetterParams<undefined, Company>) =>
            `${params.row.business_vertical?.name || "(Not Set)"}`,
        },
        {
          field: "city",
          headerName: "City / State",
          headerAlign: "center",
          minWidth: 150,
          sortable: true,
          filterable: true,
          align: "center",
          valueGetter: (params: GridValueGetterParams<undefined, Company>) =>
            `${params.row.city}, ${params.row.state}`,
        },
        {
          field: "status",
          headerName: "Status",
          headerAlign: "center",
          sortable: true,
          filterable: true,
          align: "center",
          renderCell: (params: GridRenderCellParams<undefined, Company>) => (
            <Chip
              label={params.row.status.toUpperCase()}
              size="small"
              color={params.row.status == "active" ? "success" : undefined}
              sx={{ fontSize: 12 }}
            />
          ),
        },
        {
          field: "control",
          headerName: "Toggle",
          headerAlign: "center",
          sortable: false,
          filterable: false,
          align: "center",
          renderCell: (params: GridRenderCellParams<undefined, Company>) => {
            if (params.row.status === "pending") {
              return (
                <Button
                  variant="contained"
                  size="small"
                  color="info"
                  onClick={() => handleUpdateStatusRow(params.row, "active")}
                >
                  Approve
                </Button>
              );
            } else if (params.row.status === "active") {
              return (
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() => handleUpdateStatusRow(params.row, "in-active")}
                >
                  Disable
                </Button>
              );
            } else if (params.row.status === "in-active") {
              return (
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  onClick={() => handleUpdateStatusRow(params.row, "active")}
                >
                  Enable
                </Button>
              );
            } else {
              return null;
            }
          },
          disableExport: true,
        },
        // {
        //   field: "control",
        //   headerName: "",
        //   sortable: false,
        //   filterable: false,
        //   renderCell: (params: GridRenderCellParams<undefined, Talent>) =>
        //     !!params.row.deleted_at ? (
        //       <Button
        //         variant="text"
        //         color="secondary"
        //         onClick={() => handleRestoreRow(params.row)}
        //       >
        //         Restore
        //       </Button>
        //     ) : (
        //       <Stack direction={"row"}>
        //         <IconButton onClick={() => handleEditRow(params.row)}>
        //           <EditIcon fontSize="small" />
        //         </IconButton>
        //         <IconButton onClick={() => handleDeleteRow(params.row)}>
        //           <DeleteIcon fontSize="small" />
        //         </IconButton>
        //       </Stack>
        //     ),
        // },
      ].filter((it) => {
        if (_isManage360) {
          return ![
            "total_rating",
            "percentage_uplift",
            "fixed_price_fee",
            "balance_decrypt",
          ].includes(it.field);
        } else return true;
      }) as GridColDef[],
    [_isManage360]
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    navigate(`/clients/create`);
  }, []);

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  const onView = (item: any) => {
    setCurrentItem(item);
    setOpen(true);
  };

  return (
    <Paper style={{ height: "85vh" }}>
      <DataGrid
        rows={rows || []}
        columns={columns}
        paginationMode="server"
        rowCount={rowCount}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newSize) => setPageSize(newSize)}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        loading={isLoading || isFetching}
        components={{
          LoadingOverlay: LinearProgress,
          NoResultsOverlay: NoResultsOverlay,
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        disableColumnFilter
        rowHeight={56}
        // density="compact"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            borderTop: "1px solid #ddd",
          },
          "& .deleted": {
            fontStyle: "italic",
            color: "#aaa",
          },
        }}
        getRowClassName={getRowClassName}
      />
      <CompanyDrawer data={currentItem!} open={open} setOpen={setOpen} />
    </Paper>
  );
};
export default CompaniesPage;
