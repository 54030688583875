import * as React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Autocomplete, Checkbox, TextField } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const workAvails = [
  {
    label: "Monday",
    value: "avail_monday",
  },
  {
    label: "Tuesday",
    value: "avail_tuesday",
  },
  {
    label: "Wednesday",
    value: "avail_wednesday",
  },
  {
    label: "Thursday",
    value: "avail_thursday",
  },
  {
    label: "Friday",
    value: "avail_friday",
  },
  {
    label: "Saturday",
    value: "avail_saturday",
  },
  {
    label: "Sunday",
    value: "avail_sunday",
  },
];

const SelectAvailability = ({
  onChange,
  values,
}: {
  onChange: (ids?: string[]) => void;
  values: string[];
}) => {
  const initialValues = React.useMemo(
    () => workAvails?.filter((it) => values?.includes(it.value)) || [],
    [workAvails, values]
  );

  return (
    <Autocomplete
      multiple
      fullWidth
      options={workAvails || []}
      disableCloseOnSelect
      ChipProps={{ color: "secondary" }}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Availability" placeholder="Select..." />
      )}
      onChange={(
        event: any,
        newValue: Array<{ label: string; value: string }> | null
      ) => {
        onChange(newValue?.map((it) => it.value));
      }}
      value={initialValues || []}
    />
  );
};
export default SelectAvailability;
