import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import uiReducer from "./uiSlice";
import { authApi } from "../services/auth.service";
import { rtkQueryErrorLogger } from "./middleware";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
// import { encryptTransform } from "redux-persist-transform-encrypt";

const persistConfig = {
  key: "workz360admin",
  storage,
  whitelist: ["auth"],
  /*
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_KEYENCRIPTION!,
      onError: function (error) {
        // Handle the error.
      },
    })],
  */
};

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  auth: authReducer,
  ui: uiReducer,
});

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([authApi.middleware, rtkQueryErrorLogger]),
  devTools: true,
});
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof rootReducer>;
// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >;
