import { ModelTraining } from "@mui/icons-material";
import { BalanceHistory, ManualDeposit } from "../types/finance";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "Finances";
const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE],
});

export const financeApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getBalanceHistories: builder.query<
      QueryResponse<BalanceHistory>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        modelType?: string;
        model?: number;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({
        page,
        pageSize,
        sortField,
        sortMode,
        search,
        modelType,
        model,
      }) => ({
        url: "api/admin/balance-histories",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
          load: "model",
          search,
          model_type: modelType,
          model,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getManualDeposits: builder.query<
      QueryResponse<ManualDeposit>,
      {
        page?: number;
        pageSize?: number;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ page = 0, pageSize = 25 }) => ({
        url: "api/admin/manual-deposits",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          load: "company,reason",
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    addManualDeposit: builder.mutation<
      BalanceHistory,
      Omit<BalanceHistory, "id">
    >({
      query: (body) => {
        let formData = new FormData();
        Object.entries(body).forEach(([key, value]) => {
          if (typeof value == "number") formData.append(key, value.toString());
          else formData.append(key, value as string);
        });
        return {
          url: `api/admin/manual-deposits`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetBalanceHistoriesQuery,
  useGetManualDepositsQuery,
  useAddManualDepositMutation,
} = financeApi;
