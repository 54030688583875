import dayjs from "dayjs";
import { Project, ProjectResource } from "../types/project";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE_PROJECT_JOB = "jobs";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_PROJECT_JOB],
});

export const projectJobApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProjectBids: builder.query<Array<ProjectResource>, number>({
      query: (id) => ({
        url: `api/admin/projects/${id}`,
        method: "GET",
        params: {
          load: "resources,resources.resource,resources.reportProgresses",
        },
      }),
      transformResponse: (response: Project) => {
        return response.resources || [];
      },
      providesTags: (result, error, id) => {
        return [{ type: TAG_TYPE_PROJECT_JOB, id: "PARTIAL-LIST" }];
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetProjectBidsQuery } = projectJobApi;
