import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import { Reason } from "../../types/reason";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetResourceByIdQuery,
  useCreateResourceMutation,
  useEditResourceMutation,
} from "../../services/resource.service";
import SelectSimple from "../../components/SelectSimple";
import { Resource } from "../../types/resource";

interface ModalResourceFormProps {
  open: boolean;
  onClose: () => void;
  data: Resource | null;
}

const ModalResourceForm = ({ open, onClose, data }: ModalResourceFormProps) => {
  const { data: resource } = useGetResourceByIdQuery(data?.id!, {
    skip: !data?.id,
  });

  const [add, { isLoading: adding }] = useCreateResourceMutation();
  const [edit, { isLoading: editing }] = useEditResourceMutation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!data) await add(payload).unwrap();
      else {
        payload.id = data?.id!;
        await edit(payload).unwrap();
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("title", data?.title);
      setValue("category", data?.category);
      setValue("url", data?.url);
    }
  }, [data, open]);

  React.useEffect(() => {
    setValue("title", resource?.title);
    setValue("category", resource?.category);
    setValue("url", resource?.url);
  }, [resource]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="xs"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} Resource
        </DialogTitle>
        <DialogContent>
          <Box>
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Resource Title"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.title}
                  helperText={!!errors.title && "This field is required"}
                />
              )}
            />
            <Controller
              name="category"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Category"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.category}
                  helperText={!!errors.category && "This field is required"}
                />
              )}
            />
            <Controller
              name="url"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Resource Url"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.url}
                  helperText={!!errors.url && "This field is required"}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={adding || editing}
          >
            {!data ? "Create" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalResourceForm;
