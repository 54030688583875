import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TalentFilter } from "../types/ui";
import { RootState } from "./store";

export interface UiState {
  title: string | null;
  talentFilter: TalentFilter;
}

const initialState: UiState = {
  title: null,
  talentFilter: {},
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string | null>) => {
      state.title = action.payload;
    },
    setTalentFilter: (state, action: PayloadAction<TalentFilter>) => {
      state.talentFilter = action.payload;
    },
  },
});

export const { setTitle, setTalentFilter } = uiSlice.actions;
export const currentTitle = (state: RootState) => state.ui.title;

export default uiSlice.reducer;
