import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { useTitle } from "../../hooks/useTitle";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import {
  useDeleteThreadCommentMutation,
  useGetForumThreadByIdQuery,
  useGetForumThreadCommentsQuery,
  useLockForumThreadMutation,
  usePinForumThreadMutation,
} from "../../services/forum.service";
import dayjs from "dayjs";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { ThreadComment } from "../../types/forum";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import { toast } from "react-toastify";
import { Lock, Pin, PushPinOutlined } from "@mui/icons-material";
import LockOutlined from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import { decode } from "html-entities";

const CardComment = ({
  comment,
  onDelete,
}: {
  comment: ThreadComment;
  onDelete: (comment: ThreadComment) => void;
}) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader
        title={
          <Link
            component="button"
            variant="body2"
            onClick={() => navigate(`/talents/${comment.owner?.id}`)}
          >
            <Typography>{comment.owner?.full_name}</Typography>
          </Link>
        }
        subheader={`#${comment?.id} ${dayjs(comment?.created_at).format(
          "MMM DD YYYY, hh:mm A"
        )}`}
        subheaderTypographyProps={{ fontSize: 12 }}
        action={
          <IconButton onClick={() => onDelete(comment)}>
            <DeleteOutline fontSize="small" />
          </IconButton>
        }
      />
      <CardContent
        sx={{
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
        }}
      >
        {decode(comment.comment)}
      </CardContent>
      {(comment?.documents?.length || 0) > 0 && (
        <CardContent>
          <Typography variant="body2">Attachments:</Typography>
          <ul>
            {comment?.documents?.map((doc) => (
              <li>
                <Typography variant="caption">
                  <a href={doc.original_url} target="_blank">
                    {doc.file_name}
                  </a>
                </Typography>
              </li>
            ))}
          </ul>
        </CardContent>
      )}
      {(comment.childs?.length || 0) > 0 && (
        <CardContent>
          {comment.childs?.map((child) => (
            <CardComment comment={child} onDelete={() => onDelete(child)} />
          ))}
        </CardContent>
      )}
    </Card>
  );
};

const SocialFeedDetailPage = () => {
  const { threadId } = useParams();
  const navigate = useNavigate();

  const {
    data: thread,
    isLoading,
    isFetching,
  } = useGetForumThreadByIdQuery(parseInt(threadId!), {
    skip: !threadId,
  });

  const {
    data: comments,
    isLoading: loadingComments,
    isFetching: fetchingComments,
  } = useGetForumThreadCommentsQuery(
    { thread_id: thread?.id! },
    {
      skip: !thread,
    }
  );

  const [doDelete, { isLoading: deleting }] = useDeleteThreadCommentMutation();
  const [doLock, { isLoading: locking }] = useLockForumThreadMutation();
  const [doPin, { isLoading: pinning }] = usePinForumThreadMutation();

  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    row: ThreadComment | null;
  }>({
    open: false,
    row: null,
  });

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    if (deleteModal.row?.id) {
      try {
        await doDelete({
          thread_id: parseInt(threadId!),
          comment_id: deleteModal.row.id,
        }).unwrap();
        closeDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeDeleteModal();
      }
    }
  };

  useTitle(`Feed #${thread?.id}`, `${thread?.title} | Feed`);

  return (
    <Box>
      {(isLoading || isFetching) && <LinearProgress />}
      <Box sx={{ bgcolor: "background.paper", width: "auto" }} boxShadow={1}>
        <Card>
          <CardHeader
            title={decode(thread?.title) || ""}
            titleTypographyProps={{ fontSize: 20 }}
            subheader={
              <Stack direction={"row"}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => navigate(`/talents/${thread?.user?.id}`)}
                >
                  <Typography>{thread?.user?.full_name}</Typography>
                </Link>
                <Typography>
                  ‣ {dayjs(thread?.created_at).format("MMM DD YYYY, hh:mm A")} ‣
                  {thread?.total_view || 0} views ‣ {thread?.total_likes || 0}{" "}
                  likes ‣ {thread?.total_comments || 0} comment(s)
                </Typography>
              </Stack>
            }
            subheader3={`${(
              <Link
                component="button"
                variant="body2"
                onClick={() => navigate(`/talents/${thread?.user?.id}`)}
              >
                <Typography>{thread?.user?.full_name}</Typography>
              </Link>
            )}  ‣ ${dayjs(thread?.created_at).format(
              "MMM DD YYYY, hh:mm A"
            )} ‣ ${thread?.total_view || 0} views ‣ ${
              thread?.total_likes || 0
            } likes ‣ ${thread?.total_comments || 0} comment(s)`}
            subheaderTypographyProps={{ fontSize: 14 }}
          />
          <CardContent>
            <Box
              sx={{
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
              }}
            >
              {decode(thread?.body) || ""}
            </Box>
            <Typography variant="caption">
              Tags: {thread?.tags || ""}
            </Typography>
          </CardContent>
          {(thread?.documents?.length || 0) > 0 && (
            <CardContent>
              <Typography variant="body2">Attachments:</Typography>
              <ul>
                {thread?.documents?.map((doc) => (
                  <li>
                    <Typography variant="caption">
                      <a href={doc.original_url} target="_blank">
                        {doc.file_name}
                      </a>
                    </Typography>
                  </li>
                ))}
              </ul>
            </CardContent>
          )}
          <CardActions>
            <LoadingButton
              size="small"
              startIcon={<LockOutlined />}
              variant={thread?.is_locked ? "contained" : "outlined"}
              loading={locking}
              onClick={() =>
                doLock({ thread_id: thread?.id!, lock: !thread?.is_locked })
              }
            >
              {thread?.is_locked ? "Un-Lock Thread" : "Lock Thread"}
            </LoadingButton>
            <LoadingButton
              size="small"
              startIcon={<PushPinOutlined />}
              variant={thread?.is_pinned ? "contained" : "outlined"}
              loading={pinning}
              onClick={() =>
                doPin({ thread_id: thread?.id!, pin: !thread?.is_pinned })
              }
            >
              {thread?.is_pinned ? "Un-Pin Thread" : "Pin Thread"}
            </LoadingButton>
          </CardActions>
          <CardContent>
            {(loadingComments || fetchingComments) && <LinearProgress />}
            <Typography variant="body1">Comment(s):</Typography>
            {comments?.map((comment) => (
              <CardComment
                comment={comment}
                onDelete={(data: ThreadComment) =>
                  setDeleteModal({ open: true, row: data })
                }
              />
            ))}
          </CardContent>
        </Card>
        <Divider />
      </Box>
      <ConfirmDeleteModal
        open={deleteModal.open}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
        message={`Delete comment #${deleteModal.row?.id} by ${deleteModal.row?.owner?.full_name} ?`}
      />
    </Box>
  );
};
export default SocialFeedDetailPage;
