import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SelectCertification from "../../components/SelectCertification";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { UserEducation } from "../../types/education";
import {
  useAddUserEducationMutation,
  useUpdateUserEducationMutation,
} from "../../services/talent.service";
import dayjs from "dayjs";
import { toast } from "react-toastify";

interface ModalUserEducationFormProps {
  open: boolean;
  onClose: () => void;
  userId: number;
  data: UserEducation | null;
}

const ModalUserEducationForm = ({
  open,
  onClose,
  userId,
  data,
}: ModalUserEducationFormProps) => {
  const [add, { isLoading: adding }] = useAddUserEducationMutation();
  const [edit, { isLoading: editing }] = useUpdateUserEducationMutation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      payload.user_id = userId;

      if (!!payload.start_date)
        payload.start_date = dayjs(payload.start_date).format("YYYY-MM-DD");
      if (!!payload.end_date)
        payload.end_date = dayjs(payload.end_date).format("YYYY-MM-DD");

      if (!data) {
        await add(payload).unwrap();
        toast.success("Education added");
      } else {
        payload.id = data?.id!;
        await edit(payload).unwrap();
        toast.success("Education updated");
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("school", data?.school);
      setValue("degree", data?.degree);
      setValue("field_of_study", data?.field_of_study);
      setValue("start_date", data?.start_date);
      setValue("end_date", data?.end_date);
    }
  }, [data, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} User Education
        </DialogTitle>
        <DialogContent>
          <Box>
            <Controller
              name="school"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="School Name"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.school}
                  helperText={!!errors.school && "This field is required"}
                />
              )}
            />
            <Controller
              name="degree"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Degree (optional)"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              name="field_of_study"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Field Of Study (optional)"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              name="start_date"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DesktopDatePicker
                  label="Start Date"
                  inputFormat="MMM DD, YYYY"
                  value={value || null}
                  showToolbar={true}
                  onChange={onChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
            <Controller
              name="end_date"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DesktopDatePicker
                  label="End Date"
                  inputFormat="MMM DD, YYYY"
                  value={value || null}
                  showToolbar={true}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ ml: 2 }} />
                  )}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={adding || editing}
          >
            {!data ? "Add" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalUserEducationForm;
