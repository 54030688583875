import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { clearSession } from "../features/auth/authSlice";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn("rtkQueryErrorLogger", action);
      toast.error(
        !!action?.payload?.data?.message
          ? `[${action?.payload?.status}] ${action?.payload?.data?.message}`
          : action?.error?.message
      );
      if (action?.payload?.status == 401) {
        // unauthorized , clear session n logout
        api.dispatch(clearSession());
      }
    }
    return next(action);
  };
