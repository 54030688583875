import { ForumThread, ThreadComment } from "../types/forum";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "Forum-Threads";
const TAG_TYPE_FORUM_COMMENT: string = "Forum-Comments";
const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE, TAG_TYPE_FORUM_COMMENT],
});

export const forumApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getForumThreads: builder.query<
      QueryResponse<ForumThread>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
      }
    >({
      query: ({ page, pageSize, sortField, sortMode, search }) => ({
        url: "api/admin/threads",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
          withTrashed: false,
          load: "documents",
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getForumThreadById: builder.query<ForumThread, number>({
      query: (id) => ({
        url: `api/admin/threads/${id}`,
        method: "GET",
        params: {
          load: "user,documents",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    editForumThread: builder.mutation<
      ForumThread,
      Partial<ForumThread> & Pick<ForumThread, "id">
    >({
      query: (body) => {
        return {
          url: `api/admin/threads/${body.id}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteForumThread: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/threads/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    getForumThreadComments: builder.query<
      Array<ThreadComment>,
      { thread_id: number; sortField?: string; sortMode?: string | null }
    >({
      query: ({ thread_id, sortField, sortMode }) => ({
        url: `api/threads/${thread_id}/comments`,
        method: "GET",
        params: {
          load: "childs,childs.owner,documents,childs.documents",
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
        },
      }),
      providesTags: (result, error, params) => {
        return [{ type: TAG_TYPE_FORUM_COMMENT, id: params.thread_id }];
      },
    }),
    deleteThreadComment: builder.mutation<
      void,
      { thread_id: number; comment_id: number }
    >({
      query: ({ thread_id, comment_id }) => ({
        url: `api/admin/threads/${comment_id}/comments`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE, id: params.thread_id },
        { type: TAG_TYPE_FORUM_COMMENT, id: params.thread_id },
      ],
    }),
    lockForumThread: builder.mutation<
      void,
      { thread_id: number; lock: boolean }
    >({
      query: ({ thread_id, lock }) => ({
        url: lock
          ? `api/admin/threads/${thread_id}/lock`
          : `api/admin/threads/${thread_id}/unlock`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, params) => [
        { type: TAG_TYPE, id: params.thread_id },
        // { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    pinForumThread: builder.mutation<void, { thread_id: number; pin: boolean }>(
      {
        query: ({ thread_id, pin }) => ({
          url: pin
            ? `api/admin/threads/${thread_id}/pinned`
            : `api/admin/threads/${thread_id}/unpinned`,
          method: "PUT",
        }),
        invalidatesTags: (result, error, params) => [
          { type: TAG_TYPE, id: params.thread_id },
          // { type: TAG_TYPE, id: "PARTIAL-LIST" },
        ],
      }
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetForumThreadsQuery,
  useGetForumThreadByIdQuery,
  useEditForumThreadMutation,
  useDeleteForumThreadMutation,
  useLockForumThreadMutation,
  usePinForumThreadMutation,
  //
  useGetForumThreadCommentsQuery,
  useDeleteThreadCommentMutation,
} = forumApi;
