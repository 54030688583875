import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridSortModel,
  GridRowClassNameParams,
  GridValueGetterParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
import AddIcon from "@mui/icons-material/Add";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { useNavigate } from "react-router-dom";
import { useGetBalanceHistoriesQuery } from "../../services/finance.service";
import { BalanceHistory, ManualDeposit } from "../../types/finance";
import dayjs from "dayjs";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import ModalManualDepositForm from "./ModalManualDepositForm";
import { formatMoney } from "../../utils/helper";

const TableToolbar = ({ onSearch }: { onSearch: (query?: string) => void }) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <GridToolbarExport
          sx={{ ml: 2 }}
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Finances" }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const FinancesPage = () => {
  useTitle("Finance Activities");
  const navigate = useNavigate();
  const user = useCurrentUser();

  const [formModal, setFormModal] = React.useState<{
    open: boolean;
    data: ManualDeposit | null;
  }>({
    open: false,
    data: null,
  });

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const { data, isLoading, isFetching } = useGetBalanceHistoriesQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "created_at",
        headerName: "Time",
        minWidth: 200,
        sortingOrder: ["desc", "asc", null],
        sortable: true,
        filterable: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, BalanceHistory>
        ) => `${dayjs(params.row?.created_at).format("MMM DD, YYYY hh:mm A")}`,
      },
      {
        field: "name",
        headerName: "Name",
        minWidth: 250,
        // sortable: true,
        // filterable: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, BalanceHistory>
        ) => {
          if (params.row?.model) {
            return params.row?.model_type === "company"
              ? `${params.row?.model?.name || ""}`
              : `${params.row?.model?.first_name || ""} ${
                  params.row?.model?.last_name || ""
                }`;
          }

          return `${params.row?.model_type}#${params.row?.model_id}`;
        },
      },
      // {
      //   field: "model_type",
      //   headerName: "Type",
      //   minWidth: 200,
      //   sortable: true,
      //   filterable: true,
      //   valueGetter: (
      //     params: GridValueGetterParams<undefined, BalanceHistory>
      //   ) => `${params.row?.model_type}`,
      // },
      {
        field: "tx_notes",
        headerName: "Notes",
        flex: 1,
        minWidth: 300,
        sortable: false,
        filterable: false,
        renderCell: (
          params: GridRenderCellParams<undefined, BalanceHistory>
        ) => (
          <Box>
            <Typography variant="body2">{params.row.notes}</Typography>
            {!!params.row.user_comment && (
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                Reason: {params.row.user_comment}
              </Typography>
            )}
          </Box>
        ),
        disableExport: true,
      },
      {
        field: "notes",
        headerName: "Notes",
        hide: true,
      },
      {
        field: "debit",
        headerName: "Debit",
        headerAlign: "center",
        minWidth: 130,
        align: "right",
        type: "number",
        sortable: false,
        filterable: false,
        valueGetter: (
          params: GridValueGetterParams<undefined, BalanceHistory>
        ) => `${formatMoney(params.row.debit)}`,
      },
      {
        field: "credit",
        headerName: "Credit",
        headerAlign: "center",
        minWidth: 130,
        align: "right",
        type: "number",
        sortable: false,
        filterable: false,
        valueGetter: (
          params: GridValueGetterParams<undefined, BalanceHistory>
        ) => `${formatMoney(params.row.credit)}`,
      },
      {
        field: "balance",
        headerName: "Balance",
        headerAlign: "center",
        minWidth: 130,
        //flex: 1,
        type: "number",
        sortable: true,
        filterable: true,
        align: "right",
      },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    setFormModal({ open: true, data: null });
  }, []);

  const CustomToolbar = React.useMemo(
    () => () => <TableToolbar onSearch={handleSearch} />,
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  return (
    <Box>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card sx={{}}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 16, fontWeight: "500", letterSpacing: 0.5 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Manual Deposits
                </Typography>
                {/* <Typography variant="h5" component="div">
                  100
                </Typography> */}
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button onClick={() => navigate("/finances/manual-deposits")}>
                  Browse
                </Button>
                <Button variant="outlined" onClick={handleCreate}>
                  <AddIcon /> Add Manual Deposit
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Paper sx={{ height: "70vh", mt: 2 }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[25, 50, 100]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          // disableColumnFilter
          // rowHeight={42}
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
          getRowClassName={getRowClassName}
          rowHeight={65}
        />
        {/* <TopupModalForm
          open={topupFormModal.open}
          onClose={() => setTopupFormModal({ open: false })}
        /> */}
        <ModalManualDepositForm
          open={formModal.open}
          onClose={() => setFormModal({ open: false, data: null })}
          data={formModal.data}
        />
      </Paper>
    </Box>
  );
};
export default FinancesPage;
