import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Navigate, Outlet } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import workz360 from "../images/workz360.png";
import manage360 from "../images/manage360.png";
import Navbar from "./Navbar";
import { useCurrentUser } from "../hooks/useCurrentUser";
import SiteBreadcrumb from "./SiteBreadcrumb";
import CornerRibbon from "./CornerRibbon";
import { colors } from "@mui/material";
import { isManage360, isProductionMode } from "../utils/helper";

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const loading = () => <div className="text-center">Loading...</div>;

const ProtectedPageLayout = () => {
  const user = useCurrentUser();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const isProduction = React.useMemo(() => isProductionMode(), []);
  const logo = React.useMemo(() => (isManage360() ? manage360 : workz360), []);

  if (!user) return <Navigate to="/login" replace />;

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar open={open} onToggle={toggleDrawer} />
      <Drawer variant="permanent" open={open}>
        {!isProduction && (
          <CornerRibbon
            backgroundColor={colors.yellow[200]}
            fontColor="#333"
            position="top-left"
          >
            STAGING
          </CornerRibbon>
        )}
        <Toolbar
          sx={{
            display: "flex",
            // alignItems: "center",
            // justifyContent: "flex-end",
            px: [1],
          }}
        >
          <img src={logo} height={36} />
          {/* <Typography sx={{ flex: 1, mt: 1, ml: 0.5 }}>Admin</Typography> */}
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        {/* <Divider /> */}
        <SidebarMenu />
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        {/* toolbar = extra padding */}
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
          <SiteBreadcrumb />
          <React.Suspense fallback={loading()}>
            <Outlet />
          </React.Suspense>
        </Container>
      </Box>
    </Box>
  );
};
export default ProtectedPageLayout;
