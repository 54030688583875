import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
  GridValueGetterParams,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  useDeleteSkillMutation,
  useGetSkillsQuery,
  useRestoreSkillMutation,
} from "../../services/skill.service";
import LinearProgress from "@mui/material/LinearProgress";
import { Stack, Paper, Button, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { toast } from "react-toastify";
import ModalSkillForm from "./ModalSkillForm";
import AddIcon from "@mui/icons-material/Add";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { Skill } from "../../types/skill";
import { useNavigate } from "react-router-dom";
import { isManage360 } from "../../utils/helper";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <Button variant="outlined" onClick={onCreate}>
          <AddIcon /> Add Skill
        </Button>
        <GridToolbarExport
          sx={{ ml: 2 }}
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Skills" }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const SkillsPage = () => {
  useTitle("Skills");
  const navigate = useNavigate();
  const _isManage360 = React.useMemo(() => isManage360(), []);

  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    row: GridRowModel<Skill> | null;
  }>({
    open: false,
    row: null,
  });
  const [formModal, setFormModal] = React.useState<{
    open: boolean;
    data: Skill | null;
  }>({
    open: false,
    data: null,
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const { data, isLoading, isFetching } = useGetSkillsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
    withTrashed: true,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const [doDelete, { isLoading: deleting }] = useDeleteSkillMutation();

  const handleDeleteRow = (row: GridRowModel<Skill>) => {
    setDeleteModal({ open: true, row });
  };
  const handleEditRow = (row: GridRowModel<Skill>) => {
    setFormModal({ open: true, data: row });
  };
  const [doRestore, { isLoading: restoring }] = useRestoreSkillMutation();

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () =>
      [
        {
          field: "name",
          headerName: "Skill Name",
          minWidth: 400,
          sortingOrder: ["desc", "asc", null],
          filterable: true,
        },
        {
          field: "category.name",
          headerName: "Category",
          flex: 1,
          sortable: true,
          filterable: false,
          valueGetter: (params: GridValueGetterParams<undefined, Skill>) =>
            params.row.category?.name,
        },
        {
          field: "min_price",
          headerName: "Price per Hour ($)",
          flex: 1,
          sortable: true,
          filterable: false,
          valueGetter: (params: GridValueGetterParams<undefined, Skill>) =>
            `${params.row.min_price || ""} - ${params.row.max_price || ""}`,
        },
        {
          field: "control",
          headerName: "",
          sortable: false,
          filterable: false,
          renderCell: (params: GridRenderCellParams<undefined, Skill>) =>
            !!params.row.deleted_at ? (
              <Button
                variant="text"
                color="secondary"
                onClick={() => handleRestoreRow(params.row)}
              >
                Restore
              </Button>
            ) : (
              <Stack direction={"row"}>
                <IconButton onClick={() => handleEditRow(params.row)}>
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={() => handleDeleteRow(params.row)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Stack>
            ),
          disableExport: true,
        },
      ].filter((it) => {
        if (_isManage360) {
          return !["min_price"].includes(it.field);
        } else return true;
      }) as GridColDef[],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    setFormModal({ open: true, data: null });
  }, []);

  const handleRestoreRow = async (row: GridRowModel<Skill>) => {
    try {
      await doRestore(row.id).unwrap();
      toast.success("Data restored");
    } catch (err) {}
  };

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    if (deleteModal.row?.id) {
      try {
        await doDelete(deleteModal.row.id).unwrap();
        closeDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeDeleteModal();
      }
    }
  };

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  return (
    <Box>
      <Stack direction={"row-reverse"}>
        <Button variant="text" onClick={() => navigate("/skill-categories")}>
          Manage Skill Categories →
        </Button>
      </Stack>
      <Paper style={{ height: "80vh" }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[25, 50, 100]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnFilter
          // rowHeight={42}
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
            "& .deleted": {
              fontStyle: "italic",
              color: "#aaa",
            },
          }}
          getRowClassName={getRowClassName}
        />
        <ConfirmDeleteModal
          open={deleteModal.open}
          onClose={closeDeleteModal}
          onDelete={handleDelete}
          message={`Delete skill '${deleteModal.row?.name}' ?`}
        />
        <ModalSkillForm
          open={formModal.open}
          onClose={() => setFormModal({ open: false, data: null })}
          data={formModal.data}
        />
      </Paper>
    </Box>
  );
};
export default SkillsPage;
