import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { EmailTemplate } from "../../types/emailTemplate";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetEmailTemplateByIdQuery,
  useEditEmailTemplateMutation,
} from "../../services/email-template.service";
import { toast } from "react-toastify";

const typeOptions = [
  { value: "{first_name}", label: "{first_name}" },
  { value: "{last_name}", label: "{last_name}" },
  { value: "{email}", label: "{email}" },
  { value: "{project_name}", label: "{project_name}" },
  { value: "{company_name}", label: "{company_name}" },
  { value: "{rate_per_hour}", label: "{rate_per_hour}" },
  { value: "{max_hour}", label: "{max_hour}" },
  { value: "{fixed_price}", label: "{fixed_price}" },
  { value: "{app_name}", label: "{app_name}" },
  { value: "{logo}", label: "{logo}" },
];

interface EmailTemplateEditPageProps {
  data: EmailTemplate | null;
}

const EmailTemplateEditPage = ({ data }: EmailTemplateEditPageProps) => {
  const {
    data: emailTemplate,
    isLoading,
    isFetching,
  } = useGetEmailTemplateByIdQuery(data?.id!, {
    skip: !data?.id,
  });

  const [cur, setCur] = React.useState<number>(0);

  const [edit, { isLoading: editing }] = useEditEmailTemplateMutation();

  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    setFocus,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (data) {
        payload.id = data?.id!;
        await edit(payload).unwrap();
        clearErrors();
        toast.success(`Template '${data?.title}' updated`);
      }
    } catch (err) {}
  };

  React.useEffect(() => {
    if (emailTemplate) {
      setValue("subject", emailTemplate?.subject || "");
      setValue("template", emailTemplate?.template || "");
    }

    clearErrors();
  }, [emailTemplate]);

  const handleInsertTextOnTemplate = (option: string) => {
    const currentStr = getValues("template");

    setValue(
      "template",
      currentStr.slice(0, cur) + option + currentStr.slice(cur)
    );
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Card sx={{ mt: 1 }}>
            <CardHeader title={data?.title} />
            <CardContent>
              {(isLoading || isFetching) && <CircularProgress size={16} />}
              <Controller
                name="subject"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label="Subject"
                    value={value}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.subject}
                    helperText={!!errors.subject && "This field is required"}
                  />
                )}
              />
              {typeOptions.map((item) => (
                <Button
                  onClick={() => {
                    setTimeout(() => {
                      handleInsertTextOnTemplate(`${item.value}`);
                      setFocus("template");
                    }, 100);
                  }}
                  style={{ textTransform: "none" }}
                >{`${item.label}`}</Button>
              ))}
              <Controller
                name="template"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label="Template"
                    value={value}
                    fullWidth
                    minRows={5}
                    onChange={(e) => {
                      setCur(e.target.selectionStart || 0);
                      //   // gives cursor index
                      //   // this only shows cursor position if user types
                      //   // I need to track the position of the cursor and add dropVal there
                      setValue("template", e.target.value, {
                        shouldValidate: true,
                      });

                      //   onChange();
                      setCur(e.target.selectionStart || 0);
                      onChange(e);
                    }}
                    onBlur={(e) => {
                      setCur(e.target.selectionStart || 0);
                      onBlur();
                    }}
                    multiline
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.template}
                    helperText={!!errors.template && "This field is required"}
                  />
                )}
              />
            </CardContent>
          </Card>
        </Box>
        <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 2 }}>
          <LoadingButton type="submit" variant="contained" loading={editing}>
            Update Template
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  );
};
export default EmailTemplateEditPage;
