import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Talent } from "../../types/users";
import { useNavigate } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import { nl2br } from "../../utils/helper";
import Edit from "@mui/icons-material/Edit";

const TalentProfilePanel = ({ data }: { data?: Talent }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={1}>
      <Grid item md={8} sm={12}>
        <Card>
          <CardHeader title="About" />
          <CardContent>{nl2br(data?.bio || "n/a")}</CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Skills" />
          <CardContent>
            {data?.skills?.length == 0 ? (
              <Typography variant="body2" color="gray">
                No skills added
              </Typography>
            ) : (
              data?.skills?.map((it) => (
                <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
              ))
            )}
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Industries" />
          <CardContent>
            {data?.business_verticals?.length == 0 ? (
              <Typography variant="body2" color="gray">
                No industries added
              </Typography>
            ) : (
              data?.business_verticals?.map((it) => (
                <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
              ))
            )}
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Languages" />
          <CardContent>
            {data?.languages?.length == 0 ? (
              <Typography variant="body2" color="gray">
                No languages added
              </Typography>
            ) : (
              data?.languages?.map((it) => (
                <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
              ))
            )}
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Certificates & Licenses" />
          <CardContent>
            <List>
              {data?.certifications?.length == 0 ? (
                <Typography variant="body2" color="gray">
                  No certificates added
                </Typography>
              ) : (
                data?.certifications?.map((it) => (
                  <ListItem key={it.id}>
                    <ListItemIcon>
                      <SubtitlesOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${it.certification?.name} (${it.certification?.provider})`}
                      secondary={`Issued: ${it.issue_date}`}
                    />
                  </ListItem>
                ))
              )}
            </List>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Work Experiences" />
          <CardContent>
            <List>
              {data?.work_experiences?.length == 0 ? (
                <Typography variant="body2" color="gray">
                  No work experiences added
                </Typography>
              ) : (
                data?.work_experiences?.map((it) => (
                  <ListItem key={it.id}>
                    <ListItemIcon>
                      <BusinessCenterOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${it.company}`}
                      secondary={`${it.start_period_year || "?"} - ${
                        it.end_period_year || "?"
                      }`}
                    />
                  </ListItem>
                ))
              )}
            </List>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Educations" />
          <CardContent>
            <List>
              {data?.educations?.length == 0 ? (
                <Typography variant="body2" color="gray">
                  No educations added
                </Typography>
              ) : (
                data?.educations?.map((it) => (
                  <ListItem key={it.id}>
                    <ListItemIcon>
                      <SchoolOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${it.school}`}
                      secondary={`${it.field_of_study || ""}`}
                    />
                  </ListItem>
                ))
              )}
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12} md={4}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          startIcon={<Edit />}
          disabled={!data}
          onClick={() => navigate(`/talents/${data?.id}/edit`)}
        >
          Edit Profile
        </Button>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Contact" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <TableBody>
                <TableRow>
                  <TableCell width="50%">Address 1</TableCell>
                  <TableCell>{data?.physical_address_1 || "n/a"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Address 2</TableCell>
                  <TableCell>{data?.physical_address_2 || ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>City / Zip</TableCell>
                  <TableCell>
                    {data?.city} / {data?.zip}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>State</TableCell>
                  <TableCell>{data?.state || "?"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>{data?.country_id || "?"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Work Settings" />
          <CardContent>
            <Table sx={{ fontSize: 14 }} size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Work Status</TableCell>
                  <TableCell align="right">
                    {data?.talent?.work_status?.toUpperCase() || "?"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Rate</TableCell>
                  <TableCell align="right">
                    {data?.min_pay || "?"} - {data?.max_pay || "?"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Commute Radius</TableCell>
                  <TableCell align="right">
                    {data?.commute_radius || "?"} miles
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Background Check</TableCell>
                  <TableCell align="right">
                    {!!data?.allow_background_check ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Drug Test</TableCell>
                  <TableCell align="right">
                    {!!data?.allow_drug_test ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Valid U.S Citizen or Have a Work Visa</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.is_valid_us ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Availabilty" />
          <CardContent>
            <Table sx={{ fontSize: 14 }} size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Monday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_monday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tuesday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_tuesday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Wednesday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_wednesday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Thursday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_thursday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Friday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_friday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Saturday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_saturday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sunday</TableCell>
                  <TableCell align="right">
                    {!!data?.talent?.avail_sunday ? "✅" : "❌"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Social Medias" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <TableBody>
                <TableRow>
                  <TableCell width="50%">LinkedIn</TableCell>
                  <TableCell>{data?.linkedin || "n/a"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Facebook</TableCell>
                  <TableCell>{data?.facebook || "n/a"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Instagram</TableCell>
                  <TableCell>{data?.instagram || "n/a"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Website</TableCell>
                  <TableCell>{data?.website || "n/a"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default TalentProfilePanel;
