import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Table,
  Typography,
} from "@mui/material";
import { Company } from "../../types/company";
import { useNavigate } from "react-router-dom";
import Edit from "@mui/icons-material/Edit";
// import EditIcon from "@mui/icons-material/Edit";

const CompanyProfilePanel = ({ data }: { data?: Company }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Card sx={{ mb: 1 }}>
          <CardHeader title="About" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <tbody>
                <tr>
                  <td width={"20%"}>Name</td>
                  <td>{data?.name}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{data?.phone_number || "n/a"}</td>
                </tr>
                <tr>
                  <td>Fax</td>
                  <td>{data?.fax || "n/a"}</td>
                </tr>
              </tbody>
            </Table>
          </CardContent>
        </Card>
        <Card sx={{ mb: 1 }}>
          <CardHeader title="Setting" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <tbody>
                <tr>
                  <td width={"20%"}>Industry</td>
                  <td>{data?.business_vertical?.name}</td>
                </tr>
                <tr>
                  <td>Fixed Fee</td>
                  <td>{data?.fixed_price_fee || "0"}</td>
                </tr>
                <tr>
                  <td>Color Scheme</td>
                  <td>{data?.color_schema || "n/a"}</td>
                </tr>
              </tbody>
            </Table>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Contact Person" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <tbody>
                <tr>
                  <td width={"20%"}>Name</td>
                  <td>{data?.primary_contact_name || "n/a"}</td>
                </tr>
                <tr>
                  <td>Title</td>
                  <td>{data?.primary_contact_title || "n/a"}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{data?.primary_contact_phone_number || "n/a"}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{data?.primary_contact_email || "n/a"}</td>
                </tr>
              </tbody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={!data}
          onClick={() => navigate(`/clients/${data?.id}/edit`)}
          startIcon={<Edit />}
        >
          Edit
        </Button>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Contact" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <tbody>
                <tr>
                  <td>Address 1</td>
                  <td>{data?.headquarters_address_1}</td>
                </tr>
                <tr>
                  <td>Address 2</td>
                  <td>{data?.headquarters_address_2}</td>
                </tr>
                <tr>
                  <td>City / Zip</td>
                  <td>
                    {data?.city} / {data?.zip}
                  </td>
                </tr>
                <tr>
                  <td>State</td>
                  <td>{data?.state}</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>{data?.country_id}</td>
                </tr>
              </tbody>
            </Table>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Social Media" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <tbody>
                <tr>
                  <td>LinkedIn</td>
                  <td>{data?.linked_in || "n/a"}</td>
                </tr>
                <tr>
                  <td>Facebook</td>
                  <td>{data?.facebook || "n/a"}</td>
                </tr>
                <tr>
                  <td>Instagram</td>
                  <td>{data?.instagram || "n/a"}</td>
                </tr>
                <tr>
                  <td>Website</td>
                  <td>{data?.website || "n/a"}</td>
                </tr>
              </tbody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default CompanyProfilePanel;
