import { Delete, Upload } from "@mui/icons-material";
import { Avatar, Button, Stack, Typography } from "@mui/material";
import * as React from "react";

const UploadLogo = ({
  onChange,
  value,
}: {
  onChange: (file: File | null) => void;
  value?: string;
}) => {
  const [image, _setImage] = React.useState<string | null>(null);
  const inputFileRef = React.createRef<HTMLInputElement>();

  const cleanup = () => {
    if (!!image) URL.revokeObjectURL(image!);

    inputFileRef!.current!.value = "";
  };

  const setImage = (newImage: string | null) => {
    if (image) cleanup();

    _setImage(newImage);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newImage = event.target?.files?.[0];
    if (newImage) {
      const objectUrl = URL.createObjectURL(newImage);
      setImage(objectUrl);

      onChange(newImage);
    }
  };

  const handleClick = (event: any) => {
    if (image) {
      event.preventDefault();
      setImage(null);
    }
  };

  return (
    <Stack direction="row" alignItems="center">
      <Avatar
        variant="square"
        sx={{ bgColor: "primary.main", height: 100, width: "auto", mr: 2 }}
        src={image || value || "./broken-image.jpg"}
      />
      <input
        ref={inputFileRef}
        accept="image/*"
        hidden
        id="logo-image-upload"
        type="file"
        onChange={handleOnChange}
      />
      <label htmlFor="logo-image-upload">
        <Button
          variant="outlined"
          color="primary"
          component="span"
          onClick={handleClick}
        >
          <>
            {image ? <Delete /> : <Upload />}
            {image ? "Reset" : "Select Logo"}
          </>
        </Button>
      </label>
      {/* <Typography variant="caption" display="block" gutterBottom>
      </Typography> */}
    </Stack>
  );
};
export default UploadLogo;
