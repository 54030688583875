import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import { Reason } from "../../types/reason";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useGetReasonByIdQuery,
  useAddReasonMutation,
  useEditReasonMutation,
} from "../../services/reason.service";
import SelectSimple from "../../components/SelectSimple";

const typeOptions = [
  { value: "cancel-worker", label: "cancel-worker" },
  { value: "cancel-job", label: "cancel-job" },
  { value: "reschedule-job", label: "reschedule-job" },
  { value: "edit-job", label: "edit-job" },
  { value: "manual-debit", label: "manual-debit" },
  { value: "manual-credit", label: "manual-credit" },
  { value: "reject-work", label: "reject-work" },
];

interface ModalReasonFormProps {
  open: boolean;
  onClose: () => void;
  data: Reason | null;
}

const ModalReasonForm = ({ open, onClose, data }: ModalReasonFormProps) => {
  const { data: reason } = useGetReasonByIdQuery(data?.id!, {
    skip: !data?.id,
  });

  const [add, { isLoading: adding }] = useAddReasonMutation();
  const [edit, { isLoading: editing }] = useEditReasonMutation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!data) await add(payload).unwrap();
      else {
        payload.id = data?.id!;
        await edit(payload).unwrap();
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("name", data?.name);
      setValue("description", data?.description);
      setValue("type", data?.type || typeOptions[0].value);
    }
  }, [data, open]);

  React.useEffect(() => {
    setValue("name", reason?.name);
    setValue("description", reason?.description);
    setValue("type", reason?.type);
  }, [reason]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="xs"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} Reason
        </DialogTitle>
        <DialogContent>
          <Box>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Reason name"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.name}
                  helperText={!!errors.name && "This field is required"}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Description (optional)"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  multiline
                />
              )}
            />
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SelectSimple
                  title="Type"
                  data={typeOptions}
                  value={value}
                  sx={{ mt: 2 }}
                  onChange={onChange}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={adding || editing}
          >
            {!data ? "Create" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalReasonForm;
