import { Certification } from "../types/certification";
import { baseApi, QueryResponse } from "./base";

const TAG_TYPE: string = "Certifications";
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE] });

export const certificationApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCertifications: builder.query<
      QueryResponse<Certification>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        withTrashed?: boolean;
      }
    >({
      query: ({
        page,
        pageSize,
        sortField,
        sortMode,
        search,
        withTrashed,
      }) => ({
        url: "api/admin/certifications",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          withTrashed,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    getCertificationById: builder.query<Certification, number>({
      query: (id) => ({
        url: `api/admin/certifications/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE, id }],
    }),
    addCertification: builder.mutation<
      Certification,
      Omit<Certification, "id">
    >({
      query: (body) => ({
        url: `api/admin/certifications`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    editCertification: builder.mutation<
      Certification,
      Partial<Certification> & Pick<Certification, "id">
    >({
      query: (body) => ({
        url: `api/admin/certifications/${body.id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE, id: data.id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteCertification: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/certifications/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
    restoreCertification: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/certifications/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCertificationsQuery,
  useGetCertificationByIdQuery,
  useAddCertificationMutation,
  useEditCertificationMutation,
  useDeleteCertificationMutation,
  useRestoreCertificationMutation,
} = certificationApi;
