import * as React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useGetLanguagesQuery } from "../../services/language.service";
import { Language } from "../../types/language";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectLanguages = ({
  onChange,
  values,
}: {
  onChange: (ids?: number[]) => void;
  values: number[];
}) => {
  const { data, isLoading, isFetching } = useGetLanguagesQuery({
    sortField: "name",
    sortMode: "asc",
  });

  const initialValues: Array<Language> = React.useMemo(
    () => data?.filter((it) => values?.includes(it.id)) || [],
    [data, values]
  );

  const languages: Array<Language> = React.useMemo(() => {
    return (
      data?.filter((it) =>
        ["en", "vi", "es", "zh", "ar", "fr", "hi", "ot"].includes(it.code)
      ) || []
    );
  }, [data]);

  return (
    <Autocomplete
      multiple
      fullWidth
      options={languages || []}
      disableCloseOnSelect
      ChipProps={{ color: "secondary" }}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Languages" placeholder="Search..." />
      )}
      loading={isLoading}
      onChange={(event: any, newValue: Language[] | null) => {
        onChange(newValue?.map((it) => it.id));
      }}
      value={initialValues || []}
    />
  );
};
export default SelectLanguages;
