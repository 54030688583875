import React from "react";
import {
  Label,
  Legend,
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { formatCash } from "../../utils/helper";

const renderLabelShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text> */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${payload.name} ${formatCash(Number(value))}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${+(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

interface iChartData {
  name: string;
  value: number;
  color: string;
}

const DonutChart = ({ data = [] }: { id?: string; data?: iChartData[] }) => {
  const total = React.useMemo(
    () =>
      data.map((item) => item?.value).reduce((prev?, curr?) => prev + curr, 0),
    [data]
  );

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart width={400} height={300}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={110}
          fill="#8884d8"
          dataKey="value"
          // label={
          //   data?.total_active > 0 &&
          //   data?.total_idle > 0
          //     ? renderLabelShape
          //     : false
          // }
          labelLine={false}
          isAnimationActive={false}
          paddingAngle={1}
        >
          {data.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
          <Label
            value={`${formatCash(total)}`}
            position="center"
            fontSize={30}
          />
        </Pie>
        <Legend
          payload={data.map((item) => ({
            id: item.name,
            type: "circle",
            value: `${item.name}: ${formatCash(Number(item?.value))}`,
            color: `${item.color}`,
          }))}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
