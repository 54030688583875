import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Stack,
  Typography,
  Rating,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
// import { TabPanel } from "@mui/lab";
import CompanyProfilePanel from "./CompanyProfilePanel";
import CompanyProjectsPanel from "./CompanyProjectsPanel";
import CompanyFundsPanel from "./CompanyFundsPanel";
import CompanyAccountPanel from "./CompanyAccountPanel";
import {
  useGetCompanyByIdQuery,
  useGetCompanyTotalJobSuccessRateByIdQuery,
  useGetCompanyTotalJobByIdQuery,
  useGetCompanyTotalSpentByIdQuery,
} from "../../services/company.service";
import { Company } from "../../types/company";
import { formatCash, formatMoney } from "../../utils/helper";

const ProfileStat = ({
  label,
  value,
  isLoading,
}: {
  label: string;
  value: string;
  isLoading?: any;
}) => (
  <Box
    sx={{
      border: "1px solid #ccc",
      borderRadius: 1,
      py: 0.5,
      px: 1,
      alignItems: "center",
      mr: 1,
      bgcolor: "white",
      minWidth: 80,
    }}
  >
    {isLoading ? (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress size={16} />
      </Box>
    ) : (
      <Typography textAlign={"center"} fontSize={16}>
        {value}
      </Typography>
    )}
    <Typography textAlign={"center"} fontSize={12} color="text.secondary">
      {label}
    </Typography>
  </Box>
);

const ProfileMeta = ({
  icon,
  value,
}: {
  icon: React.ReactNode;
  value: string;
}) => (
  <Button
    variant="text"
    startIcon={icon}
    size="small"
    sx={{ mr: 2, textTransform: "unset", color: "text.secondary" }}
  >
    {value}
  </Button>
);

const ProfileHeader = ({
  data,
  dataTotalJobSuccessRate,
  dataTotalJob,
  dataTotalSpent,
  isLoadingTotalJobSuccessRate,
  isLoadingTotalJob,
  isLoadingTotalSpent,
}: {
  data?: Company;
  dataTotalJobSuccessRate: any;
  dataTotalJob: any;
  dataTotalSpent: any;
  isLoadingTotalJobSuccessRate: any;
  isLoadingTotalJob: any;
  isLoadingTotalSpent: any;
}) => (
  <Stack direction={"row"} alignItems="center">
    {/* <Avatar
      variant="square"
      sx={{ bgColor: "primary.main", width: 100, height: 100, mr: 2 }}
      // alt={data?.first_name}
      src={
        (!!data?.photo?.length && data?.photo[0].original_url) ||
        "broken-image.jpg"
      }
    /> */}
    <Stack direction="column">
      <Stack direction="row" alignItems="center">
        <Typography variant="h5">{data?.name}</Typography>
        <Rating value={data?.total_rating || 0} readOnly sx={{ ml: 2 }} />
      </Stack>
      <Stack direction="row" alignItems="center">
        <ProfileMeta
          icon={<PersonPinCircleIcon />}
          value={`${data?.city || "Unknown City"}, ${
            data?.state || "Unknown State"
          }`}
        />
        {/* <ProfileMeta
          icon={<EmailIcon />}
          value={`${data?.email || "No Email"}
          `}
        /> */}
        <ProfileMeta
          icon={<PhoneAndroidIcon />}
          value={`${data?.phone_number || "No Phone"}`}
          
        />
      </Stack>
      <Stack direction="row" sx={{ mt: 1 }}>
        <ProfileStat
          label="Balance"
          value={
            !!data?.balance_decrypt
              ? `${formatMoney(data?.balance_decrypt || 0)}`
              : `$0`
          }
        />
        <ProfileStat
          label="Rating"
          value={`${+Number(data?.total_rating || 0).toFixed(1)}`}
        />
        <ProfileStat
          label="Job Success"
          value={`${+Number(dataTotalJobSuccessRate?.total || 0).toFixed(2)}%`}
          isLoading={isLoadingTotalJobSuccessRate}
        />
        <ProfileStat
          label="Jobs"
          value={`${dataTotalJob?.total || 0}`}
          isLoading={isLoadingTotalJob}
        />
        <ProfileStat
          label="Total Spent"
          value={`$${formatCash(Number(dataTotalSpent?.total || 0))}`}
          isLoading={isLoadingTotalSpent}
        />
      </Stack>
    </Stack>
  </Stack>
);

const CompanyDetailPage = () => {
  const { companyId } = useParams();
  const [tab, setTab] = React.useState("profile");

  const { data } = useGetCompanyByIdQuery(parseInt(companyId!), {
    skip: !companyId,
  });

  const {
    data: dataTotalJobSuccessRate,
    isLoading: isLoadingTotalJobSuccessRate,
  } = useGetCompanyTotalJobSuccessRateByIdQuery(parseInt(companyId!), {
    skip: !companyId,
  });

  const { data: dataTotalJob, isLoading: isLoadingTotalJob } =
    useGetCompanyTotalJobByIdQuery(parseInt(companyId!), {
      skip: !companyId,
    });

  const { data: dataTotalSpent, isLoading: isLoadingTotalSpent } =
    useGetCompanyTotalSpentByIdQuery(parseInt(companyId!), {
      skip: !companyId,
    });

  useTitle(`Company #${data?.id}`, `${data?.name} | Company`);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Box>
      <ProfileHeader
        data={data}
        dataTotalJobSuccessRate={dataTotalJobSuccessRate}
        dataTotalJob={dataTotalJob}
        dataTotalSpent={dataTotalSpent}
        isLoadingTotalJobSuccessRate={isLoadingTotalJobSuccessRate}
        isLoadingTotalJob={isLoadingTotalJob}
        isLoadingTotalSpent={isLoadingTotalSpent}
      />
      <Box sx={{ mt: 2 }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Overview" value={"profile"} />
          <Tab label="Jobs" value={"jobs"} />
          <Tab label="Finances" value={"finances"} />
          <Tab label="Managers" value={"account"} />
        </Tabs>
      </Box>
      <Box sx={{ mt: 1 }}>
        {tab == "profile" && <CompanyProfilePanel data={data} />}
        {tab == "jobs" && <CompanyProjectsPanel data={data} />}
        {tab == "finances" && <CompanyFundsPanel data={data} />}
        {tab == "account" && <CompanyAccountPanel data={data} />}
      </Box>
    </Box>
  );
};
export default CompanyDetailPage;
