import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useGetSurveyAnswerQuery } from "../../services/report.service";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

interface BarChartSurveyAnswerProps {
  id?: string;
}

const BarChartSurveyAnswer = (props: BarChartSurveyAnswerProps) => {
  const { id } = props;
  const { data, isLoading, isFetching } = useGetSurveyAnswerQuery();

  const sorted = React.useMemo(() => {
    const _data = data?.data;
    if (!_data) return [];
    const sorted = [..._data].sort((a, b) => b.total - a.total);
    return sorted || [];
  }, [data]);

  if (isLoading)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );

  if (!data?.data) return <Box sx={{ textAlign: "center" }}>Error</Box>;

  return (
    <Box>
      {sorted.length > 0 && (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            barGap={20}
            layout="vertical"
            width={500}
            height={300}
            data={sorted}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 60,
            }}
          >
            <XAxis type="number" hide={true} />
            <YAxis dataKey="survey_answer" type="category" />
            <Tooltip />
            <Bar dataKey="total" fill="#8884d8" label={{ position: "right" }}>
              {sorted.map((it, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export default BarChartSurveyAnswer;
