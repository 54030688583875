import * as React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import ErrorNotFound from "./components/ErrorNotFound";
import ProtectedPageLayout from "./components/ProtectedPageLayout";
import LoginPage from "./features/auth/LoginPage";
import DashboardPage from "./features/dashboard/DashboardPage";
import { useAppSelector } from "./hooks/store";
import { useLoadUserQuery } from "./services/auth.service";
import CircularProgress from "@mui/material/CircularProgress";
import BusinessVerticalsPage from "./features/vertical/BusinessVerticalsPage";
import CertificationsPage from "./features/certification/CertificationsPage";
import SkillsPage from "./features/skill/SkillsPage";
import SkillCategoriesPage from "./features/skill/SkillCategoriesPage";
import TalentsPage from "./features/talent/TalentsPage";
import TalentDetailPage from "./features/talent/TalentDetailPage";
import TalentEditPage from "./features/talent/TalentEditPage";
import ProjectsPage from "./features/project/ProjectsPage";
import ProjectDetailPage from "./features/project/ProjectDetailPage";
import FinancesPage from "./features/finance/FinancesPage";
import ManualDepositsPage from "./features/finance/ManualDepositsPage";
import CompaniesPage from "./features/company/CompaniesPage";
import CompanyEditPage from "./features/company/CompanyEditPage";
import CompanyDetailPage from "./features/company/CompanyDetailPage";
import AdminsPage from "./features/admins/AdminsPage";
import AccountPage from "./features/account/AccountPage";
import ReasonsPage from "./features/reason/ReasonsPage";
import WithdrawalsPage from "./features/withdrawal/WithdrawalsPage";
import AppUpdatesPage from "./features/app-update/AppUpdatesPage";
import EmailTemplatesPage from "./features/email-template/EmailTemplatesPage";
import { useCurrentUser } from "./hooks/useCurrentUser";
import { isManage360, isProductionMode } from "./utils/helper";
import CornerRibbon from "./components/CornerRibbon";
import { colors } from "@mui/material";
import TalentsMapPage from "./features/talent/TalentsMapPage";
import TalentReviewsPage from "./features/talent/TalentReviewsPage";
import FinanceReportPage from "./features/report/FinanceReportPage";
import UserFeedbacksPage from "./features/social/UserFeedbacksPage";
import ResourcesPage from "./features/social/ResourcesPage";
import SocialFeedsPage from "./features/social/SocialFeedsPage";
import SocialFeedDetailPage from "./features/social/SocialFeedDetailPage";

const Loader = () => {
  // const navigate = useNavigate();
  const token = useAppSelector((state) => state.auth.token);
  const { isLoading, refetch } = useLoadUserQuery(undefined, {
    skip: !token,
  });

  React.useEffect(() => {
    if (!!token) {
      refetch();
    }
  }, [token]);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  return <Outlet />;
};

const PublicLayout = () => {
  const user = useCurrentUser();
  const isProduction = React.useMemo(() => isProductionMode(), []);
  if (!!user) return <Navigate to="/dashboard" replace />;
  return (
    <div>
      {!isProduction && (
        <CornerRibbon backgroundColor={colors.yellow[200]} fontColor="#333">
          STAGING
        </CornerRibbon>
      )}
      <Outlet />
    </div>
  );
};

const IndexPage = () => {
  return <Navigate to="/login" />;
};

const AppRoutes = () => {
  const _isManage360 = React.useMemo(() => isManage360(), []);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Loader />}>
          <Route path="/" element={<IndexPage />} />
          <Route element={<PublicLayout />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
          <Route element={<ProtectedPageLayout />}>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/clients">
              <Route index element={<CompaniesPage />} />
              {/* <Route path="create" element={<CompanyEditPage />} /> */}
              <Route path=":companyId" element={<CompanyDetailPage />} />
              <Route path=":companyId/edit" element={<CompanyEditPage />} />
            </Route>
            <Route path="/talents">
              <Route index element={<TalentsPage />} />
              {/* <Route path="create" element={<TalentEditPage />} /> */}
              <Route path="map" element={<TalentsMapPage />} />
              <Route path=":talentId" element={<TalentDetailPage />} />
              <Route path=":talentId/edit" element={<TalentEditPage />} />
              <Route path=":talentId/reviews" element={<TalentReviewsPage />} />
            </Route>
            <Route path="/jobs">
              <Route index element={<ProjectsPage />} />
              <Route path=":projectId" element={<ProjectDetailPage />} />
            </Route>
            {!_isManage360 && (
              <>
                <Route path="/withdrawals">
                  <Route index element={<WithdrawalsPage />} />
                  {/* <Route path="manual-deposits" element={<ManualDepositsPage />} /> */}
                  {/* <Route path=":talentId" element={<TalentDetailPage />} /> */}
                  {/* <Route path=":talentId/edit" element={<TalentEditPage />} /> */}
                </Route>
                <Route path="/finances">
                  <Route index element={<FinancesPage />} />
                  <Route
                    path="manual-deposits"
                    element={<ManualDepositsPage />}
                  />
                  {/* <Route path=":talentId" element={<TalentDetailPage />} /> */}
                  {/* <Route path=":talentId/edit" element={<TalentEditPage />} /> */}
                </Route>
                <Route path="/reports/finance-report">
                  <Route index element={<FinanceReportPage />} />
                </Route>
              </>
            )}
            <Route path="/admins">
              <Route index element={<AdminsPage />} />
              {/* <Route path="create" element={<TalentEditPage />} />
              <Route path=":talentId" element={<TalentDetailPage />} />
              <Route path=":talentId/edit" element={<TalentEditPage />} /> */}
            </Route>
            <Route path="/app-updates">
              <Route index element={<AppUpdatesPage />} />
            </Route>
            <Route path="/account">
              <Route index element={<AccountPage />} />
            </Route>
            <Route
              path="/business-verticals"
              element={<BusinessVerticalsPage />}
            />
            <Route path="/skills" element={<SkillsPage />} />
            <Route path="/skill-categories" element={<SkillCategoriesPage />} />
            <Route path="/certifications" element={<CertificationsPage />} />
            <Route path="/reasons" element={<ReasonsPage />} />
            <Route path="/email-templates" element={<EmailTemplatesPage />} />
            <Route path="/social">
              <Route index element={<p>Please select submenu</p>} />
              <Route path="feeds" element={<SocialFeedsPage />} />
              <Route
                path="feeds/:threadId"
                element={<SocialFeedDetailPage />}
              />
              <Route path="resources" element={<ResourcesPage />} />
              <Route path="user-feedbacks" element={<UserFeedbacksPage />} />
            </Route>
          </Route>
          <Route path="*" element={<ErrorNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default AppRoutes;
