import { ProjectResource, ReportProgress } from "../types/project";

export function getLastProgress(
  job: ProjectResource
): ReportProgress | undefined {
  const sorted = job.report_progresses
    ?.slice()
    .sort(
      (a: ReportProgress, b: ReportProgress) =>
        new Date(b.check_in).getTime() - new Date(a.check_in).getTime()
    );
  const progress = sorted?.[0];
  return progress;
}
