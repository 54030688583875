import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  useDeleteBusinessVerticalMutation,
  useGetBusinessVerticalsQuery,
  useRestoreBusinessVerticalMutation,
} from "../../services/vertical.service";
import LinearProgress from "@mui/material/LinearProgress";
import { Stack, Paper, Button, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import { BusinessVertical } from "../../types/vertical";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { toast } from "react-toastify";
import ModalBusinessVerticalForm from "./ModalBusinessVerticalForm";
import AddIcon from "@mui/icons-material/Add";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <Button variant="outlined" onClick={onCreate}>
          <AddIcon /> Add Industry
        </Button>
        <GridToolbarExport
          sx={{ ml: 2 }}
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Industries" }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const BusinessVerticalsPage = () => {
  useTitle("Industries");
  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    row: GridRowModel<BusinessVertical> | null;
  }>({
    open: false,
    row: null,
  });
  const [formModal, setFormModal] = React.useState<{
    open: boolean;
    data: BusinessVertical | null;
  }>({
    open: false,
    data: null,
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const { data, isLoading, isFetching } = useGetBusinessVerticalsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const [doDelete, { isLoading: deleting }] =
    useDeleteBusinessVerticalMutation();

  const [doRestore, { isLoading: restoring }] =
    useRestoreBusinessVerticalMutation();

  const handleDeleteRow = (row: GridRowModel<BusinessVertical>) => {
    setDeleteModal({ open: true, row });
  };
  const handleEditRow = (row: GridRowModel<BusinessVertical>) => {
    setFormModal({ open: true, data: row });
  };
  const handleRestoreRow = async (row: GridRowModel<BusinessVertical>) => {
    try {
      await doRestore(row.id).unwrap();
      toast.success("Data restored");
    } catch (err) {}
  };

  const rows: GridRowsProp | undefined = React.useMemo(
    () =>
      data?.data.map((it) => ({
        id: it.id,
        name: it.name,
        description: it.description,
        deleted_at: it.deleted_at,
      })),
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "name",
        headerName: "Industry",
        minWidth: 200,
        sortingOrder: ["desc", "asc", null],
        filterable: true,
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
        sortable: false,
        filterable: true,
      },
      {
        field: "control",
        headerName: "",
        sortable: false,
        filterable: false,
        renderCell: (
          params: GridRenderCellParams<undefined, BusinessVertical>
        ) =>
          !!params.row.deleted_at ? (
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleRestoreRow(params.row)}
            >
              Restore
            </Button>
          ) : (
            <Stack direction={"row"}>
              <IconButton onClick={() => handleEditRow(params.row)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={() => handleDeleteRow(params.row)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Stack>
          ),
        disableExport: true,
      },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    setFormModal({ open: true, data: null });
  }, []);

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    if (deleteModal.row?.id) {
      try {
        await doDelete(deleteModal.row.id).unwrap();
        closeDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeDeleteModal();
      }
    }
  };

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  return (
    <Paper style={{ height: "85vh" }}>
      <DataGrid
        rows={rows || []}
        columns={columns}
        paginationMode="server"
        rowCount={rowCount}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newSize) => setPageSize(newSize)}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        loading={isLoading || isFetching}
        components={{
          LoadingOverlay: LinearProgress,
          NoResultsOverlay: NoResultsOverlay,
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        disableColumnFilter
        // rowHeight={42}
        density="compact"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            borderTop: "1px solid #ddd",
          },
          "& .deleted": {
            fontStyle: "italic",
            color: "#aaa",
          },
        }}
        getRowClassName={getRowClassName}
      />
      <ConfirmDeleteModal
        open={deleteModal.open}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
        message={`Delete industry '${deleteModal.row?.name}' ?`}
      />
      <ModalBusinessVerticalForm
        open={formModal.open}
        onClose={() => setFormModal({ open: false, data: null })}
        data={formModal.data}
      />
    </Paper>
  );
};
export default BusinessVerticalsPage;
