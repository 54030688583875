import * as React from "react";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Input,
  Link,
  Paper,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Talent } from "../../types/users";
import { useNavigate } from "react-router-dom";
import TalentDrawer from "../talent/TalentDrawer";
import dayjs from "dayjs";
import {
  NegotiationStatus,
  Project,
  ProjectResource,
  ProjectStatus,
  ReportProgress,
  ReportProgressStatus,
} from "../../types/project";
import { toast } from "react-toastify";
import { getLastProgress } from "../../utils/projectHelper";
import { ucFirstAllWords } from "../../utils/helper";
import { LoadingButton } from "@mui/lab";
import { Refresh } from "@mui/icons-material";
import { useGetProjectBidsQuery } from "../../services/projectJob.service";

const getBidStatus = (
  status: NegotiationStatus
): {
  label: string;
  color: any;
} => {
  if (status == NegotiationStatus.NEW) {
    return {
      label: "NEW",
      color: undefined,
    };
  } else if (status == NegotiationStatus.HIRED) {
    return {
      label: "HIRED",
      color: "success",
    };
  } else if (status == NegotiationStatus.INTERVIEW) {
    return {
      label: "INTERVIEWED",
      color: "warning",
    };
  } else if (status == NegotiationStatus.INVITED) {
    return {
      label: "INVITED",
      color: "info",
    };
  } else if (status == NegotiationStatus.REJECTED) {
    return {
      label: "REJECTED",
      color: "error",
    };
  } else if (status == NegotiationStatus.CANCELED) {
    return {
      label: "CANCELED",
      color: "error",
    };
  } else {
    return { label: status, color: undefined };
  }
};

const getHiredStatus = (
  project: Project,
  job: ProjectResource
): {
  label: string;
  color: any;
  helperText?: string;
} => {
  const progress = getLastProgress(job);
  if (project.status == ProjectStatus.PUBLISHED) {
    return {
      label: "WAIT for Independepent Professional",
      color: undefined,
      helperText: "Waiting for more Independent Professionals",
    };
  } else if (progress?.status == ReportProgressStatus.APPROVED) {
    return { label: "WORK APPROVED", color: "success" };
  } else if (progress?.status == ReportProgressStatus.REJECTED) {
    return { label: "WORK REJECTED", color: "error" };
  } else if (progress?.status == ReportProgressStatus.SUBMITTED) {
    return {
      label: "WAIT APPROVAL",
      color: "info",
    };
  } else if (!!progress?.check_in && !progress?.check_out) {
    return {
      label: "CHECKED IN",
      color: "info",
      helperText: `at ${dayjs(progress?.check_in).format("MMM DD hh:mm A")}`,
    };
  } else if (!!progress?.check_in && !!progress?.check_out) {
    return {
      label: "CHECKED OUT",
      color: "warning",
      helperText: `at ${dayjs(progress?.check_out).format("MMM DD hh:mm A")}`,
    };
  }
  return { label: job?.status?.toUpperCase(), color: undefined };
};

const LogModal = ({
  open,
  onClose,
  job,
}: {
  open: boolean;
  onClose: () => void;
  job: ProjectResource | null;
}) => {
  const sorted = React.useMemo(
    () =>
      job?.report_progresses
        ?.slice()
        .sort(
          (a: ReportProgress, b: ReportProgress) =>
            new Date(a.check_in).getTime() - new Date(b.check_in).getTime()
        ),
    [job]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">Log</DialogTitle>
      <DialogContent>
        <ul>
          <li>
            Hired at {dayjs(job?.hired_at).format("MMM DD, YYYY hh:mm A")}
          </li>
          {sorted?.map((it) => (
            <>
              <li>
                {"Working from "}
                {dayjs(it.check_in).format("MMM DD, YYYY hh:mm A")} {" --> "}
                {!!it.check_out
                  ? dayjs(it.check_out).format("MMM DD, YYYY hh:mm A")
                  : "-"}
              </li>
              {!!it.status && (
                <>
                  <li>Work Submitted</li>
                  <li>{ucFirstAllWords(it.status)}</li>
                </>
              )}
            </>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const TableHireds = ({
  project,
  bids,
}: {
  project: Project;
  bids: Array<ProjectResource>;
}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedTalent, setSelectedTalent] = React.useState<Talent | null>(
    null
  );
  const [logModal, setLogModal] = React.useState<{
    visible: boolean;
    job: ProjectResource | null;
  }>({
    visible: false,
    job: null,
  });

  return (
    <Box>
      <Typography sx={{ fontSize: 16, fontWeight: "500", mb: 1 }}>
        Hired Talents
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#eee" }}>
              <TableCell>Talent</TableCell>
              <TableCell>Hired At</TableCell>
              <TableCell>Rate ($/hr)</TableCell>
              <TableCell>Max Hour</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bids?.length == 0 && (
              <TableRow>
                <TableCell colSpan={6}>No talents hired, yet</TableCell>
              </TableRow>
            )}
            {bids?.map((job) => {
              const status = getHiredStatus(project, job);
              const progress = getLastProgress(job);
              const reviewForTalent = project?.reviews?.find(
                (it) =>
                  it.model_type == "user" && it.model_id == job.resource_id
              );
              const reviewForClient = project?.reviews?.find(
                (it) =>
                  it.model_type == "company" && it.created_by == job.resource_id
              );
              return (
                <>
                  <TableRow
                    key={job.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Stack direction={"row"} alignItems="center">
                        <Box>
                          <Stack direction={"row"} alignItems="center">
                            <Link
                              onClick={() => {
                                setSelectedTalent(job.resource || null);
                                setDrawerOpen(true);
                              }}
                              sx={{ fontSize: 16 }}
                            >
                              {job.resource?.first_name}{" "}
                              {job.resource?.last_name}
                            </Link>
                            <Rating
                              value={job.resource?.total_rating}
                              readOnly
                              precision={0.5}
                              size={"small"}
                              emptyIcon={
                                <StarIcon
                                  style={{ opacity: 0.55 }}
                                  fontSize="inherit"
                                />
                              }
                              sx={{ ml: 1 }}
                            />
                          </Stack>
                          <Typography variant="caption">
                            {job.resource?.city}, {job.resource?.state}{" "}
                            {job.resource?.zip}
                          </Typography>
                        </Box>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {dayjs(job.hired_at).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell>{job.rate}</TableCell>
                    <TableCell>{job.max_hour} hr</TableCell>
                    <TableCell>
                      <Chip
                        label={status.label}
                        color={status.color}
                        size="small"
                      />
                      {!!status.helperText && (
                        <FormHelperText>{status.helperText}</FormHelperText>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="info"
                        variant="text"
                        onClick={() => setLogModal({ visible: true, job })}
                      >
                        Log
                      </Button>
                    </TableCell>
                  </TableRow>
                  {project.status == ProjectStatus.COMPLETED && (
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={2} sx={{ alignItems: "flex-start" }}>
                        <Typography variant="subtitle2">
                          Review for Talent:
                        </Typography>
                        {!!reviewForTalent ? (
                          <Box>
                            <Rating
                              value={reviewForTalent.star}
                              readOnly={true}
                            />
                            <Typography variant="body2">
                              {reviewForTalent.review}
                            </Typography>
                          </Box>
                        ) : (
                          <Box>
                            <Typography variant="body2" sx={{ color: "#999" }}>
                              No review, yet
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell colSpan={3} sx={{ verticalAlign: "top" }}>
                        <Typography variant="subtitle2">
                          Review for Customer:
                        </Typography>
                        {!!reviewForClient ? (
                          <Box>
                            <Rating
                              value={reviewForClient.star}
                              readOnly={true}
                            />
                            <Typography variant="body2">
                              {reviewForClient.review}
                            </Typography>
                          </Box>
                        ) : (
                          <Box>
                            <Typography variant="body2" sx={{ color: "#999" }}>
                              No review, yet
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <LogModal
        open={logModal.visible}
        job={logModal.job || null}
        onClose={() => setLogModal({ visible: false, job: null })}
      />
      <TalentDrawer
        forceRefresh={true}
        data={selectedTalent}
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
    </Box>
  );
};

const TableBids = ({
  project,
  bids,
}: {
  project: Project;
  bids: Array<ProjectResource>;
}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [selectedTalent, setSelectedTalent] = React.useState<Talent | null>(
    null
  );
  const [hireModal, setHireModal] = React.useState<{
    visible: boolean;
    job: ProjectResource | null;
  }>({
    visible: false,
    job: null,
  });
  const [showTable, setShowTable] = React.useState(
    project.status == ProjectStatus.PUBLISHED
  );
  const isHourly = React.useMemo(() => project?.type == "hourly", [project]);

  const isAcceptingBid = React.useMemo(
    () => project.status == ProjectStatus.PUBLISHED,
    [project]
  );

  return (
    <Box>
      <Stack direction={"row"} alignItems="center" sx={{ mb: 1 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "500", flex: 1 }}>
          Incoming Bids{" "}
          <Button onClick={() => setShowTable(!showTable)} size="small">
            {showTable ? "Hide" : "Show"}
          </Button>
        </Typography>
        {/* {isAcceptingBid && (
          <Button variant="outlined" onClick={() => alert("TODO")}>
            + Invite Talent
          </Button>
        )} */}
      </Stack>
      {showTable && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#eee" }}>
                <TableCell>Talent</TableCell>
                <TableCell>Price</TableCell>
                {isHourly && <TableCell>Max Hour</TableCell>}
                <TableCell>Status</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bids?.length == 0 && (
                <TableRow>
                  <TableCell colSpan={6}>No bids, yet</TableCell>
                </TableRow>
              )}
              {bids?.map((job) => {
                const status = getBidStatus(job.status);
                return (
                  <TableRow
                    key={job.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Stack direction={"row"} alignItems="center">
                        <Box>
                          <Stack direction={"row"} alignItems="center">
                            <Link
                              onClick={() => {
                                setSelectedTalent(job.resource || null);
                                setDrawerOpen(true);
                              }}
                              sx={{ fontSize: 16 }}
                            >
                              {job.resource?.first_name}{" "}
                              {job.resource?.last_name}
                            </Link>
                            <Rating
                              value={job.resource?.total_rating}
                              readOnly
                              precision={0.5}
                              size={"small"}
                              emptyIcon={
                                <StarIcon
                                  style={{ opacity: 0.55 }}
                                  fontSize="inherit"
                                />
                              }
                              sx={{ ml: 1 }}
                            />
                          </Stack>
                          <Typography variant="caption">
                            {job.resource?.city}, {job.resource?.state}{" "}
                            {job.resource?.zip}
                          </Typography>
                        </Box>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {job.status == NegotiationStatus.INVITED
                        ? "n/a"
                        : isHourly
                        ? `$${job.rate}/hr`
                        : `$${job.price}`}
                    </TableCell>
                    {isHourly && <TableCell>{job.max_hour} hr</TableCell>}
                    <TableCell>
                      <Chip
                        label={status.label}
                        color={status.color}
                        size="small"
                      />
                      {status.label == "CANCELED" && (
                        <Typography variant="caption">
                          <br /> {job.canceled_reason}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {dayjs(job.updated_at).format("MMM DD, YYYY hh:mm A")}
                    </TableCell>
                    <TableCell align="right">
                      {/* {isAcceptingBid && (
                        <>
                          <Button
                            variant="contained"
                            onClick={() => setHireModal({ visible: true, job })}
                          >
                            Hire
                          </Button>
                          <Button variant="outlined" sx={{ ml: 1 }}>
                            Negotiate
                          </Button>
                        </>
                      )} */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TalentDrawer
        forceRefresh={true}
        data={selectedTalent}
        open={drawerOpen}
        setOpen={setDrawerOpen}
      />
    </Box>
  );
};

const ProjectTalentsPanel = ({ project }: { project: Project }) => {
  // const navigate = useNavigate();
  const {
    data: jobs,
    isLoading,
    isFetching,
    refetch,
  } = useGetProjectBidsQuery(project.id);

  const handleRefresh = () => {
    refetch();
  };

  const hiredList = React.useMemo(
    () => jobs?.filter((it) => !!it.hired_at),
    [jobs]
  );
  const bidList = React.useMemo(
    () => jobs?.filter((it) => !it.hired_at),
    [jobs]
  );

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Stack sx={{ alignItems: "flex-end" }}>
        <LoadingButton
          variant="outlined"
          onClick={handleRefresh}
          size="small"
          loading={isFetching}
          startIcon={<Refresh />}
        >
          Refresh
        </LoadingButton>
      </Stack>
      <TableHireds bids={hiredList || []} project={project} />
      <Box sx={{ mt: 5 }} />
      <TableBids bids={bidList || []} project={project} />
    </Box>
  );
};
export default ProjectTalentsPanel;
