import dayjs from "dayjs";

import React, { PureComponent } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  useGetTotalTalentByDateQuery,
  useGetTotalCompanyByDateQuery,
} from "../../services/report.service";

function formatXAxis(tickItem: string) {
  return dayjs(tickItem).format("DD MMM YYYY");
}

interface ParamsProps {
  startDate: string;
  endDate: string;
}

interface BarChartNewTalentCompanyProps {
  id?: string;
  params: ParamsProps;
}

const BarChartNewTalentCompany = (props: BarChartNewTalentCompanyProps) => {
  const { id, params } = props;
  const theme = useTheme();

  const {
    data: dataTotalTalentByDate,
    isFetching: isFetchingTotalTalentByDate,
    isLoading: isLoadingTotalTalentByDate,
  } = useGetTotalTalentByDateQuery(params);
  const {
    data: dataTotalCompanyByDate,
    isFetching: isFetchingTotalCompanyByDate,
    isLoading: isLoadingTotalCompanyByDate,
  } = useGetTotalCompanyByDateQuery(params);

  const totalTalent = React.useMemo(
    () =>
      dataTotalTalentByDate
        ?.map((it) => it.total)
        ?.reduce((prev, cur) => prev + cur, 0),
    [dataTotalTalentByDate]
  );
  const totalCustomer = React.useMemo(
    () =>
      dataTotalCompanyByDate
        ?.map((it) => it.total)
        ?.reduce((prev, cur) => prev + cur, 0),
    [dataTotalCompanyByDate]
  );

  const chartData = React.useMemo(() => {
    let newData: any[] = [];
    dataTotalTalentByDate?.map((item) => {
      newData.push({ date: item.date, talent: item.total, customer: 0 });
    });
    dataTotalCompanyByDate?.map((item) => {
      const curData = newData.find((curItem) => curItem.date === item.date);
      if (curData) {
        newData.find((curItem) => curItem.date === item.date).customer +=
          item.total;
      } else {
        newData.push({ date: item.date, talent: 0, customer: item.total });
      }
    });
    newData.sort((a, b) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return dayjs(a.date).diff(b.date);
    });
    return newData;
  }, [dataTotalTalentByDate, dataTotalCompanyByDate]);

  if (isLoadingTotalTalentByDate || isLoadingTotalCompanyByDate)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        // width={500}
        height={300}
        data={chartData}
        margin={{
          // top: 5,
          // right: 30,
          // left: 20,
          bottom: 35,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          angle={-45}
          dataKey="date"
          fontSize="12"
          interval={1}
          textAnchor="end"
          tickFormatter={formatXAxis}
        />
        <YAxis />
        <Tooltip />
        <Legend layout="horizontal" verticalAlign="top" />
        <Bar
          name={`Talent: ${totalTalent}`}
          dataKey="talent"
          fill={theme.palette.primary.main}
        />
        <Bar
          name={`Client: ${totalCustomer}`}
          dataKey="customer"
          fill={theme.palette.secondary.main}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartNewTalentCompany;
