import { OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET } from "../configs/api";
import { User } from "../types/users";
import { baseApi } from "./base";

export interface LoginResponse {
  token_type: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (req) => {
        // let formData = new FormData();
        // formData.append("username", req.username);
        // formData.append("password", req.password);
        // formData.append("grant_type", "password");
        // formData.append("client_id", OAUTH_CLIENT_ID);
        // formData.append("client_secret", OAUTH_CLIENT_SECRET);
        // formData.append("scope", "*");
        return {
          url: "oauth/token",
          method: "POST",
          // body: formData,
          body: {
            username: req.username,
            password: req.password,
            grant_type: "password",
            client_id: OAUTH_CLIENT_ID,
            client_secret: OAUTH_CLIENT_SECRET,
            scope: "*",
          },
        };
      },
    }),
    loadUser: builder.query<User, void>({
      query: () => ({
        url: "api/me",
        method: "GET",
      }),
    }),
    getUser: builder.query<User, void>({
      query: () => ({
        url: "api/me",
        method: "GET",
      }),
    }),
    logout: builder.mutation<{ message: string }, void>({
      query: () => ({
        url: "logout",
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useLoadUserQuery,
  useGetUserQuery,
  useLogoutMutation,
} = authApi;
