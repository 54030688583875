import { QueryResponse, baseApi } from "./base";
import { UserFeedback } from "../types/feedback";

const TAG_TYPE: string = "Incidents";
const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE],
});

export const feedbackApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getUserFeedbacks: builder.query<
      QueryResponse<UserFeedback>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
      }
    >({
      query: ({ page, pageSize, sortField, sortMode, search }) => ({
        url: "api/admin/incidents",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
          withTrashed: false,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE, id })),
        ];
      },
    }),
    deleteUserFeedback: builder.mutation<void, number>({
      query: (id) => ({
        url: `api/admin/incidents/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE, id },
        { type: TAG_TYPE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserFeedbacksQuery, useDeleteUserFeedbackMutation } =
  feedbackApi;
