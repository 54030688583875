import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { RootState } from "../../app/store";
import { setTalentFilter } from "../../app/uiSlice";
import useDebounce from "../../hooks/useDebounce";
import SelectCountry from "../../components/SelectCountry";
import SelectSkills from "./SelectSkills";

interface ModalTalentFilterProps {
  open: boolean;
  onClose: () => void;
}

const ModalTalentFilter = ({ open, onClose }: ModalTalentFilterProps) => {
  const filter = useAppSelector((state: RootState) => state.ui.talentFilter);
  const dispatch = useAppDispatch();

  const [city, setCity] = React.useState(filter.city);
  const [state, setState] = React.useState(filter.state);

  const handleReset = React.useCallback(() => {
    // TODO: still triggered double refresh
    setCity("");
    setState("");
    dispatch(setTalentFilter({}));
  }, [dispatch]);

  const debounceCity = useDebounce(city, 500);
  const debounceState = useDebounce(state, 500);

  React.useEffect(() => {
    dispatch(setTalentFilter({ ...filter, city: debounceCity }));
  }, [dispatch, debounceCity]);

  React.useEffect(() => {
    dispatch(setTalentFilter({ ...filter, state: debounceState }));
  }, [dispatch, debounceState]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle id="alert-dialog-title">Filter By</DialogTitle>
      <DialogContent>
        <Box>
          <SelectSkills
            onChange={(ids) =>
              dispatch(setTalentFilter({ ...filter, skills: ids }))
            }
            values={filter.skills || []}
          />
          <TextField
            InputLabelProps={{ style: { fontSize: 14 } }}
            //InputProps={{ style: {fontSize: 14}}}
            label="City"
            value={city}
            fullWidth
            onChange={(e) => setCity(e.target.value)}
          />
          <TextField
            InputLabelProps={{ style: { fontSize: 14 } }}
            //InputProps={{ style: {fontSize: 14}}}
            label="State"
            value={state}
            fullWidth
            onChange={(e) => setState(e.target.value)}
          />
          <SelectCountry
            onChange={(id) =>
              dispatch(setTalentFilter({ ...filter, country: id }))
            }
            value={filter.country || 0}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset}>Reset</Button>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalTalentFilter;
