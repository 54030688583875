import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
// import { Stack, Paper, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { AppVersion } from "../../types/appVersion";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetAppVersionsQuery,
  useEnabledAppVersionMutation,
  useDisabledAppVersionMutation,
} from "../../services/app-version.service";
import ModalAppUpdateForm from "./ModalAppUpdateForm";
import dayjs from "dayjs";
import { Chip } from "@mui/material";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button
          variant="text"
          sx={{ mt: 1 }}
          onClick={() => setQuery("")}
        >
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <Button variant="outlined" onClick={onCreate}>
          <AddIcon /> Add App Version
        </Button>
      </Stack>
    </GridToolbarContainer>
  );
};

const AppUpdatesPage = () => {
  useTitle("App Updates");
  const navigate = useNavigate();

  const [formModal, setFormModal] = React.useState<{
    open: boolean;
    data: AppVersion | null;
  }>({
    open: false,
    data: null,
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const { data, isLoading, isFetching } = useGetAppVersionsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const [doEnabled, { isLoading: enabling }] = useEnabledAppVersionMutation();
  const [doDisabled, { isLoading: disabling }] =
    useDisabledAppVersionMutation();

  const handleEnabledRow = async (row: GridRowModel<AppVersion>) => {
    // setDeleteModal({ open: true, row });
    try {
      await doEnabled(row.id).unwrap();
      toast.success("Successfully updated");
    } catch (err) {}
  };
  const handleEditRow = (row: GridRowModel<AppVersion>) => {
    setFormModal({ open: true, data: row });
  };
  const handleDisabledRow = async (row: GridRowModel<AppVersion>) => {
    try {
      await doDisabled(row.id).unwrap();
      toast.success("Successfully updated");
    } catch (err) {}
  };

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "app_id",
        headerName: "App ID",
        headerAlign: "center",
        flex: 1,
        filterable: true,
        sortable: true,
        align: "center",
      },
      {
        field: "platform",
        headerName: "Platform",
        headerAlign: "center",
        flex: 1,
        sortingOrder: ["desc", "asc", null],
        filterable: true,
        sortable: true,
        align: "center",
      },
      {
        field: "version",
        headerName: "Version",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: true,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, AppVersion>) =>
          `${params.row.version} (${params.row.version_name})`,
      },
      {
        field: "mandatory",
        headerName: "Is Mandatory",
        headerAlign: "center",
        minWidth: 50,
        sortable: true,
        filterable: true,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, AppVersion>) =>
          `${params.row.mandatory ? "Yes" : "No"}`,
      },
      // {
      //   field: "release_notes",
      //   headerName: "Release Notes",
      //   minWidth: 200,
      //   sortable: false,
      //   filterable: false,
      // },
      {
        field: "enabled",
        headerName: "Status",
        headerAlign: "center",
        flex: 1,
        sortable: true,
        filterable: true,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, AppVersion>) => (
          <Stack direction={"row"}>
            <Chip
              size="small"
              label={params.row.enabled ? "ENABLED" : "DISABLED"}
              color={params.row.enabled ? "success" : "error"}
              sx={{ fontSize: 12 }}
            />
          </Stack>
        ),
      },
      {
        field: "created_at",
        headerName: "Created At",
        headerAlign: "center",
        minWidth: 200,
        sortable: true,
        filterable: true,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, AppVersion>) =>
          `${dayjs(params.row.created_at).format("MMM DD, YYYY hh:mm A")}`,
      },
      {
        field: "control",
        headerName: "Disble/Edit",
        headerAlign: "center",
        minWidth: 150,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, AppVersion>) => (
          <Stack direction={"row"}>
            {params.row.enabled ? (
              <Button
                onClick={() => handleDisabledRow(params.row)}
                size="small"
              >
                Disable
              </Button>
            ) : (
              <Button size="small" onClick={() => handleEnabledRow(params.row)}>
                Enable
              </Button>
            )}
            <IconButton onClick={() => handleEditRow(params.row)}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Stack>
        ),
      },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    setFormModal({ open: true, data: null });
  }, []);

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  return (
    <Paper style={{ height: "85vh" }}>
      <DataGrid
        rows={rows || []}
        columns={columns}
        paginationMode="server"
        rowCount={rowCount}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newSize) => setPageSize(newSize)}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        loading={isLoading || isFetching}
        components={{
          LoadingOverlay: LinearProgress,
          NoResultsOverlay: NoResultsOverlay,
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        disableColumnFilter
        // rowHeight={42}
        density="compact"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            borderTop: "1px solid #ddd",
          },
          "& .deleted": {
            fontStyle: "italic",
            color: "#aaa",
          },
        }}
        getRowClassName={getRowClassName}
      />
      <ModalAppUpdateForm
        open={formModal.open}
        onClose={() => setFormModal({ open: false, data: null })}
        data={formModal.data}
      />
    </Paper>
  );
};
export default AppUpdatesPage;
