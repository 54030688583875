import React from "react";
import { CircularProgress, Box, Typography, useTheme } from "@mui/material";
import { useGetTotalUserQuery } from "../../services/report.service";
import DonutChart from "./DonutChart";

interface DonutChartUserProps {
  id?: string;
}

const DonutChartUser = (props: DonutChartUserProps) => {
  const { id } = props;
  const { data: dataApi, isFetching, isLoading } = useGetTotalUserQuery();
  const theme = useTheme();

  const data = React.useMemo(() => {
    return [
      {
        name: "Active",
        value: dataApi?.total_active || 0,
        color: theme.palette.primary.main,
      },
      {
        name: "Not Active",
        value: dataApi?.total_inactive || 0,
        color: theme.palette.grey[500],
      },
      {
        name: "Pending",
        value: dataApi?.total_pending || 0,
        color: theme.palette.secondary.main,
      },
    ];
  }, [dataApi]);

  if (isLoading)
    return (
      <Box sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );

  return <DonutChart id={id} data={data} />;
};

export default DonutChartUser;
