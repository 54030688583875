import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
  GridToolbarExport,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { Stack, Paper, Button, Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import TextField from "@mui/material/TextField";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { toast } from "react-toastify";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { Certification } from "../../types/certification";
import {
  useDeleteUserFeedbackMutation,
  useGetUserFeedbacksQuery,
} from "../../services/feedback.service";
import { UserFeedback } from "../../types/feedback";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <GridToolbarExport
          sx={{ ml: 2 }}
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "User Feedbacks" }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

const UserFeedbacksPage = () => {
  useTitle("User Feedbacks");
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    row: GridRowModel<Certification> | null;
  }>({
    open: false,
    row: null,
  });
  const [formModal, setFormModal] = React.useState<{
    open: boolean;
    data: Certification | null;
  }>({
    open: false,
    data: null,
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const { data, isLoading, isFetching } = useGetUserFeedbacksQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const [doDelete, { isLoading: deleting }] = useDeleteUserFeedbackMutation();

  const handleDeleteRow = (row: GridRowModel<Certification>) => {
    setDeleteModal({ open: true, row });
  };

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      { field: "id", width: 50 },
      {
        field: "created_at",
        headerName: "Time",
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams<undefined, UserFeedback>) =>
          `${dayjs(params.row?.created_at).format("MMM DD, hh:mm A")}`,
      },
      {
        field: "user",
        headerName: "Sender",
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<undefined, UserFeedback>) => (
          <Link
            onClick={() => navigate(`/talents/${params.row.user?.id}`)}
            component="button"
            variant="body2"
          >
            #{params.row.user?.id} {params.row.user?.full_name}
          </Link>
        ),
      },
      {
        field: "message",
        headerName: "Message",
        flex: 1,
      },
      {
        field: "type",
        headerName: "Type",
        minWidth: 150,
      },
      {
        field: "info",
        headerName: "Info",
        minWidth: 300,
        flex: 1,
      },
      // {
      //   field: "control",
      //   headerName: "",
      //   sortable: false,
      //   filterable: false,
      //   renderCell: (
      //     params: GridRenderCellParams<undefined, Certification>
      //   ) => (
      //     <Stack direction={"row"}>
      //       <IconButton onClick={() => handleDeleteRow(params.row)}>
      //         <DeleteIcon fontSize="small" />
      //       </IconButton>
      //     </Stack>
      //   ),
      //   disableExport: true,
      // },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    setFormModal({ open: true, data: null });
  }, []);

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    if (deleteModal.row?.id) {
      try {
        await doDelete(deleteModal.row.id).unwrap();
        closeDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeDeleteModal();
      }
    }
  };

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  return (
    <Paper style={{ height: "85vh" }}>
      <DataGrid
        rows={rows || []}
        columns={columns}
        paginationMode="server"
        rowCount={rowCount}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newSize) => setPageSize(newSize)}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        loading={isLoading || isFetching}
        components={{
          LoadingOverlay: LinearProgress,
          NoResultsOverlay: NoResultsOverlay,
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        disableColumnFilter
        // rowHeight={42}
        density="compact"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            borderTop: "1px solid #ddd",
          },
          "& .deleted": {
            fontStyle: "italic",
            color: "#aaa",
          },
        }}
        getRowClassName={getRowClassName}
      />
      <ConfirmDeleteModal
        open={deleteModal.open}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
        message={`Delete Feedback #${deleteModal.row?.id} ?`}
      />
    </Paper>
  );
};
export default UserFeedbacksPage;
