import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Controller, useForm } from "react-hook-form";
import { Withdrawal } from "../../types/withdrawal";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useInprogressWithdrawalMutation,
  useCompletedWithdrawalMutation,
} from "../../services/withdrawal.service";
import SelectReason from "../../components/SelectReason";
import { formatMoney } from "../../utils/helper";

function createData(name: string, value: string) {
  return { name, value };
}

interface ModalWithdrawalDetailFormProps {
  open: boolean;
  onClose: () => void;
  data: Withdrawal | null;
  type: string;
}

const ModalWithdrawalDetailForm = ({
  open,
  onClose,
  data,
  type,
}: ModalWithdrawalDetailFormProps) => {
  const [doProcess, { isLoading: processing }] =
    useInprogressWithdrawalMutation();
  const [doConfirm, { isLoading: confirming }] =
    useCompletedWithdrawalMutation();

  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (type === "process") {
        await doProcess(payload?.id).unwrap();
      }
      if (type === "confirm") {
        await doConfirm(payload).unwrap();
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("id", data?.id || -1);
      setValue("notes", "");

      clearErrors();
    }
  }, [data, open]);

  const rows = [
    createData(
      "Talent Name",
      `${data?.user?.first_name} ${data?.user?.last_name}`
    ),
    createData("Email", `${data?.email || "n/a"}`),
    createData("Phone", `${data?.phone || "n/a"}`),
    createData("Type", `${data?.type || "n/a"}`),
    createData("Total", `${formatMoney(Number(data?.total || 0))}`),
    createData("Status", `${data?.status || "n/a"}`),
  ];

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {type === "process" ? "Process" : "Confirm"} Withdrawal
        </DialogTitle>
        <DialogContent>
          <Box>
            {/* <TableContainer component={Paper}> */}
            <Table aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: "medium", width: 150 }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* </TableContainer> */}

            <Controller
              name="id"
              control={control}
              rules={{ required: true }}
              render={({ field: { value } }) => (
                <TextField
                  label="Id"
                  value={value}
                  fullWidth
                  sx={{ display: "none" }}
                />
              )}
            />
            {type === "confirm" && (
              <Controller
                name="notes"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    label="Notes"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                    helperText="ex: #TransactionID, reasons, etc"
                  />
                )}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={processing || confirming}
          >
            {type === "process" ? "Process" : "Confirm Done"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalWithdrawalDetailForm;
