import { FinancialReport } from "../types/report";
import { baseApi, QueryResponse } from "./base";

interface DailyStat {
  total: number;
  date: number;
}

const TAG_TYPE = "report";
const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE],
});

export const reportApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTotalJob: builder.query<
      { total_completed: number; total_created: number },
      void
    >({
      query: () => ({
        url: `api/admin/reports/total-job`,
        method: "GET",
      }),
      providesTags: (result, error) => [{ type: TAG_TYPE, id: "total-job" }],
    }),
    getTotalSpentByCompany: builder.query<any, number>({
      query: (id) => ({
        url: `api/admin/reports/total-spent-by-company`,
        method: "GET",
        params: {
          company_id: id,
        },
      }),
      providesTags: (result, error, company_id) => [
        { type: TAG_TYPE, id: "total-spent-by-company", company_id },
      ],
    }),
    getTotalTalent: builder.query<
      { total_active: number; total_idle: number; total_pending: number },
      void
    >({
      query: () => ({
        url: `api/admin/reports/total-talent`,
        method: "GET",
      }),
      providesTags: (result, error) => [{ type: TAG_TYPE, id: "total-talent" }],
    }),
    getTotalTalentByDate: builder.query<
      Array<DailyStat>,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `api/admin/reports/total-talent-by-date`,
        method: "GET",
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
      providesTags: (result, error, { startDate, endDate }) => [
        { type: TAG_TYPE, id: "total-talent-by-date", startDate, endDate },
      ],
    }),
    getTotalCompany: builder.query<
      { total_active: number; total_idle: number; total_inactive: number },
      void
    >({
      query: () => ({
        url: `api/admin/reports/total-company`,
        method: "GET",
      }),
      providesTags: (result, error) => [
        { type: TAG_TYPE, id: "total-company" },
      ],
    }),
    getTotalCompanyByDate: builder.query<
      Array<DailyStat>,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `api/admin/reports/total-company-by-date`,
        method: "GET",
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
      providesTags: (result, error, { startDate, endDate }) => [
        { type: TAG_TYPE, id: "total-company-by-date", startDate, endDate },
      ],
    }),
    getTotalUser: builder.query<
      { total_active: number; total_inactive: number; total_pending: number },
      void
    >({
      query: () => ({
        url: `api/admin/reports/total-user`,
        method: "GET",
      }),
      providesTags: (result, error) => [{ type: TAG_TYPE, id: "total-user" }],
    }),
    getSurveyAnswer: builder.query<
      { data: Array<{ survey_answer: string; total: number }> },
      void
    >({
      query: () => ({
        url: `api/admin/survey-answers`,
        method: "GET",
      }),
      providesTags: (result, error) => [
        { type: TAG_TYPE, id: "survey-answer" },
      ],
    }),
    getSkillGroupByTalent: builder.query<
      { data: Array<{ name: string; total: number }> },
      void
    >({
      query: () => ({
        url: `api/admin/reports/skills/group-by-talents`,
        method: "GET",
      }),
      providesTags: (result, error) => [
        { type: TAG_TYPE, id: "skill-group-by-talent" },
      ],
    }),
    getVerticalGroupByCompany: builder.query<
      { data: Array<{ name: string; total: number }> },
      void
    >({
      query: () => ({
        url: `api/admin/reports/companies/group-by-business-verticals`,
        method: "GET",
      }),
      providesTags: (result, error) => [
        { type: TAG_TYPE, id: "vertical-group-by-company" },
      ],
    }),
    getSkillGroupByProject: builder.query<
      { data: Array<{ name: string; total: number }> },
      void
    >({
      query: () => ({
        url: `api/admin/reports/projects/group-by-skills`,
        method: "GET",
      }),
      providesTags: (result, error) => [
        { type: TAG_TYPE, id: "skill-group-by-project" },
      ],
    }),
    getTotalEarningByDate: builder.query<
      { total: number },
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `api/admin/earnings/total`,
        method: "GET",
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
      providesTags: (result, error, { startDate, endDate }) => [
        { type: TAG_TYPE, id: "total-earning-by-date", startDate, endDate },
      ],
    }),
    getEarningsByDate: builder.query<
      Array<DailyStat>,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `api/admin/earnings/total-group`,
        method: "GET",
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
      providesTags: (result, error, { startDate, endDate }) => [
        { type: TAG_TYPE, id: "earnings-by-date", startDate, endDate },
      ],
    }),
    getFinanceReport: builder.query<
      Array<FinancialReport>,
      { companyId: number | undefined; start_date: string; end_date: string }
    >({
      query: ({ companyId, start_date, end_date }) => ({
        url: `api/admin/reports/finances`,
        method: "GET",
        params: {
          company_id: companyId,
          start_date,
          end_date,
        },
      }),
      transformResponse: (response: Array<FinancialReport>, meta, arg) => {
        return response.map((it) => {
          it.total_earning =
            it.type == "fixed"
              ? it.fix_price
              : it.rate_hour * it.total_working || 0;
          return {
            ...it,
          };
        });
      },
    }),
    getProjectSummary: builder.query<
      {
        data: Array<{
          data: string;
          number_of_completed: number;
          total_pay: number;
          avg_per_ticket: number;
          total_fee: number;
        }>;
      },
      { startDate?: string; endDate?: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `api/admin/reports/summary-projects`,
        method: "GET",
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
      providesTags: (result, error, { startDate, endDate }) => [
        { type: TAG_TYPE, id: "project-summary-by-date", startDate, endDate },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTotalJobQuery,
  useGetTotalSpentByCompanyQuery,
  useGetTotalTalentQuery,
  useGetTotalTalentByDateQuery,
  useGetTotalCompanyQuery,
  useGetTotalCompanyByDateQuery,
  useGetTotalUserQuery,
  useGetSurveyAnswerQuery,
  useGetSkillGroupByTalentQuery,
  useGetVerticalGroupByCompanyQuery,
  useGetSkillGroupByProjectQuery,
  useGetTotalEarningByDateQuery,
  useGetEarningsByDateQuery,
  useGetFinanceReportQuery,
  useGetProjectSummaryQuery,
} = reportApi;
